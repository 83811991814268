import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { viewtransactionbyid } from "../../services/Bank.service";
import { formatCurrency } from "../../utils/helpers/helper";
import { useTranslation } from 'react-i18next';  

function ViewTransaction({ data }) {
  let id = data?.[0]?.master_transaction_id;
  const [viewtransactiondetails, setviewtransactiond] = useState(null);
  const { t, i18n } = useTranslation(); // Translation hook

  const getAlltransactions = async (id) => {
    try {
      const requestBody = {
        master_id: id,
      };
      const resp = await viewtransactionbyid(requestBody);

      if (resp?.status === 200 && resp?.data?.statusCode === 200) {
        setviewtransactiond(resp?.data?.data);
      } else {
        toast.error(resp?.message || resp?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();

        console.error(msg);
      }
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAlltransactions(id);
  }, []);

  return (
    <>
      <div className="whiteFrame">

        <div className="Table_Wrapper tableResponsive">
          <table className="mb_24">
            <thead>
              <tr>
              <th>{t("common.Sno")}</th>
              <th>{t("transaction.PayReference")}</th>
              <th>{t("transaction.PaymentType")}</th>
              <th>{t("transaction.PaymentStatus")}</th>
              <th>{t("common.Amount")}</th>
              <th>{t("transaction.PaymentDateTime")}</th>
              </tr>
            </thead>
            <tbody>
              {viewtransactiondetails?.map((tdetails, i) => {
                return (
                  <tr key={tdetails?.id}>
                    <td> {i + 1}</td>
                    <td> {tdetails?.pay_refernce ?? "-"}</td>

                    <td>{tdetails?.transaction_method ?? "-"}</td>
                    <td> {tdetails?.transaction_status ?? "-"}</td>

                    <td>
                      {tdetails?.amount !== null &&
                        tdetails?.amount !== undefined
                        ? formatCurrency(tdetails?.amount || 0)
                        : "-"}
                    </td>
                    <td> {tdetails?.paymentDate ?? "-"}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default ViewTransaction;
