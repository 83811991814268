import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { getVoucherDetails } from "../../services/customer.service";
import { formatCurrency } from "../../utils/helpers/helper";
import PaginatedTable from "../common/PaginatedTable";
import ViewIcon from "../../assets/images/view_icon.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';  // Import useTranslation

const CustomerVoucher = () => {
  const [vouchers, setVouchers] = useState([]);
  console.log('vouchers::: ', vouchers);
  const { id } = useParams();
  const { t, i18n } = useTranslation(); // Translation hook

  let [totalCount, setTotalCount] = useState(0);
  console.log('totalCount::: ', totalCount);
  let [currentPage, setCurrentPage] = useState(1);
  let [itemsPerPage, setItemsPerPage] = useState(10);
  let [searchKey, setSearchKey] = useState("");
  let [isLoading, setIsLoading] = useState(true);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [range, setRange] = useState({
    startDate: undefined,
    endDate: new Date(),
  });

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const handleTogglePicker = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (ranges) => {
    const temp = {
      startDate: ranges["range1"]["startDate"],
      endDate: ranges["range1"]["endDate"],
    };
    setRange(temp);
  };

  const [column, setColumn] = useState([
    // {
    //   heading: () => "Action",
    //   cell: (row) => (
    //     <div>
    //       {/* <button className="emptyBtn" onClick={() => handleShowedit(row)}>
    //         <img className="CyanSky_filter" src={EditIcon} alt="EditIcon" />
    //       </button> */}
    //       <button className="emptyBtn" onClick={() => handleShowView(row)}>
    //         <img src={ViewIcon} alt="ViewIcon" />
    //       </button>
    //     </div>
    //   ),

    //   hidden: false,
    //   // width: "120",
    //   // align: "left",
    //   id: 1,
    // },
    {
      heading: () => t("voucher.VoucherName"),
      cell: (row) => (
        <div className="textWrap_Wrap">
          {row["voucher_name"] ? row["voucher_name"] : "-"}
        </div>
      ),
      hidden: false,
      width: "120",
      align: "left",
      id: 2,
    },
    // row["brand_name"] === " " ? "-" : row["brand_name"]
    {
      heading: () => t("voucher.Redeemed"),
      cell: (row) => (
        <span
          className={
            row?.redeemed
              ? "fontWeight600 UFOGreen_text"
              : "fontWeight600 errorClr_text"
          }
        >
          {row["redeemed"] ? "Redeemed" : "Not Redeemed"}
        </span>
      ),
      hidden: false,
      width: "120",
      align: "left",
      id: 8,
    },
    {
      heading: () => t("customer.CreatedDate"),

      cell: (row) => (row["createdDate"] ? row["createdDate"] : "-"),
      hidden: false,
      hidden: false,
      width: "100",
      id: 4,
    },
    {
      heading: () => t("customer.Code"),
      cell: (row) => (row["code"] ? row["code"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 5,
    },
    {
      heading: () => t("common.Amount"),
      cell: (row) => row["amount"] != null ? formatCurrency(row["amount"]) : "-",
      hidden: false,
      width: "120",
      align: "left",
      id: 6,
    },

    // {
    //   heading: () => "Redeemed",
    //   cell: (row) => (
    //     <span className={row?.redeemed ? "fontWeight600 UFOGreen_text" : "fontWeight600 errorClr_text"}>
    //       {row["redeemed"] ? "Redeemed" : "Not Redeemed"}
    //     </span>
    //   ),
    //   hidden: false,
    //   width: "120",
    //   align: "left",
    //   id: 8,
    // },
  ]);

  useEffect(() => {
    fetchVouchers(id);
  }, [currentPage, itemsPerPage, searchKey, range,setSearchKey]);

  const formatDate = (dateString) => {
    const parts = dateString.split("-");
    const day = parseInt(parts[0]);
    const month = parseInt(parts[1]) - 1; // Month is zero-based in Date object
    const year = parseInt(parts[2]);
    return new Date(year, month, day).toLocaleDateString("en-GB");
  };

  const fetchVouchers = async () => {
    // const offset = (currentPage - 1) * itemsPerPage;
    // const offsetToUse = offset >= 0 ? offset : 0
    try {
      const requestBody = {
        offset: (currentPage - 1) * itemsPerPage,
        limit: itemsPerPage,
        search: searchKey,
        // startDate: convertToISOString(range.startDate),
        // endDate: convertToISOString(range.endDate)
      };
      const res = await getVoucherDetails(id,requestBody);
      console.log('res::: ', res);
      if (res?.status === 200 && res?.data?.statusCode === 200) {
        setVouchers(res?.data?.data?.getallVouchers);
        setTotalCount(res?.data?.data?.totalCount);
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {
      console.error("Error fetching vouchers:", error.message);
      toast.error("Failed to fetch voucher data");
    }
  };

  return (
    <div className="Table_Wrapper tableResponsive">
 

      {/* {TransactionTable && ( */}
      <>
        {/* <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween">
            <div>
              <h5 className="fontSize20 fontWeight600 blackClr_text">
                {" "}
                All Transactions List
              </h5>
            </div>


          </div> */}
        {isLoading ? (
          <PaginatedTable
            row={vouchers}
            column={column}
            totalCount={totalCount}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            isSearch={true}
            searchKey={searchKey}
            setSearchKey={setSearchKey}
            maxPageNumberLimit={maxPageNumberLimit}
            setMaxPageNumberLimit={setMaxPageNumberLimit}
            minPageNumberLimit={minPageNumberLimit}
            setMinPageNumberLimit={setMinPageNumberLimit}
            isExport={false}
            setRange={setRange}
            range={range}
            isDateRange={false}
          />
        ) : (
          <div style={{ textAlign: "center" }}>Loading...</div>
        )}
      </>
      {/* )} */}
    </div>
  );
};

export default CustomerVoucher;
