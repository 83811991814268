import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import { selectCurrentUser } from "../../store/user/user.selector";
import BackIcon from "../../assets/images/back_icon.svg";
import CreateIcon from "../../assets/images/create_icon.svg";
import { CreateCategory } from "../../services/Category.service";
import CloseIcon from "../../assets/images/close_icon.svg";
import UploadImg from "../../assets/images/upload_img.svg";
import { fileUpload } from "../../services/common.service";
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";
import { getDocumentlist,CreateDocument } from "../../services/Document.service";
import { useTranslation } from 'react-i18next';  // Import useTranslation


const fileType = "Voucher";
const CreateDocumentType = ({ brand = [], back }) => {
  const handleClose = () => {
    resetForm();
    setErrors({});
    back(false);
  };
  const { t, i18n } = useTranslation(); // Translation hook

  const user = useSelector(selectCurrentUser)?.userDetail;
  const id = user?.userType === "brand" ? user?.brand_id : "";
  const today = new Date().toISOString().split("T")[0];

  // form
  const {
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues: {
      type: "",

   
    },
    validationSchema: Yup.object({
      type: Yup.string()
      .matches(/^[a-zA-Z0-9\s]*$/, 'Only alphanumeric characters and spaces are allowed')
        .max(50, "Document Type must be at most 50 characters")
        .required("Document Type is required"),
      // logo: Yup.string().required("logo is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
   
      try {
      
        const payload = {
          ...values,
          category_logo: values?.logo,
        };

        const res = await CreateDocument(payload);

        if (res?.status === 200 && res?.data?.statusCode === 200) {
          toast.success(res.data.message);
          resetForm();
          setErrors({});
          back();
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          console.error(msg);
        }
        console.log(error.message);
      }
    },
  });

  const handleFileChange = async (event) => {
    try {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile, selectedFile.name);
        let res = await fileUpload(formData, fileType);
        if (res?.data?.statusCode === 200) {
          setFieldValue("logo", res?.data?.data);
          setFieldValue("selectedLogo", URL.createObjectURL(selectedFile));
        }
      }
    } catch (error) {}
  };

  const removeLogo = () => {
    setFieldValue("logo", "");
    setFieldValue("selectedLogo", "");
  };

  const handleTypeChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  return (
    <div className="whiteFrame">
      <div className="mb_24 displayFlex alignItem_center gap12">
        <div>
          <button
            className="emptyBtn"
            type="button"
            onClick={handleClose}
          >
            <img src={ArrowleftIcon} alt="ArrowleftIcon" />
          </button>
        </div>
        <div>
          <h5 className="fontSize20 fontWeight600 blackClr_text">
          Add Merchant Document Type

          </h5>
        </div>
       
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              {t("document.DocumentType")}
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Document Type "
              id="type"
              name="type"
              value={values.type}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.type && touched.type ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.type}
              </p>
            ) : (
              <></>
            )}
          </div>
       
        </div>

        {/* ========= Submit Button ========= */}
        <button type="submit" className="MainButton cyanSky_clr gap12">
          <img src={CreateIcon} alt="CreateIcon" className="width_20px" />
          <span>{t("document.CreateDocumenttype")}</span>
        </button>
      </form>
    </div>
  );
};

export default CreateDocumentType;
