import React, { useEffect, useState } from "react";

import toast from "react-hot-toast";
import BackIcon from "../../assets/images/back_icon.svg";
import DeleteIcon from "../../assets/images/delete_icon.svg";
import DeactivateIcon from "../../assets/images/deactivate_icon.svg";
import ActivateIcon from "../../assets/images/active_icon.svg";
import PaginatedTable from "../common/PaginatedTable";
import ViewIcon from "../../assets/images/view_icon.svg";
import EditIcon from "../../assets/images/edit_icon.svg";
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";
import FilterIcon from "../../assets/images/filter_icon.svg";

import { selectCurrentUser } from "../../store/user/user.selector";
import { useSelector } from "react-redux";
import {
  getSupportquery,
  updateSupportStatus,
} from "../../services/supportquery.service";
import EditVoucher from "./EditSupportQuery";

import {
  updateVoucherStatus,
  deleteVoucher,
  deleteVoucherById,
  getVoucherlist,
} from "../../services/voucher.service";
import { formatCurrency } from "../../utils/helpers/helper";
import ConfirmPopup from "../common/confirmation component/ConfirmPopup";
import EditSupportQuery from "./EditSupportQuery";
import { useTranslation } from 'react-i18next';  // Import useTranslation


function ViewSupportQuery({ voucher, back, brand }) {
  const { t, i18n } = useTranslation(); // Translation hook

  const [Vouchers, setVouchers] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [voucherTable, setVoucherTable] = useState(true);
  const user = useSelector(selectCurrentUser)?.userDetail;

  const id = user?.userType === "brand" ? user?.brand_id : "";

  //Paginated Table Variables
  let [totalCount, setTotalCount] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  let [itemsPerPage, setItemsPerPage] = useState(10);
  let [searchKey, setSearchKey] = useState("");
  let [isLoading, setIsLoading] = useState(true);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [activePopup, setActivePopup] = useState(null);
  const idString = id.toString();

  const getNextStatus = (currentStatus) => {
    switch (currentStatus) {
      case "open":
        return "onprogress";
      case "onprogress":
        return "completed";
      case "completed":
        return "open";
      default:
        return "open";
    }
  };

  const confirm = {
    status: "status",
    delete: "delete",
  };

  const [statusFilter, setStatusFilter] = useState(voucher?.status || "open");

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const voucherStatusUpdate = async (voucher) => {
    try {
      const nextStatus = getNextStatus(voucher?.status);
      const payload = {
        query_id: voucher?.id,
        user_id: voucher?.user_id,
        admin_id: user.user_id,
        status: nextStatus,
      };

      const res = await updateSupportStatus(payload);

      if (res?.status === 200 && res?.data?.statusCode === 200) {
        toast?.success(res?.data?.message);
        back();
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {}
  };

  const deleteVoucher = async (id) => {
    try {
      const payload = {
        id: id,
      };
      const res = await deleteVoucherById(payload);
      if (res?.status === 200 && res?.data?.statusCode === 200) {
        toast?.success(res?.data?.message);
        back();
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {}
  };

  const handleShowedit = (voucher) => {
    // setdata(data);
    setShowEdit(true);
    setVoucherTable(false);
  };

  const [column, setColumn] = useState([
    {
      heading: () => "Code",
      cell: (row) => (row["code"] ? row["code"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 2,
    },

    {
      heading: () => "Customer Name/ Customer Mobile",
      cell: (row) => (row["customer_name"] ? row["customer_name"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 3,
    },

    {
      heading: () => "Create Date",
      cell: (row) => (row["createdDate"] ? row["createdDate"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 5,
    },

    {
      heading: () => "Redeemed",
      cell: (row) => (
        <span style={{ color: row["redeemed"] ? "green" : "red" }}>
          {row["redeemed"] ? "Redeemed" : "Not-Redeemed"}
        </span>
      ),
      hidden: false,
      width: "120",
      align: "left",
      id: 8,
    },
  ]);

  let filesLinks = [];
  if (typeof voucher?.files_links === "string") {
    try {
      filesLinks = JSON.parse(voucher.files_links);
    } catch (error) {
      console.error("Error parsing files_links:", error);
    }
  } else if (Array.isArray(voucher?.files_links)) {
    filesLinks = voucher.files_links;
  }

  useEffect(() => {
    setStatusFilter(voucher?.status);
  }, [currentPage, itemsPerPage, searchKey]);

  const confirmPopFunction = () => {
    if (confirm?.status === activePopup) voucherStatusUpdate(voucher);
    if (confirm?.delete === activePopup) deleteVoucher(voucher?.id);
    setActivePopup(null);
  };

  return (
    <>
      <ConfirmPopup
        message="Are you sure you want to proceed with this action?"
        onConfirm={confirmPopFunction}
        onCancel={() => setActivePopup(null)}
        isOpen={confirm?.status === activePopup}
      />

      <ConfirmPopup
        message="Are you sure you want to proceed with this action?"
        onConfirm={confirmPopFunction}
        onCancel={() => setActivePopup(null)}
        isOpen={confirm?.delete === activePopup}
      />
      <h2 className="fontSize20 blackClr_text mb_16">
        {/* <span className="fontWeight400">Merchant Name - </span>
        <span className="fontWeight600">{voucher?.brand_name}</span> */}
      </h2>
      <div className="whiteFrame">
        <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween Mob_flexWrapGap BorderBottom_LavenderSyrup pb_16">
          <div className="displayFlex alignItem_center gap12">
            <div>
              <button className="emptyBtn" onClick={() => back(false)}>
                <img src={ArrowleftIcon} alt="ArrowleftIcon" />
              </button>
            </div>
            <div>
              {/* <h2 className="fontSize20 blackClr_text mb_16">
                        <span className="fontWeight400">Merchant Name - </span>
                        <span className="fontWeight600">{voucher?.brand_name}</span>
                    </h2><br></br> */}
              <h5 className="fontSize20 fontWeight600 blackClr_text">
                View Support Query
              </h5>
            </div>
          </div>
          <div className="displayFlex justifyContent_end gap12">
            <div>
              {(voucher?.status === "open" ||
                voucher?.status === "onprogress") && (
                //  {" "}
                


            <div className="grayGroupinput">
                    <div className="inputgroup_append">
                    <span className="grayGroupinput_text">
                        <button type="button" className="emptyBtn">
                        <img src={FilterIcon} alt="FilterIcon" className="width20px black_filter" />
                        
                        </button>
                      </span>
                    </div>
                  <select
                    value={statusFilter}
                    onChange={(e) => {
                      handleStatusFilterChange(e);
                      voucherStatusUpdate(voucher);
                    }}
                  >
                    {/* <option value="all">All</option> */}
                    <option value="open">Open</option>
                    <option value="onprogress">On Progress</option>
                    <option value="completed">Completed</option>
                  </select>
              </div>
              )}
            </div>
          </div>
        </div>

        {voucherTable && (
          <>
            <div className="tableResponsive">
              <table className="table_detail">
                <tr>
                  <th> Name</th>
                  <td>{voucher?.name}</td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{voucher?.email}</td>
                </tr>

                <tr className="textWrap_Wrap">
                  <th> Message</th>
                  <td >
                    <span className="textWrap_Wrap">
                      {voucher?.message} </span></td>
                </tr>
                <tr>
                  <th>Files Links</th>
                  <td>
    {filesLinks.length > 0 ? (
      <ul className="emptyList displayFlex alignItem_center gap12">
        {filesLinks.map((link, index) => (
          <li key={index}>
            <a
              className="fontSize14 fontWeight500 cyanSkyClr_text"
              href={link}
              target="_blank"
              rel="noopener noreferrer"
            >
              File {index + 1}
            </a>
          </li>
        ))}
      </ul>
    ) : (
      <p>No files available</p>
    )}
    {/* <button onClick={downloadAllFiles}>Download All</button> */}
  </td>
                </tr>
                <tr>
                  <th>Status</th>
                  {/* <td>{voucher?.status}</td>
                   */}
                    <td>{voucher?.status ? capitalizeFirstLetter(voucher.status) : "-"}</td>
                </tr>
              </table>
            </div>
          </>
        )}

        {showEdit && (
          <EditSupportQuery data={voucher} brand={brand} back={back} />
        )}
      </div>
    </>
  );
}

export default ViewSupportQuery;
