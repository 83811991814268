import React, { useEffect, useState } from "react";
import CreateAdmin from "./CreateAdmin";
import { getAdminUserList } from "../../services/super-admin.service";
import AddIcon from "../../assets/images/add_icon.svg";
import toast from "react-hot-toast";
import PaginatedTable from "../common/PaginatedTable";
import { useTranslation } from 'react-i18next';  // Import useTranslation

const AdminList = () => {
  const [list, setList] = useState([]);
  const [showCreate, setShowCreate] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const { t, i18n } = useTranslation(); // Translation hook

  //Paginated Table Variables
  let [totalCount, setTotalCount] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  let [itemsPerPage, setItemsPerPage] = useState(10);
  let [searchKey, setSearchKey] = useState("");
  let [isLoading, setIsLoading] = useState(true);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [status, setStatus] = useState("");

  const handleShowCreate = () => {
    setShowCreate(true);
    setShowTable(false);
  };

  const back = (cond = true) => {
    cond && adminUserList();
    setShowCreate(false);
    setShowTable(true);
  };

  useEffect(() => {
    adminUserList();
  }, [currentPage, itemsPerPage, searchKey ,setSearchKey,status]);

  const [column, setColumn] = useState([
    // {
    //   heading: () => "#",
    //   cell: (row, i) => {
    //     return i;
    //   },
    //   hidden: false,
    //   width: "20",
    //   id: 1,
    // },
    {
      heading: () => t("common.FirstName"),
      cell: (row) => (row["first_name"] ? row["first_name"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 1,
    },
    {
      heading: () => t("common.LastName"),
      cell: (row) => (row["last_name"] ? row["last_name"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 2,
    },

    {
      heading: () => t("customer.Email"),
      cell: (row) => (row["email"] ? row["email"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 3,
    },
    {
      heading: () => t("customer.Mobile"),
      cell: (row) => (row["mobile"] ? `+91 -${row["mobile"]}` : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 4,
    },

    {
      heading: () => t("common.Status"),
      cell: (row) => {
        console.log('row::: ', row);
        const status = row["active_flag"];
        console.log('status::: ', status);
        let displayText = "";
        let style = {};
        let className = "";

        if (status === true) {
          displayText = "Active";
          
        } else if (status === false) {
          displayText = "InActive";
          className = "fontWeight600 errorClr_text"; // Use class names directly
        } else if (status === "all") {
          displayText = "ALL Status";
          style = { color: "#28a745", fontWeight: 600 };
        } else {
          displayText = "-";
          style = { fontWeight: 600 };
        }

        return <span className={row?.active_flag ? "fontWeight600 UFOGreen_text" : "fontWeight600 errorClr_text"} style={style}>{displayText}</span>;
      },
      hidden: false,
      width: "120",
      align: "left",
      id: 8,
    },
  ]);

  const adminUserList = async () => {
    const offset = (currentPage - 1) * itemsPerPage;
    const offsetToUse = offset >= 0 ? offset : 0;

    try {
      const requestBody = {
        offset: offsetToUse,
        limit: itemsPerPage,
        search: searchKey,
        statusfilter: status || "all"
      };
      let res = await getAdminUserList(requestBody);
      if (res?.status === 200 && res?.data?.statusCode === 200) {
        setList(res?.data?.data);
        setTotalCount(res?.data?.count);
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();
        console.error(msg);
        // alert(msg);
      }
      console.log(error.message);
    }
  };

  return (
    <div>
      {showTable && (
        <div>
          <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween">
            <div>
              <h5 className="fontSize20 fontWeight600 blackClr_text">
                {" "}
                {t("admin.AdminList")}
              </h5>
            </div>
            <div>
              <button
                className="MainButton cyanSky_clr gap8"
                type="button"
                onClick={handleShowCreate}
              >
                <img className="width20px" src={AddIcon} alt="AddIcon" />
                <span>{t("admin.CreateAdminUser")} </span>
              </button>
            </div>
          </div>

          {isLoading ? (
            <PaginatedTable
              row={list}
              column={column}
              totalCount={totalCount}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isSearch={true}
              searchKey={searchKey}
              setSearchKey={setSearchKey}
              maxPageNumberLimit={maxPageNumberLimit}
              setMaxPageNumberLimit={setMaxPageNumberLimit}
              minPageNumberLimit={minPageNumberLimit}
              setMinPageNumberLimit={setMinPageNumberLimit}
              //   setRange={setRange}
              //   range={range}
              isExport={false}
              isDateRange={false}
              status={status}
              setStatus={setStatus}
              isstatus={true}
              //   handleExport={() => handleExportTransactions()}
            />
          ) : (
            <div style={{ textAlign: "center" }}>Loading...</div>
          )}
        </div>
      )}

      {showCreate && <CreateAdmin back={back} />}
    </div>
  );
};

export default AdminList;
