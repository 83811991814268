import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from 'react-hot-toast';

import { GetVoucherSetting, UpdateVoucherSetting } from '../../services/voucher.service';
import { formatCurrency } from '../../utils/helpers/helper';

import { useTranslation } from 'react-i18next';  

const Vouchersetting = ({ editVoucherSetting, setEditVoucherSetting }) => {

    const { id } = useParams();
    const { t, i18n } = useTranslation(); // Translation hook

    const [settingDetail, setSettingDetail] = useState(null);


    const fetchVoucherSetting = async () => {
        try {
            if (!id) return null;
            const req = {
                type: "get",
                merchant_id: id
            }

            const resp = await GetVoucherSetting(req);
            if (resp?.data?.statusCode === 200) {

                if (resp?.data?.data) {
                    setSettingDetail(resp?.data?.data);
                }
            } else {
                toast.error(resp?.message || resp?.data?.message);
            }
        } catch (error) {
            if (error?.message) {
                const msg = error?.message?.split(":")?.[1]?.trim();

                console.error(msg);
            }
            console.log(error.message);
        }
    };



    useEffect(() => {
        return () => setSettingDetail(null)
    }, [])

    useEffect(() => {
        if (!editVoucherSetting) {

            fetchVoucherSetting();
        }

    }, [editVoucherSetting])


    return (
        <div>

            {
                !editVoucherSetting && <div className="tableResponsive">
                    <table className="table_detail">
                        <tbody>
                            <tr>
                                <th>{t("campaign.Offertype")} :</th>
                                <td>{settingDetail?.offer_type || "-"}</td>
                            </tr>
                            <tr>
                                <th>{t("merchant.OfferValue")} : </th>
                                <td> {(settingDetail?.offer_type === 'Percentage' ? `${settingDetail?.offer_value}%` : settingDetail?.offer_value && formatCurrency(settingDetail?.offer_value)) || "-"}</td>
                            </tr>
                            <tr>
                                <th>{t("merchant.DiscountValue")} :</th>
                                <td>{settingDetail?.discount_value && formatCurrency(settingDetail?.discount_value) || "-"}</td>
                            </tr>
                            <tr>
                                <th>{t("merchant.MinSpend")} :</th>
                                <td>{settingDetail?.min_spend && formatCurrency(settingDetail?.min_spend) || "-"}</td>
                            </tr>
                            <tr>
                                <th>{t("voucher.Upperlimit")} : </th>
                                <td>{settingDetail?.upper_limit && formatCurrency(settingDetail?.upper_limit) || "-"}</td>
                            </tr>

                        </tbody>
                    </table>

                </div>
            }


            {editVoucherSetting && <VoucherSettingEdit data={settingDetail} setEditVoucherSetting={setEditVoucherSetting} />}

        </div>
    )
}

export default Vouchersetting




export function VoucherSettingEdit({ data, setEditVoucherSetting }) {

    const { id } = useParams();

    const {
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        values,
        errors,
        touched,
        resetForm,
        setErrors,
    } = useFormik({
        initialValues: {
            offer_type: data?.fee_type || 'Value',
            type: "update",
            offer_value: data?.offer_value,
            discount_value: data?.discount_value,
            min_spend: data?.min_spend,
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            offer_type: Yup.string()
                .required("offer type is required"),
            offer_value: Yup.string().test({
                name: "offer_value",
                exclusive: false,
                test: function (value, { createError }) {
                    const isNumeric = /^[0-9]+$/.test(value);
                    if (isNumeric) {
                        if (this.parent.offer_type === "Percentage") {
                            if (parseInt(value, 10) < 100) return true; // Reset error
                            else return createError({ message: "Value must be a number and below 100 for percentage offers" });
                        } else {
                            if (parseInt(value, 10) < parseInt(this.parent.min_spend, 10)) return true; // Reset error
                            else return createError({ message: "Value must be a number and below min spend" });
                        }
                    } else {
                        return true; // Reset error for non-numeric values
                    }
                },
            }).required("Value is required"),
            min_spend: Yup.string()
                .required("Min spend  is required")
                .matches(/^[1-9][0-9]*$/, "Min spend must above 0"),
            upper_limit: Yup.string().test({
                name: "upper_limit",
                exclusive: false,
                message: "Upper limit must be a number and less than min spend",
                test: function (value, { parent }) {
                    const isNumeric = /^[0-9]+$/.test(value);
                    if (parent.offer_type === "Value") {
                        return true;
                    }
                    if (isNumeric) {
                        if (parent.offer_type === "Percentage") {
                            return parseInt(value, 10) > 0 && (parseInt(value, 10) < parent.min_spend);
                        } else {
                            return true; // Reset error for non-"Percentage" offer types
                        }
                    } else {
                        return false; // Reset error for non-numeric values
                    }
                },
            }),

        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const payload = {
                    offer_type: values?.offer_type,
                    type: "update",
                    offer_value: values?.offer_value,
                    discount_value: values?.offer_type === "Percentage" ? +values?.upper_limit : +values?.offer_value,
                    min_spend: +values?.min_spend,
                    upper_limit: +values?.upper_limit,
                    merchant_id: id
                }
                const res = await UpdateVoucherSetting(payload);

                if (res?.data?.statusCode === 200) {
                    toast.success(res.data.message);
                    resetForm();
                    setEditVoucherSetting(false)

                } else {
                    toast.error(res?.message || res?.data?.message);
                }
            } catch (error) {
                if (error?.message) {
                    const msg = error?.message?.split(":")?.[1]?.trim();
                    console.error(msg);
                }
                console.log(error.message);
            }
        },
    });


  

    const handleTypeChange = (e) => {
        const { name, value } = e.target;
        setFieldValue(name, value);
    };

    useEffect(() => {
        return () => {
            resetForm();
            setErrors({});
        };
    }, []);

    return (
        <div>
            <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween">
                <div>
                    <h5 className="fontSize20 fontWeight600 blackClr_text">Edit Voucher Setting</h5>
                </div>

            </div>




            <form onSubmit={handleSubmit}>

                <div className='row'>


                    <div className="mb_24 col-md-6">
                        <label
                            className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                            htmlFor="toggleValueInput"
                        >
                            Offer type
                        </label>
                        <div className='displayFlex alignItem_center gap12'>
                            <div className='prefer_method'>
                                <input
                                    type="radio"
                                    id="offer_value"
                                    name="offer_type"
                                    value="Value"
                                    checked={values.offer_type === "Value"}
                                    onChange={(e) => handleTypeChange(e)}
                                />
                                <label htmlFor="offer_value">Amount</label>
                            </div>
                            <div className='prefer_method'>
                                <input
                                    type="radio"
                                    id="offer_percentage"
                                    name="offer_type"
                                    value='Percentage'
                                    checked={values.offer_type === "Percentage"}
                                    onChange={(e) => handleTypeChange(e)}
                                />
                                <label htmlFor="offer_percentage">Percentage</label>
                            </div>
                        </div>
                        {errors.offer_type && touched.offer_type ? (
                            <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                                {errors.offer_type}
                            </p>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="mb_24 col-md-6">
                        <label
                            className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                            htmlFor="email"
                        >
                            Minimum spend
                        </label>
                        <input
                            className="MainInput"
                            type="text"
                            placeholder="Enter Value"
                            id="min_spend"
                            name="min_spend"
                            value={values.min_spend}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        {errors.min_spend && touched.min_spend ? (
                            <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                                {errors.min_spend}
                            </p>
                        ) : (
                            <></>
                        )}
                    </div>

                    <div className="mb_24 col-md-6">
                        <label
                            className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                            htmlFor="email"
                        >
                            {values?.offer_type === "Value" ? "Amount" : "Percentage"}
                        </label>
                        <input
                            className="MainInput"
                            type="text"
                            placeholder="Enter Value"
                            id="offer_value"
                            name="offer_value"
                            value={values.offer_value}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        {errors.offer_value && touched.offer_value ? (
                            <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                                {errors.offer_value}
                            </p>
                        ) : (
                            <></>
                        )}
                    </div>



                    {
                        values.offer_type === "Percentage" &&
                        <div className="mb_24 col-md-6">
                            <label
                                className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                                htmlFor="email"
                            >
                                Upper limit
                            </label>
                            <input
                                className="MainInput"
                                type="text"
                                placeholder="Enter Value"
                                id="upper_limit"
                                name="upper_limit"
                                value={values.upper_limit}
                                onBlur={handleBlur}
                                onChange={handleChange}
                            />
                            {errors.upper_limit && touched.upper_limit ? (
                                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                                    {errors.upper_limit}
                                </p>
                            ) : (
                                <></>
                            )}
                        </div>
                    }

                </div>

                {/* ========= Submit Button ========= */}


                <button type="submit" className="MainButton cyanSky_clr gap12">
                    {/* <img src={CreateIcon} alt='CreateIcon' className='width_20px' /> */}
                    <span>Update Voucher setting</span>
                </button>
            </form>





        </div>
    )
}
