import React, { useEffect, useState } from "react";

import toast from "react-hot-toast";
import BackIcon from "../../assets/images/back_icon.svg";
import DeleteIcon from "../../assets/images/delete_icon.svg";
import DeactivateIcon from "../../assets/images/deactivate_icon.svg";
import ActivateIcon from "../../assets/images/active_icon.svg";
import PaginatedTable from "../common/PaginatedTable";
import ViewIcon from "../../assets/images/view_icon.svg";
import EditIcon from "../../assets/images/edit_icon.svg";
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";
import EditVoucher from "./EditVoucher";
import { useTranslation } from 'react-i18next';  // Import useTranslation

import {
  updateVoucherStatus,
  deleteVoucher,
  deleteVoucherById,
  getVoucherlist,
} from "../../services/voucher.service";
import { formatCurrency } from "../../utils/helpers/helper";
import ConfirmPopup from "../common/confirmation component/ConfirmPopup";
import { selectRole } from "../../store/role/role.selector";
import { useSelector } from "react-redux";

function ViewVoucher({ voucher, back,brand }) {
  const [Vouchers, setVouchers] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [voucherTable, setVoucherTable] = useState(true);
  //Paginated Table Variables
  const { t, i18n } = useTranslation(); // Translation hook

  let [totalCount, setTotalCount] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  let [itemsPerPage, setItemsPerPage] = useState(10);
  let [searchKey, setSearchKey] = useState("");
  let [isLoading, setIsLoading] = useState(true);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [activePopup, setActivePopup] = useState(null);
  const role = useSelector(selectRole);

  const confirm = {
    status: "status",
    delete: "delete",
  };

  const voucherStatusUpdate = async (voucher) => {
    try {
      const payload = {
        id: voucher?.id,
        status: !voucher?.active_flag,
      };
      const res = await updateVoucherStatus(payload);

      if (res?.status === 200 && res?.data?.statusCode === 200) {
        toast?.success(res?.data?.message);
        back();
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {}
  };

  const deleteVoucher = async (id) => {
    try {
      const payload = {
        id: id,
      };
      const res = await deleteVoucherById(payload);
      if (res?.status === 200 && res?.data?.statusCode === 200) {
        toast?.success(res?.data?.message);
        back();
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {}
  };

  const handleShowedit = (voucher) => {
    // setdata(data);
    setShowEdit(true);
    setVoucherTable(false);
  };

  const getVoucher = async () => {
    const offset = (currentPage - 1) * itemsPerPage;
    const offsetToUse = offset >= 0 ? offset : 0;
    try {
      const payload = {
        id: voucher?.id,
        offset: offsetToUse,
        limit: itemsPerPage,
        search: searchKey,
      };
      const res = await getVoucherlist(payload);
      if (res?.status === 200 && res?.data?.statusCode === 200) {
        setVouchers(res?.data?.data || []);
        setTotalCount(res?.data?.count);
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();
        // alert(msg);
        console.error(msg);
      }
      console.log(error.message);
    }
  };

  const [column, setColumn] = useState([
    {
      heading: () => t("customer.Code"),
      cell: (row) => (row["code"] ? row["code"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 2,
    },

    {
      heading: () => t("customer.CustomerName")+"/"+t("customer.CustomerMobile"),
      cell: (row) => (row["customer_name"] ? row["customer_name"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 3,
    },

    {
      heading: () => t("customer.CreatedDate"),
      cell: (row) => {
        if (row["created_date"]) {
          const dateObj = new Date(row["created_date"]);
          const date = dateObj.toLocaleDateString(); 
          const time = dateObj.toLocaleTimeString(); 
          return `${date} ${time}`;
        } else {
          return "-";
        }
      },
      hidden: false,
      width: "120",
      align: "left",
      id: 5,
    },

    {
      heading: () => t("voucher.Redeemed"),
      cell: (row) => (
        <span style={{ color: row["redeemed"] ? "green" : "red" }}>
          {row["redeemed"] ? "Redeemed" : "Not-Redeemed"}
        </span>
      ),
      hidden: false,
      width: "120",
      align: "left",
      id: 8,
    },
  ]);

  useEffect(() => {
    getVoucher(voucher?.id);
  }, [currentPage, itemsPerPage, searchKey]);

  const confirmPopFunction = () => {
    if (confirm?.status === activePopup) voucherStatusUpdate(voucher);
    if (confirm?.delete === activePopup) deleteVoucher(voucher?.id);
    setActivePopup(null);
  };

  return (
    <>
      <ConfirmPopup
        message="Are you sure you want to proceed with this action?"
        onConfirm={confirmPopFunction}
        onCancel={() => setActivePopup(null)}
        isOpen={confirm?.status === activePopup}
      />

      <ConfirmPopup
        message="Are you sure you want to proceed with this action?"
        onConfirm={confirmPopFunction}
        onCancel={() => setActivePopup(null)}
        isOpen={confirm?.delete === activePopup}
      />
      <h2 className="fontSize20 blackClr_text mb_16">
        <span className="fontWeight400 textWrap_Wrap">{t("merchant.MerchantName")}
        - </span>
        <span className="fontWeight600" >{voucher?.brand_name}</span>
      </h2>
      <div className="whiteFrame">
        <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween Mob_flexWrapGap BorderBottom_LavenderSyrup pb_16">
          <div className="displayFlex alignItem_center gap12">
            <div>
              <button className="emptyBtn" onClick={() => back(false)}>
                <img src={ArrowleftIcon} alt="ArrowleftIcon" />
              </button>
            </div>
            <div>
              {/* <h2 className="fontSize20 blackClr_text mb_16">
                        <span className="fontWeight400">Merchant Name - </span>
                        <span className="fontWeight600">{voucher?.brand_name}</span>
                    </h2><br></br> */}
              <h5 className="fontSize20 fontWeight600 blackClr_text">
                {/* View Voucher */}
                {showEdit ? "Edit Voucher Details" : "View Voucher Details"}
              </h5>
            </div>
          </div>
          {!showEdit && (
              <div className="displayFlex justifyContent_end gap12">
              <div>
                {/* <button
                className="MainButton lemonPie_clr gap8"
                type="button"
                onClick={() => handleShowedit(voucher)}
              >
                <img className="width20px" src={EditIcon} alt="EditIcon" />
                <span className="custom_tooltiptext"></span>
                <span></span>
              </button> */}
                <button
                  className="customTooltip actionIcon_btn lemonPie_clr gap8"
                  onClick={() => handleShowedit(voucher)}
                >
                  <img
                    className="width20px"
                    src={EditIcon}
                    alt="EditIcon"
                  />
                  <span className="custom_tooltiptext">{t("common.Edit")}</span>
                </button>
              </div>
              {/* <div>
              <button
                className="MainButton neverything_clr gap8"
                type="button"
                onClick={() => back(false)}
              >
                {" "}
                <img src={BackIcon} alt="BackIcon" />
                <span> Back </span>
              </button>
            </div> */}
              {

                (role == "admin" || role == "superadmin") &&
                  <div>
                    <button
                      className={
                        voucher?.active_flag
                          ? "actionIcon_btn errorText_clr customTooltip"
                          : "actionIcon_btn UFOGreen_clr customTooltip"
                      }
                      onClick={() => setActivePopup(confirm?.status)}
                    >
                      {voucher?.active_flag ? (
                        <img
                          src={DeactivateIcon}
                          alt="deactivate_icon"
                          className="width20px"
                        />
                      ) : (
                        <img
                          src={ActivateIcon}
                          alt="ActivateIcon"
                          className="width20px"
                        />
                      )}
                      <span className="custom_tooltiptext">
                        {" "}
                        {voucher?.active_flag ? "Deactivate" : "Activate"}
                      </span>
                    </button>
                  </div>
              }

  
              <div>
                <button
                  className="customTooltip actionIcon_btn errorText_clr"
                  onClick={() => setActivePopup(confirm?.delete)}
                >
                  <img className="width20px" src={DeleteIcon} alt="DeleteIcon" />
                  <span className="custom_tooltiptext">Delete</span>
                </button>
              </div>
            </div>

          )}
        
        </div>

        {voucherTable && (
          <>
            <div className="row mb_24">
              <div className="col-md-2 mob_mb24">
                <label
                  className="fontSize14 fontWeight500 blackClr_text display_block mb_16"
                  htmlFor="fn"
                >
                  {t("voucher.VoucherLogo")}
                </label>

                <div className="viewBrand_logo">
                  {/* PlaceholderImg */}
                  <img
                    src={voucher?.campaign_logo || "-"}
                    alt="brand_logo"
                    width={100}
                    height={100}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <label
                  className="fontSize14 fontWeight500 blackClr_text display_block mb_16"
                  htmlFor="fn"
                >
                  
                  {t("voucher.VoucherBanner")}

                </label>

                <div className="viewBrand_logo">
                  {/* PlaceholderImg */}
                  <img
                    src={voucher?.banner_image || "-"}
                    alt="brand_logo"
                    width={100}
                    height={100}
                  />
                </div>
              </div>
            </div>
            <div className="tableResponsive">
              <table className="table_detail">
                <tr>
                  <th>{t("voucher.VoucherName")} </th>
                  <td className="textWrap_Wrap">{voucher?.campaign_name}</td>
                </tr>
                <tr>
                  <th>{t("merchant.MerchantName")}</th>
                  <td className="textWrap_Wrap">{voucher?.brand_name}</td>
                </tr>

                <tr>
                  <th> {t("common.StartDate")}</th>
                  <td>{voucher?.start_Date}</td>
                </tr>

                <tr>
                  <th>{t("common.EndDate")}</th>
                  <td>{voucher?.end_Date}</td>
                </tr>
                <tr>
                  <th>{t("voucher.OfferType")}</th>
                  <td>{voucher?.offer_type}</td>
                </tr>

                <tr>
                  <th>{t("common.Value")}</th>
                  <td>
                    {" "}
                    {voucher?.offer_type === "Percentage"
                      ? `${voucher?.offer_value}%`
                      : formatCurrency(voucher?.offer_value)}{" "}
                  </td>
                </tr>

                <tr>
                  <th>{t("campaign.CouponCount")}</th>
                  <td>{voucher?.coupon_count}</td>
                </tr>
                <tr>
                  <th>{t("voucher.AvailableCount")}</th>
                  <td>{voucher?.available_coupon}</td>
                </tr>
                <tr>
                  <th>{t("voucher.VoucherValue")}</th>
                  <td>{formatCurrency(voucher?.purchase_amount)}</td>
                </tr>

                {voucher?.offer_type === "Percentage" && (
                  <tr>
                    <th>{t("voucher.UpperLimit")}</th>
                    <td>{voucher?.upper_limit}</td>
                  </tr>
                )}
              </table>
            </div>

            <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween">
              <div>
                <h5 className="fontSize20 fontWeight600 blackClr_text">
                {t("voucher.VoucherList")}
                </h5>
              </div>
            </div>

            {isLoading ? (
              <PaginatedTable
                row={Vouchers}
                column={column}
                totalCount={totalCount}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                isSearch={false}
                searchKey={searchKey}
                setSearchKey={setSearchKey}
                maxPageNumberLimit={maxPageNumberLimit}
                setMaxPageNumberLimit={setMaxPageNumberLimit}
                minPageNumberLimit={minPageNumberLimit}
                setMinPageNumberLimit={setMinPageNumberLimit}
                //  setRange={setRange}
                //  range={range}
                isExport={false}
                isDateRange={false}
              />
            ) : (
              <div style={{ textAlign: "center" }}>Loading...</div>
            )}
          </>
        )}

        {showEdit && <EditVoucher data={voucher} brand={brand}  back={back} />}
      </div>
    </>
  );
}

export default ViewVoucher;
