import React, { useEffect, useState } from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";
import CreateIcon from "../../assets/images/create_icon.svg";
import { selectcountry } from "../../store/countrylist/country.selector";
import { useDispatch, useSelector } from "react-redux";
import { setcountry } from "../../store/countrylist/country.reducer";
import { Counrtylist } from "../../services/merchant.service";
import { addBrandBank, getBrandBankList, bankMasterList, setPrimaryAccount, removeBank } from "../../services/Bank.service";
import {
    latitudeRegex,
    longitudeRegex,
    phoneRegex,
    VatIdRegex,
    Swiftcode,
    IBANcode
  } from "../../utils/Regex";
import { useParams } from 'react-router-dom';
import toast from "react-hot-toast";
import AddBankIcon from "../../assets/images/addBank_icon.svg";
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";
import DeleteIcon from "../../assets/images/delete_icon.svg";
import PaginatedTable from "../common/PaginatedTable";
import { useNavigate } from "react-router-dom";
import ViewIcon from "../../assets/images/view_icon.svg";
import CreateBank from "./create-bank";
import Viewbank from './view-bank';
import { useTranslation } from 'react-i18next';  // Import useTranslation


const BankDetails = () => {
  const { t, i18n } = useTranslation(); // Translation hook

    const [bankList, setBankList] = useState();
    const navigate = useNavigate();
    console.log('bankList::: ', bankList?.[0]?.create_type);
    const { id } = useParams();
  
    const [addBank, setAddBank] = useState(false);
    const [Viewbanklist, setViewbanklist] = useState(false);

    const [brandTable, setBrandTable] = useState(true);
    const [data, setData] = useState({});


    //Paginated Table Variables
  let [totalCount, setTotalCount] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  let [itemsPerPage, setItemsPerPage] = useState(10);
  let [searchKey, setSearchKey] = useState("");
  let [isLoading, setIsLoading] = useState(true);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [range, setRange] = useState({
    startDate: undefined,
    endDate: new Date(),
  });

    const getBankList = async (id) => {
        try {
            const offset = (currentPage - 1) * itemsPerPage;
            const offsetToUse = offset >= 0 ? offset : 0
            const requestBody = {
                offset: offsetToUse,
                limit: itemsPerPage,
                search: searchKey,
                // startDate: convertToISOString(range.startDate),
                // endDate: convertToISOString(range.endDate)
              };
            const res = await getBrandBankList(id,requestBody);
            if (res?.status === 200 && res?.data?.statusCode === 200) {
                console.log('res::: ', res);
                setBankList(res?.data?.data);

                setTotalCount(res?.data?.count);
                setAddBank(false)
            
            } else {
                // toast.error(res?.message || res?.data?.message);
            }
        } catch (error) {
            if (error?.message) {
                const msg = error?.message?.split(":")?.[1]?.trim();
                // alert(msg);
                console.error(msg)
            }
            console.log(error.message);

        }

    }

    const setPrimaryAcc = async (accId) => {
        
        const payload = {
            id : id,
            account_id:accId,
            create_type:"Merchant"
            // rules_id: brand?.id,
            // status: !brand?.active_flag,
          };
    
        try {
            const res = await setPrimaryAccount(payload);
        
            if (res?.status === 200 && res?.data?.statusCode === 200) {
                toast.success(res?.data?.message || res?.message);
                getBankList(id)
            } else {
                toast.error(res?.message || res?.data?.message);
            }
        } catch (error) {
            if (error?.message) {
                const msg = error?.message?.split(":")?.[1]?.trim();
                // alert(msg);
                console.error(msg)
            }
            console.log(error.message);

        }

    }

    const removeAcc = async (accId) => {
        const payload = {
            id : id,
            account_id:accId,
            create_type:"Merchant"
          
          };
        try {
            const res = await removeBank(payload);
            if (res?.status === 200 && res?.data?.statusCode === 200) {
                toast.success(res?.data?.message || res?.message);
                getBankList(id)
            } else {
                toast.error(res?.message || res?.data?.message);
            }
        } catch (error) {
            if (error?.message) {
                const msg = error?.message?.split(":")?.[1]?.trim();
                // alert(msg);
                console.error(msg)
            }
            console.log(error.message);

        }

    }

    const handleBack = () => {

        if (addBank) {
            setAddBank(false)
            setBrandTable(true)
        } else {
            back();
        }
    }

    const back = (list = true) => {
      list && getBankList(id);
      setBrandTable(true);
      // setEdiform(false);
      setAddBank(false);
      setViewbanklist(false);
      // setcashbackview(false)
      setData({});
    };


    


    const [column, setColumn] = useState([
    
        {
            heading: () => t("common.Action"),
            cell: (row) => (
            //   <>
            //     {/* <button className="emptyBtn" onClick={() => navigate(`/admin/brand-detail/${row?.id}`)}>
            //       <img
            //         src={ViewIcon}
            //         alt="ViewIcon"
            //       />
            //     </button> */}
            //     <button className="emptyBtn" onClick={() => removeAcc(row.id)}>
            //                            <img className='bloodDonor_filter' src={DeleteIcon} alt='deleteIcon' />
                                           
            //                     </button> 
            //   </>

            <img
            src={ViewIcon}
            alt="ViewIcon"
            onClick={() => handleShowView(row)}
          />
              
            ),
            hidden: false,
             // width: "120",
            // align: "left",
            id: 11,
          },
          {
            heading: () => t("merchant.AccountType"),
            cell: (row) => (
              <>
                {/* <button className="emptyBtn" onClick={() => navigate(`/admin/brand-detail/${row?.id}`)}>
                  <img
                    src={ViewIcon}
                    alt="ViewIcon"
                  />
  
                </button> */}
                {row?.active_flag ? (
          <span className='cyanSkyClr_text fontWeight600'>{t("merchant.PrimaryAccount")}</span>
        ) : (
          <button className="MainButton UFOGreen_clr" onClick={() => setPrimaryAcc(row.id)}>
            <span>{t("merchant.SetPrimary")}</span>
          </button>
        )}
              </>
            ),
            hidden: false,
             // width: "120",
            // align: "left",
            id: 1,
          },
        {
          heading: () => t("common.Name"),
          cell: (row) => (row["name"] ? row["name"] : "-"),
          hidden: false,
          width: "120",
          align: "left",
          id: 2,
        },
        {
          heading: () => t("merchant.BankName"),
          cell: (row) => (row["bank_name"] ? row["bank_name"] : "-"),
          hidden: false,
          width: "120",
          align: "left",
          id: 3,
        },
        {
          heading: () => t("merchant.AccountNumber"),
        //   cell: (row) => {
           
        //     const dialCode = row.dial_code || "+971"; // Use an empty string if no dial code
        //     const mobile = row.mobile || "-"; // Use a dash if no mobile number
        //     return dialCode ? `${dialCode}-${mobile}` : mobile;
        //   },
          cell: (row) => (row["account_number"] ? row["account_number"] : "-"),
          hidden: false,
          width: "100",
          id: 4,
        },
        {
          heading: () => t("merchant.StreetAddress"),
          cell: (row) => (row["street_address"] ? row["street_address"] : "-"),
          hidden: false,
          width: "120",
          align: "left",
          id: 5,
        },
        {
          heading: () => t("merchant.City"),
          cell: (row) => (row["city"] ? row["city"] : "-"),
          hidden: false,
          width: "120",
          align: "left",
          id: 6,
        },
        {
            heading: () => t("merchant.StateName"),
            cell: (row) => (row["state"] ? row["state"] : "-"),
            hidden: false,
            width: "120",
            align: "left",
            id: 7,
          },
          {
            heading: () => t("merchant.Country"),
            cell: (row) => (row["country"] ? row["country"] : "-"),
            hidden: false,
            width: "120",
            align: "left",
            id: 8,
          },
          {
            heading: () => t("merchant.IBAN"),
            cell: (row) => (row["iban_number"] ? row["iban_number"] : "-"),
            hidden: false,
            width: "120",
            align: "left",
            id: 9,
          },
          {
            heading: () => t("merchant.SwiftCode"),
            cell: (row) => (row["swift_code"] ? row["swift_code"] : "-"),
            hidden: false,
            width: "120",
            align: "left",
            id: 10,
          },
    
     
      ]);

      useEffect(() => { getBankList(id) }, [currentPage, itemsPerPage, searchKey, range, setSearchKey])


      const handleShowView = (bankList) => {
        setData(bankList);
        // setcashbackview(true);
        // setBrandTable(false);
        // setcashbackview(true);
        setViewbanklist(true)
        setBrandTable(false)
      };

      const handleShow = () => {
        setAddBank(true);
        setBrandTable(false);
        // setcashproform(false)
      };



    return (
        <div>
            <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween BorderBottom_LavenderSyrup pb_16 Mob_flexWrapGap">
                <div className="displayFlex alignItem_center gap12">
                    <div>

                        <button className="emptyBtn" >
                            <img src={ArrowleftIcon} alt="ArrowleftIcon" onClick={handleBack} />
                        </button>
                    </div>
                    <div>
                        <h5 className="fontSize20 fontWeight600 blackClr_text">
                            {addBank ? "Add Bank details" : "Bank Accounts"}
                        </h5>
                    </div>
                </div>

                {
                    !addBank && !Viewbanklist && 
                    <div className="displayFlex alignItem_center gap12">
                        <div>
                            <button
                                className="MainButton lemonPie_clr gap8"
                                onClick={() => handleShow()}
                            >
                                <img src={AddBankIcon} alt="AddBankIcon" />
                                {"Add bank details"}
                            </button>
                        </div>






                    </div>
             } 

            </div>

        

{brandTable && (
    <>
        {isLoading ? (
            <PaginatedTable
                row={bankList}
                column={column}
                totalCount={totalCount}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                isSearch={false}
                searchKey={searchKey}
                setSearchKey={setSearchKey}
                maxPageNumberLimit={maxPageNumberLimit}
                setMaxPageNumberLimit={setMaxPageNumberLimit}
                minPageNumberLimit={minPageNumberLimit}
                setMinPageNumberLimit={setMinPageNumberLimit}
                setRange={setRange}
                range={range}
                isExport={false}
                isDateRange={true}
                // handleExport={() => handleExport()}
            />
        ) : (
            <div style={{ textAlign: "center" }}>Loading...</div>
        )}
    </>
)}



           

            {addBank && <CreateBank getBankList={getBankList} back={back} />}
            {Viewbanklist && <Viewbank data={data} back={back} />}

        </div>
    )
}







export default BankDetails