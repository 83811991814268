import React, { useEffect, useState } from "react";
import { editBrand } from "../../utils/validators/validators";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import { updatebrand, Counrtylist } from "../../services/super-admin.service";

import * as Yup from "yup";

import { fileUpload } from "../../services/common.service";

import { getLocation } from "../../services/Location.service";
import { updatelocation } from "../../services/Location.service";
import BackIcon from "../../assets/images/back_icon.svg";
import SaveIcon from "../../assets/images/save_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { setcountry } from "../../store/countrylist/country.reducer";
import { selectcountry } from "../../store/countrylist/country.selector";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";
import { selectRole } from "../../store/role/role.selector";
import {
  latitudeRegex,
  longitudeRegex,
  phoneRegex,
  VatIdRegex,
} from "../../utils/Regex";
import { useTranslation } from 'react-i18next';  

const fileType = "Merchant";

const EditLocationForm = ({ brand, back }) => {
  const { id } = useParams();
  const { t, i18n } = useTranslation(); // Translation hook

  const [EditData, SeteditData] = useState([]);

  const [dialCode, setDialCode] = useState("");
  const role = useSelector(selectRole);
  const location = useLocation();
  const navigate = useNavigate();
  const [isSingleStore, setIsSingleStore] = useState(
    EditData?.[0]?.is_single_store
  );
  const [TransactionTable, setTransactioTable] = useState(true);
  let [totalCount, setTotalCount] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  let [itemsPerPage, setItemsPerPage] = useState(10);
  let [searchKey, setSearchKey] = useState("");
  let [isLoading, setIsLoading] = useState(true);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [range, setRange] = useState({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(),
  });

  const [isOpen, setIsOpen] = useState(false);
  const handleTogglePicker = () => {
    setIsOpen(!isOpen);
  };

  const Rewardtype = [
    { name: "Open Loop", value: "openLoop" },
    { name: "Closed Loop", value: "closedLoop" },
  ];

  const storetype = [
    { key: "Offline", value: "OFFLINE" },
    { key: "Online", value: "ONLINE" },
    { key: "Both", value: "BOTH" },
  ];

  const countries = useSelector(selectcountry);
  const dispatch = useDispatch();
  const formikedit = useFormik({
    initialValues: {
      id: brand?.id,
      location: brand?.location,
      latitude: brand?.latitude,
      longitude: brand?.longitude,
      contact_name: brand?.contact_name,
      email: brand?.email,
      mobile: brand?.mobile,
      parent_merchant_id: brand?.parent_merchant_id,
      country: brand?.country,
      city: brand?.city,
      dial_code: brand?.dial_code,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid Email Id")
        .required("Email is required"),
      contact_name: Yup.string()
        .required("Contact name is required")
        .matches(
          /^[a-zA-Z0-9\s]*$/,
          "Only alphanumeric characters and spaces are allowed"
        ),

      mobile: Yup.string()
        .notRequired()
        .matches(phoneRegex, "Mobile number is not valid")
        .nullable(true)
        .required("Mobile is required"),

      latitude: Yup.string()
        .matches(latitudeRegex, "Invalid Latitude")
        .nullable(true),
      longitude: Yup.string()
        .matches(longitudeRegex, "Invalid Longitude")
        .nullable(true),
      location: Yup.string().required("Location is required"),
      city: Yup.string()
        .required("City name is required")
        .matches(
          /^[a-zA-Z0-9\/,\s-]+$/,
          "City Name must contain only letters, numbers, slashes, hyphens, and commas"
        ),
      country: Yup.string().required("Country is required"),
    }),
    onSubmit: async (values) => {
      // values.store_count = +values.store_count

      try {
        const res = await updatelocation(values);
        console.log("res::: ", res);
        if (res?.status === 200 && res?.data?.statusCode === 200) {
          toast.success(res.data.message);
          formikedit.resetForm();
          formikedit.setErrors({});
          if (role == "admin" || role == "superadmin") {
            back();
          } else {
            navigate(location.pathname, { replace: true });
          }
          // getMerchantDetail()
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          console.error(msg);
        }
        console.log(error.message);
      }
    },
  });

  const handleTypeChange1 = (e) => {
    const { name, value, id } = e.target;
    console.log(name, value, id, "JJJJJJJJJJJJ");
    formikedit.setFieldValue(name, value == "false" ? false : true);
    setIsSingleStore(value == "false" ? false : true);
    formikedit.setFieldValue("store_count", 1);
  };

  console.log("formikedit", formikedit.values);

  const handleEditFileChange = async (event) => {
    try {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile, selectedFile.name);
        let res = await fileUpload(formData, fileType);
        if (res?.data?.statusCode === 200) {
          formikedit.setFieldValue("brand_logo", res?.data?.data);
        }
      }
    } catch (error) {}
  };

  const editRemoveLogo = () => {
    formikedit.setFieldValue("brand_logo", "");
  };

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;

    formikedit.setFieldValue("country", selectedCountry);

    const country = countries.find((c) => c.name === selectedCountry);
    const selectedDialCode = country ? country.dialCode : "";

    setDialCode(selectedDialCode);
    formikedit.setFieldValue("dial_code", selectedDialCode);
  };

  // const countries = useSelector(selectcountry);
  // const dispatch = useDispatch();

  useEffect(() => {
    // getAlloffer()
    const getCountry = async () => {
      try {
        const res = await Counrtylist();
        if (res?.status === 200 && res?.data?.statusCode === 200) {
          const countries = res.data.data.map((country) => ({
            name: country.name,
            dialCode: country.dial,
          }));
          dispatch(setcountry(countries));
          // setBrand(res?.data?.getallbrand || []);
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          // alert(msg);
          console.error(msg);
        }
        console.log(error.message);
      }
    };

    const getLocationdetails = async (id) => {
      try {
        const requestBody = {
          offset: (currentPage - 1) * itemsPerPage,
          limit: itemsPerPage,
          search: searchKey,

          parent_merchant_id: id,
          startDate: range.startDate,
          endDate: range.endDate,

          // search:undefined
        };
        const resp = await getLocation(requestBody);
        console.log("resp::: ", resp);

        if (resp?.status === 200 && resp?.data?.statusCode === 200) {
          console.log("resp::: ", resp);

          SeteditData(resp?.data?.getallbrand);
          setTotalCount(resp?.data?.count);
        } else {
          toast.error(resp?.message || resp?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();

          console.error(msg);
        }
        console.log(error.message);
      }
    };
    getCountry();
    getLocationdetails(id);
  }, []);

  const handleTypeChange = (e) => {
    formikedit?.setFieldValue("category_type", e);
  };

  return (
    <div>
      <form onSubmit={formikedit.handleSubmit}>
        <div className="row">
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              {t("merchant.ContactName")}
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder={t("merchant.ContactNameEnter")}
              id="contact_name"
              name="contact_name"
              value={formikedit.values.contact_name}
              onChange={formikedit.handleChange}
              onBlur={formikedit.handleBlur}
            />
            {formikedit.errors.contact_name &&
            formikedit.touched.contact_name ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.contact_name}
              </p>
            ) : (
              <></>
            )}
          </div>

          {/* ========= Email ========= */}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              {t("merchant.EmailAddress")}
            </label>
            <input
              className="MainInput"
              type="email"
              placeholder={t("merchant.EnterEmail")}
              id="email"
              name="email"
              value={formikedit.values.email}
              onChange={formikedit.handleChange}
              onBlur={formikedit.handleBlur}
            />
            {formikedit.errors.email && formikedit.touched.email ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.email}
              </p>
            ) : (
              <></>
            )}
          </div>
          {/* ========= Country ========= */}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              {t("merchant.Country")}
            </label>
            <select
              className="selectMainInput"
              id="country"
              placeholder={t("merchant.Country")}
              name="country"
              value={formikedit.values.country}
              onChange={handleCountryChange}
              onBlur={formikedit.handleBlur}
            >
              <option>---Select Country---</option>
              {countries.map((city, index) => (
                <option key={index}>{city.name}</option>
              ))}
            </select>

            {formikedit.errors.country && formikedit.touched.country ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.country}
              </p>
            ) : (
              <></>
            )}
          </div>

          {/* ========= City ========= */}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
             {t("merchant.City")} 
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder={t("merchant.EnterCity")}
              id="city"
              name="city"
              value={formikedit.values.city}
              onChange={formikedit.handleChange}
              onBlur={formikedit.handleBlur}
            />
            {formikedit.errors.city && formikedit.touched.city ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.city}
              </p>
            ) : (
              <></>
            )}
          </div>

          {/*===========Location=========*/}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
             {t("merchant.Location")} 
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder={t("merchant.EnterLocation")}
              id="location"
              name="location"
              value={formikedit.values.location}
              onChange={formikedit.handleChange}
              onBlur={formikedit.handleBlur}
            />
            {formikedit.errors.location && formikedit.touched.location ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.location}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
             {t("merchant.Mobile")} 
            </label>
            <div className="row">
              <div className="col-md-2 pRzero">
                <div className="MainInputFrame">
                  {" "}
                  {formikedit.values.dial_code || "-"}
                </div>
              </div>
              <div className="col-md-10">
                <input
                  className="MainInput flex-grow-1"
                  type="text"
                  placeholder={t("merchant.EnterMobile")}
                  id="mobile"
                  name="mobile"
                  value={formikedit.values.mobile}
                  onChange={formikedit.handleChange}
                  onBlur={formikedit.handleBlur}
                />
              </div>
            </div>

            {formikedit.errors.mobile && formikedit.touched.mobile ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.mobile}
              </p>
            ) : (
              <></>
            )}
          </div>

          {/*===========Latitude=========*/}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              {t("merchant.Latitude")}
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder={t("merchant.EnterLatitude")}
              id="latitude"
              name="latitude"
              value={formikedit.values.latitude}
              onChange={formikedit.handleChange}
              onBlur={formikedit.handleBlur}
            />
            {formikedit.errors.latitude && formikedit.touched.latitude ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.latitude}
              </p>
            ) : (
              <></>
            )}
          </div>

          {/*===========Longitude=========*/}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              {t("merchant.Longitude")}
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder={t("merchant.EnterLongitude")}
              id="longitude"
              name="longitude"
              value={formikedit.values.longitude}
              onChange={formikedit.handleChange}
              onBlur={formikedit.handleBlur}
            />
            {formikedit.errors.longitude && formikedit.touched.longitude ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.longitude}
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>

        {/* ========= Submit Button ========= */}
        <button type="submit" className="MainButton cyanSky_clr gap8">
          <img src={SaveIcon} alt="SaveIcon" />
          <span> {t("common.SaveChanges")}</span>
        </button>
      </form>
    </div>
  );
};

export default EditLocationForm;
