import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getBrandList } from "../../services/super-admin.service";
import { useNavigate } from "react-router-dom";

import AddIcon from "../../assets/images/add_icon.svg";
import UpdateIcon from "../../assets/images/update_icon.svg";
import ViewIcon from "../../assets/images/view_icon.svg";
import DeleteIcon from "../../assets/images/delete_icon.svg";

import CashBackPriority from "./CashBackPriority";
import CreateCashBack from "./CreateCashBack";
import Editcashbackrule from "./Editcashbackrule";
import ViewCashback from "./ViewCashback";
import setting from "../../assets/images/setting_icon.svg";
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";
import PaginatedTable from "../common/PaginatedTable";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import CloseIcon from "../../assets/images/close_icon.svg";
import SelectdateIcon from "../../assets/images/selectdate_icon.svg";
import { formatCurrency } from "../../utils/helpers/helper";
import { getProductdroplist } from "../../services/product.services";
import { useTranslation } from 'react-i18next';  // Import useTranslation

import EditBrandForm from "./EditBrandForm";
// import ViewCashbank from "./ViewCashbank";
import {
  getCashbackRules,
  updateCashBackRules,
  removeCashBackRules,
} from "../../services/cashback.service";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectRole } from "../../store/role/role.selector";

const CashBackManagement = ({ backFunc }) => {
  const { id } = useParams();
  const [createForm, setCreateForm] = useState(false);
  const [brandTable, setBrandTable] = useState(true);
  const [createcashproform, setcashproform] = useState(false);
  const [status, setStatus] = useState("");
  const [cashbackview, setcashbackview] = useState(false);
  const [Ediform, setEdiform] = useState(false);
  const [brand, setbrand] = useState();
  const [product, setproduct] = useState();
  console.log("product::: ", product);
  const { t, i18n } = useTranslation(); // Translation hook

  const role = useSelector(selectRole);

  //pagination
  // const [Viewtransaction, setViewtransaction] = useState(false);
  const [TransactionTable, setTransactioTable] = useState(true);
  let [totalCount, setTotalCount] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  let [itemsPerPage, setItemsPerPage] = useState(10);
  let [searchKey, setSearchKey] = useState("");
  let [isLoading, setIsLoading] = useState(true);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [range, setRange] = useState({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(),
  });

  const [isOpen, setIsOpen] = useState(false);
  const handleTogglePicker = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (ranges) => {
    const temp = {
      startDate: ranges["range1"]["startDate"],
      endDate: ranges["range1"]["endDate"],
    };
    setRange(temp);
  };

  const [data, setData] = useState({});
  const navigate = useNavigate();
  const handleShow = () => {
    setCreateForm(true);
    setBrandTable(false);
    setcashproform(false);
  };

  const showPriority = () => {
    setCreateForm(false);
    setBrandTable(false);
    setcashproform(true);
  };

  const back = (list = true) => {
    list && getAllcashbackrules(id);
    setBrandTable(true);
    setEdiform(false);
    setCreateForm(false);
    setcashproform(false);
    setcashbackview(false);
    setData({});
  };

  const handleShowedit = (brand) => {
    setData(brand);
    // setcashproform(true);
    setEdiform(true);
    setBrandTable(false);
  };

  // const handleView = (brand) => {
  //   setData(brand);
  //   setEdiform(true);
  //   setBrandTable(false);
  // };

  const handleShowView = (brand) => {
    setData(brand);
    setcashbackview(true);
    setBrandTable(false);
    // setcashbackview(true);
  };

  const getAllproductlist = async (id) => {
    try {
      const requestBody = {
        brand_id: id,
      };
      const resp = await getProductdroplist(requestBody);

      if (resp?.status === 200 && resp?.data?.statusCode === 200) {
        setproduct(
          resp?.data?.data?.map((category) => ({
            label: category.product_name,
            value: category.product_id,
            logo: category.logo,
          }))
        );
      } else {
        // toast.error(resp?.message || resp?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();

        console.error(msg);
      }
      console.log(error.message);
    }
  };

  const getAllcashbackrules = async (id) => {
    try {
      const requestBody = {
        offset: (currentPage - 1) * itemsPerPage,
        limit: itemsPerPage,
        search: searchKey,

        brand_id: id,
        startDate: range.startDate,
        endDate: range.endDate,
        statusfilter: status || "all",
        // search:undefined
      };
      const resp = await getCashbackRules(requestBody);

      if (resp?.status === 200 && resp?.data?.statusCode === 200) {
        setbrand(resp?.data?.data);
        setTotalCount(resp?.data?.count);
      } else {
        toast.error(resp?.message || resp?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();

        console.error(msg);
      }
      console.log(error.message);
    }
  };

  const cashbackStatusUpdate = async (brand) => {
    try {
      const payload = {
        rules_id: brand?.id,
        status: !brand?.active_flag,
      };

      const res = await updateCashBackRules(payload);

      if (res?.status === 200 && res?.data?.statusCode === 200) {
        toast.success(res?.data?.message);
        back();
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {
      console.error("Error occurred while updating brand status:", error);
    }
  };

  const deleteVoucher = async (id) => {
    try {
      const payload = {
        id: id,
      };
      const res = await removeCashBackRules(payload);
      if (res?.status === 200 && res?.data?.statusCode === 200) {
        toast?.success(res?.data?.message);
        back();
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {}
  };

  const [column, setColumn] = useState([
    {
      heading: () => t("common.Action"),
      cell: (row) => (
        <img
          src={ViewIcon}
          alt="ViewIcon"
          onClick={() => handleShowView(row)}
        />
      ),
      hidden: false,
      width: "120",
      align: "left",
      id: 9,
    },
    {
      heading: () => t("merchant.CashbackRuleName"),
      cell: (row) => (row["rules_name"] ? row["rules_name"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 3,
    },
    {
      heading: () => t("merchant.MinSpend"),
      cell: (row) =>
        row["min_spend"] ? formatCurrency(row["min_spend"]) : "-",
      hidden: false,
      width: "120",
      align: "left",
      id: 5,
    },
    {
      heading: () => t("merchant.CashbackOn"),
      cell: (row) => (row["cash_back_on"] ? row["cash_back_on"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 4,
    },
    {
      heading: () => t("common.StartDate"),
      cell: (row) => (row["start_Date"] ? row["start_Date"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 7,
    },

    {
      heading: () => t("common.EndDate"),
      cell: (row) => (row["end_Date"] ? row["end_Date"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 13,
    },
    {
      heading: () => t("common.Status"),
      cell: (row) => {
        console.log("row::: ", row);
        const status = row["active_flag"];
        console.log("status::: ", status);
        let displayText = "";
        let style = {};
        let className = "";

        if (status === true) {
          displayText = "Active";
        } else if (status === false) {
          displayText = "InActive";
          className = "fontWeight600 errorClr_text"; // Use class names directly
        } else if (status === "all") {
          displayText = "ALL Status";
          style = { color: "#28a745", fontWeight: 600 };
        } else {
          displayText = "-";
          style = { fontWeight: 600 };
        }

        return (
          <span
            className={
              row?.active_flag
                ? "fontWeight600 UFOGreen_text"
                : "fontWeight600 errorClr_text"
            }
            style={style}
          >
            {displayText}
          </span>
        );
      },
      hidden: false,
      width: "120",
      align: "left",
      id: 8,
    },
  ]);

  useEffect(() => {
    getAllcashbackrules(id);
    getAllproductlist(id);
  }, [currentPage, itemsPerPage, searchKey, range, setSearchKey, status]);

  const handleBack = () => {
    if (createcashproform || cashbackview || createForm) {
      back();
    } else {
      backFunc();
    }
  };

  return (
    <div>
      <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween BorderBottom_LavenderSyrup pb_16 Mob_flexWrapGap">
        <div className="displayFlex alignItem_center gap12">
          <div>
            <button className="emptyBtn" onClick={handleBack}>
              <img src={ArrowleftIcon} alt="ArrowleftIcon" />
            </button>
          </div>
          <div>
            <h5 className="fontSize20 fontWeight600 blackClr_text">
              {"Cashback Management"}
            </h5>
          </div>
        </div>

        <div className="displayFlex alignItem_center gap12">
          <div>
            <button
              className="MainButton lemonPie_clr gap8"
              type="button"
              onClick={() => showPriority()}
            >
              <img className="width20px" src={setting} alt="setting" />
              <span>{t("merchant.Setting")}</span>
            </button>
          </div>
        </div>
      </div>
      {!cashbackview && !Ediform && !createForm && (
        <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween Mob_flexWrapGap">
          <div>
            <h5 className="fontSize16 fontWeight600 blackClr_text">
            {t("merchant.CashbackRuleList")}
            </h5>
          </div>
          <div className="displayFlex alignItem_center gap12">
            {role == "merchant" ? (
              ""
            ) : (
              <div>
                <button
                  className="MainButton cyanSky_clr gap8"
                  type="button"
                  onClick={handleShow}
                  // onClick={() => navigate("/add-coach")}
                >
                  <img className="width20px" src={AddIcon} alt="AddIcon" />
                  <span> {t("merchant.CreateCashbackRule")} </span>
                </button>
              </div>
            )}
            <div>
              <h5 className="fontSize20 fontWeight600 blackClr_text">
                {" "}
                {/* All Transactions List */}
              </h5>
            </div>
            <div className="position_relative">
              <button
                className="lemonPie_clr MainButton gap12"
                onClick={handleTogglePicker}
              >
                <img className="width20px" src={SelectdateIcon} />
                <span>{t("common.SelectDate")} </span>
              </button>
              {isOpen && (
                <div className="DateRangePickerPosition">
                  <DateRangePicker ranges={[range]} onChange={handleSelect} />
                  <button
                    className="closeDateRangePicker"
                    onClick={handleTogglePicker}
                  >
                    <img src={CloseIcon} alt="CloseIcon" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {brandTable && (
        <>
          {isLoading ? (
            <PaginatedTable
              row={brand}
              column={column}
              totalCount={totalCount}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isSearch={true}
              searchKey={searchKey}
              setSearchKey={setSearchKey}
              maxPageNumberLimit={maxPageNumberLimit}
              setMaxPageNumberLimit={setMaxPageNumberLimit}
              minPageNumberLimit={minPageNumberLimit}
              setMinPageNumberLimit={setMinPageNumberLimit}
              isExport={false}
              status={status}
              setStatus={setStatus}
              isstatus={true}
            />
          ) : (
            <div style={{ textAlign: "center" }}>Loading...</div>
          )}
        </>
      )}

      {createForm && (
        <CreateCashBack data={brand} back={back} product={product} />
      )}

      {createcashproform && <CashBackPriority editData={data} back={back} />}

      {cashbackview && (
        <ViewCashback
          brand={data}
          product={product}
          back={back}
          handleShowedit={handleShowedit}
          handleShowView={handleShowView}
        />
      )}
    </div>
  );
};

export default CashBackManagement;
