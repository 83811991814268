import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import { selectCurrentUser } from "../../store/user/user.selector";
import BackIcon from "../../assets/images/back_icon.svg";
import CreateIcon from "../../assets/images/create_icon.svg";
import { CreateNewVoucher } from "../../services/voucher.service";
import CloseIcon from "../../assets/images/close_icon.svg";
import UploadImg from "../../assets/images/upload_img.svg";
import { fileUpload } from "../../services/common.service";
import { GetMerchantCategory } from "../../services/Category.service";
import Select from "react-select";
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";
import { Parser } from "htmlparser2";
import { DomHandler } from "domhandler";
import TextEditor from '../TextEditor/TextEditor';
import { calculatePercentage } from "../../utils/helpers/helper";
import { useTranslation } from 'react-i18next';  // Import useTranslation

const fileType = "Voucher";
const CreateVoucher = ({ brand = [], back }) => {
  const handleClose = () => {
    resetForm();
    setErrors({});
    back(false);
  };
  const { t, i18n } = useTranslation(); // Translation hook

  const [selectedMerchant, setSelectedMerchant] = useState("");

  const [Category, setCategory] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);


  const user = useSelector(selectCurrentUser)?.userDetail;
  const id = user?.userType === "brand" ? user?.brand_id : "";
  const today = new Date().toISOString().split("T")[0];

  const deserialize = (html) => {
    const parseNode = (node) => {
      if (node.type === "text") {
        let attributes = {};
        if (node.parent && (node.parent.name === 'strong' || node.parent.name === 'em' || node.parent.name === 'u')) {
          if (node.parent.name === 'strong') attributes.bold = true;
          if (node.parent.name === 'em') attributes.italic = true;
          if (node.parent.name === 'u') attributes.underline = true;
        }
        return { ...attributes, text: node.data };
      }

      switch (node.name) {
        case 'p':
          return { type: 'paragraph', children: node.children ? node.children.map(parseNode) : [] };
        case 'a':
          return { type: 'link', url: node.attribs.href, children: node.children ? node.children.map(parseNode) : [] };
        case 'blockquote':
          // Assuming all block quotes are wrapped in a paragraph tag
          return { type: 'block-quote', children: [{ type: 'paragraph', children: node.children ? node.children.map(parseNode) : [] }] };
        case 'ul':
          return { type: 'bulleted-list', children: node.children ? node.children.map(parseNode) : [] };
        case 'ol':
          return { type: 'numbered-list', children: node.children ? node.children.map(parseNode) : [] };
        case 'li':
          return { type: 'list-item', children: node.children ? node.children.map(parseNode) : [] };
        case 'h1':
          return { type: 'heading-one', children: node.children ? node.children.map(parseNode) : [] };
        case 'h2':
          return { type: 'heading-two', children: node.children ? node.children.map(parseNode) : [] };
        case 'div':
          // Assuming divs are used for text alignment
          const align = node.attribs.style.split(":")[1].trim();
          return { align, children: node.children ? node.children.map(parseNode) : [] };
        default:
          // Check if the node has only one child
          if (node.children && node.children.length === 1) {
            return parseNode(node.children[0]);
          } else {
            return node.children ? node.children.map(parseNode) : [];
          }
      }
    };

    try {
      const handler = new DomHandler();
      const parser = new Parser(handler);

      parser.write(html || '');
      parser.end();

      const document = handler.dom.map(parseNode);
      return document.length > 0 ? document : [{ type: 'paragraph', children: [{ text: '' }] }];
    } catch (error) {
      console.error('Error occurred during deserialization:', error);
      return [{ type: 'paragraph', children: [{ text: 'Error occurred during deserialization' }] }];
    }
  };


  const [termsAndCondition, setTermsAndCondition] = useState('');
  const [privacyPolicy, setPrivacyPolicy] = useState('');
  const [stepsToRedeem, setStepsToRedeem] = useState('');


  const merchantlist = brand.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const Calegorylist = Category.map((e) => ({
    value: e.value,
    label: e.label,
  }));



  // form
  const {
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues: {
      VoucherName: "",
      VoucherCount: "",
      brand_id: id,
      startDate: "",
      endDate: "",
      offer_type: "Value",
      offer_value: "",
      voucher_value: "",
      min_spend: 0,
      upper_limit: 0,
      logo: null,
      banner: null,
      selectedLogo: null,
      privacyPolicy: "",
      termsAndCondition: "",
      stepsToRedeem: "",
      category_id: "",
      privacyPolicy_raw: privacyPolicy,
      termsAndCondition_raw: termsAndCondition,
      stepsToRedeem_raw: stepsToRedeem,
    },
    validationSchema: Yup.object({
      VoucherName: Yup.string()
      .matches(/^[a-zA-Z0-9\s]*$/, 'Only alphanumeric characters and spaces are allowed')
        .max(50, "Voucher Name must be at most 50 characters")
        .required("Voucher Name is required"),
      logo: Yup.string().required("Logo is required"),
      banner: Yup.string().required("Banner is required"),
      VoucherCount: Yup.string()
        .required("Voucher Count is required")
        .matches(/^[1-9][0-9]*$/, "Voucher Count must be a number"),
      brand_id: Yup.string().required("Merchant is required"),
      voucher_value: Yup.string()
        .matches(/^\d+(\.\d{1,3})?$/, "Voucher Purchase Amount must be a positive number with up to three decimal places")
        .required("Voucher Purchase Amount is required"),
      startDate: Yup.string().required("Start date is required"),
      endDate: Yup.string().required("End date is required"),
      offer_type: Yup.string().required("offer type is required"),
      offer_value: Yup.string()
        .test({
          name: "offer_value",
          exclusive: false,
          test: function (value, { createError }) {
            const isNumeric = /^[1-9][0-9]*([.][0-9]+)?$|^0[.][0-9]+$/.test(value);
            if (isNumeric) {
              return true
            } else {
              return true; // Reset error for non-numeric values
            }
          },
        })
        .matches(/^\d+(\.\d{1,3})?$/, "Voucher Offer must be a positive number with up to three decimal places")
        .required("Voucher Offer is required"),
      upper_limit: Yup.string().test({
        name: "upper_limit",
        exclusive: false,
        message: "Upper limit must be a number and less than min spend",
        test: function (value, { parent }) {
          const isNumeric = /^[1-9][0-9]*([.][0-9]+)?$|^0[.][0-9]+$/.test(value);
          if (parent.offer_type === "Value" || !values) {
            return true;
          }
          if (isNumeric) {
            if (parent.offer_type === "Percentage") {
              return (
                parseFloat(value) > 0 &&
                parseFloat(value) < parseFloat(parent.min_spend)
              );
            } else {
              return true; // Reset error for non-"Percentage" offer types
            }
          } else {
            return false; // Reset error for non-numeric values
          }
        },
      }),
      privacyPolicy: Yup.string()
        .max(800, "Privacy policy  must be at most 800 characters")
        .required("Privacy policy is required"),
      termsAndCondition: Yup.string()
        .max(800, "Terms and condition  must be at most 800 characters")
        .required("Terms and condition is required"),
      stepsToRedeem: Yup.string()
        .max(800, "Steps to redeem  must be at most 800 characters")
        .required("Steps to redeem required"),

        category_id: Yup.string()
        .required("Category is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const payload = {
          ...values,
          VoucherCount: +values.VoucherCount,
          min_spend: +values.min_spend,
          upper_limit: values?.offer_type === "Percentage"
            ? +values?.upper_limit
            : 0,
          voucher_value: +values?.voucher_value,
          discount_value:
            values?.offer_type === "Percentage"
              ? (+values?.upper_limit || calculatePercentage(+values?.offer_value, +values.min_spend))
              : +values?.offer_value,
          coupon_type: "VOUCHER",
          voucher_logo: values?.logo,
          banner_image: values?.banner,
          category_id: +selectedOptions,
          privacyPolicy_raw: deserialize(values?.privacyPolicy),
          termsAndCondition_raw: deserialize(values?.termsAndCondition),
          stepsToRedeem_raw: deserialize(values?.stepsToRedeem),
        };
        const res = await CreateNewVoucher(payload);

        if (res?.status === 200 && res?.data?.statusCode === 200) {
          toast.success(res.data.message);
          resetForm();
          setErrors({});
          back();
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          console.error(msg);
        }
        console.log(error.message);
      }
    },
  });

  const handleFileChange = async (event, type = "logo") => {
    try {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile, selectedFile.name);
        let res = await fileUpload(formData, fileType);
        if (res?.data?.statusCode === 200) {
          if (type === "banner") {
            setFieldValue("banner", res?.data?.data);
          } else {
            setFieldValue("logo", res?.data?.data);
            setFieldValue("selectedLogo", URL.createObjectURL(selectedFile));
          }
        }
      }
    } catch (error) { }
  };

  const handleTypeChange1 = (e) => {
    setSelectedOptions(e);
  };

  const removeLogo = (type = "logo") => {
    if (type === "banner") {
      setFieldValue("banner", "");
    } else {
      setFieldValue("logo", "");
      setFieldValue("selectedLogo", "");
    }
  };

  const handleTypeChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const handleMerchantChange = (event) => {
    setFieldValue("category_id","")
    const test = event.value;
    setFieldValue("brand_id", test);
    setSelectedMerchant(test);
    handleChange(test);
  };

  const handleTypeChangemerchantcategory = (e) => {
  
    setSelectedOptions(e.value);
    setFieldValue("category_id",e.value )
  };


  const getCategorylist = async (brand_id) => {
    try {
     
      const res = await GetMerchantCategory(brand_id);
      if (res?.status === 200 && res?.data?.statusCode === 200) {
        if (res?.data?.data) {
          setCategory(
            res?.data?.data?.map((category) => ({
              label: category.category_name,
              value: category.id,
            }))
          );
        }
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();
        // alert(msg);
        console.error(msg);
      }
      console.log(error.message);
    }
  };
  useEffect(() => {

    if (selectedMerchant || id) {
      
      const getCategorylist = async () => {
        try {
          const res = await GetMerchantCategory(selectedMerchant ? selectedMerchant : id);
          if (res?.status === 200 && res?.data?.statusCode === 200) {
            if (res?.data?.data) {
              setCategory(
                res?.data?.data?.map((category) => ({
                  label: category.category_name,
                  value: category.category_id,
                }))
              );
            }
          } else {
            toast.error(res?.message || res?.data?.message);
          }
        } catch (error) {
          if (error?.message) {
            const msg = error?.message?.split(":")?.[1]?.trim();
            // alert(msg);
            console.error(msg);
          }
          console.log(error.message);
        }
      };

      getCategorylist(selectedMerchant);
    }
  }, [selectedMerchant ,selectedOptions]);

  return (
    <div className="whiteFrame">
      <div className="mb_24 displayFlex alignItem_center gap12">
        <div>
          <button className="emptyBtn" type="button" onClick={handleClose}>
            <img src={ArrowleftIcon} alt="ArrowleftIcon" />
          </button>
        </div>
        <div>
          <h5 className="fontSize20 fontWeight600 blackClr_text">
          {t("voucher.CreateVoucher")}
          </h5>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="mb_24 col-md-6">
            <label className="fontSize14 fontWeight500 blackClr_text display_block mb_8">
              
          {t("voucher.VoucherLogo")}

            </label>
            {!values?.selectedLogo && (
              <label htmlFor="selectedLogo" className="uploadImg_btn gap12">
                <img src={UploadImg} alt="UploadImg" />
                <span>{t("voucher.VoucherLogoupload")}</span>
                <input
                  id="selectedLogo"
                  type="file"
                  name="logo"
                  value={values.logo}
                  accept="image/*" // Accept all image file types
                  onChange={handleFileChange}
                  hidden
                />
              </label>
            )}

            {values?.selectedLogo && (
              <div className="ImgFrame position_relative">
                <img
                  className="brandImgSelectlogo"
                  src={values?.selectedLogo}
                  width={100}
                  height={100}
                  alt="selected logo"
                />
                <div className="imgcloseBtn ">
                  <img src={CloseIcon} alt="CloseIcon" onClick={removeLogo} />
                </div>
              </div>
            )}

            {!values.logo && touched.logo ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.logo}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label className="fontSize14 fontWeight500 blackClr_text display_block mb_8">
              
              {t("voucher.VoucherBanner")}

            </label>
            {!values?.banner && (
              <label htmlFor="banner" className="uploadImg_btn gap12">
                <img src={UploadImg} alt="UploadImg" />
                <span>{t("voucher.VoucherBannerupload")}</span>
                <input
                  id="banner"
                  type="file"
                  name="banner"
                  value={values.banner}
                  accept="image/*" // Accept all image file types
                  onChange={(e) => handleFileChange(e, "banner")}
                  hidden
                />
              </label>
            )}

            {values?.banner && (
              <div className="ImgFrame position_relative">
                <img
                  className="brandImgSelectlogo"
                  src={values?.banner}
                  width={100}
                  height={100}
                  alt="selected Banner"
                />
                <div className="imgcloseBtn ">
                  <img
                    src={CloseIcon}
                    alt="CloseIcon"
                    onClick={() => removeLogo("banner")}
                  />
                </div>
              </div>
            )}

            {!values.banner && touched.banner ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.banner}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              
              {t("voucher.VoucherName")}
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder={t("voucher.EnterVoucherName")}
              id="VoucherName"
              name="VoucherName"
              value={values.VoucherName}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.VoucherName && touched.VoucherName ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.VoucherName}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              
              {t("voucher.VoucherCount")}
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder={t("voucher.EnterVoucherCount")}
              id="VoucherCount"
              name="VoucherCount"
              value={values.VoucherCount}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.VoucherCount && touched.VoucherCount ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.VoucherCount}
              </p>
            ) : (
              <></>
            )}
          </div>

          {!id && (
              <div className="mb_24 col-md-6">
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                htmlFor="brand_id"
              >
                
                {t("merchant.MerchantName")}
              </label>
              <Select
                className="customSelect selectCategory"
                value={merchantlist.find(
                  (option) => option.value === selectedMerchant
                )} 
                onBlur={handleBlur}
                onChange={handleMerchantChange}
                options={merchantlist} 
              >
              </Select>
              {errors.brand_id && touched.brand_id ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {errors.brand_id}
                </p>
              ) : (
                <></>
              )}
            </div>
          )}

          {/* {!id && ( */}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="category_id "
            >
              
              {t("merchant.CategorySelect")}
            </label>
            <Select
               className="customSelect selectCategory"
              id="category_id "
              name="category_id "
              value={Calegorylist.find(
                (option) => option.value === selectedOptions
              )}
              onBlur={handleBlur}
              onChange={handleTypeChangemerchantcategory}
              options={Calegorylist}
            />

            {errors.category_id && touched.category_id ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.category_id}
              </p>
            ) : (
              <></>
            )}
          </div>
          {/* )} */}

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              
              {t("common.StartDate")}
            </label>
            <input
              className="MainInput"
              type="date"
              id="startDate"
              name="startDate"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.startDate}
              min={today}
              max="9999-12-31" // Maximum allowed date to restrict to 4-digit years
            />
            {errors.startDate && touched.startDate ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.startDate}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              
              {t("common.EndDate")}
            </label>
            <input
              className="MainInput"
              type="date"
              id="endDate"
              name="endDate"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.endDate}
              min={values.startDate} // Set minimum date to the selected start date
              max="9999-12-31" // Maximum allowed date to restrict to 4-digit years
            // max={values.endDate} // Set maximum date to the end of the current month
            />
            {errors.endDate && touched.endDate ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.endDate}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="toggleValueInput"
            >
              {t("voucher.OfferType")}
            </label>
            <div className="displayFlex alignItem_center gap12">
              <div className="prefer_method">
                <input
                  type="radio"
                  id="offer_value"
                  name="offer_type"
                  value="Value"
                  checked={values.offer_type === "Value"}
                  onChange={(e) => handleTypeChange(e)}
                  // readOnly={true}
                  disabled={true}
                />
                <label htmlFor="offer_value">Value</label>
              </div>
              {/* <div className='prefer_method'>
                            <input
                                type="radio"
                                id="offer_percentage"
                                name="offer_type"
                                value='Percentage'
                                checked={values.offer_type === "Percentage"}
                                onChange={(e) => handleTypeChange(e)}
                            />
                            <label htmlFor="offer_percentage">Percentage</label>
                        </div> */}
            </div>
            {errors.offer_type && touched.offer_type ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.offer_type}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              
              {t("voucher.VoucherOffer")}

            </label>
            <input
              className="MainInput"
              type="text"
              placeholder= {t("voucher.EnterVoucherOffer")}
              id="offer_value"
              name="offer_value"
              value={values.offer_value}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.offer_value && touched.offer_value ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.offer_value}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
             {t("voucher.VoucherPurchaseAmount")}
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder={t("voucher.EnterVoucherPurchaseAmount")}
              id="voucher_value"
              name="voucher_value"
              value={values.voucher_value}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.voucher_value && touched.voucher_value ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.voucher_value}
              </p>
            ) : (
              <></>
            )}
          </div>

          {values.offer_type === "Percentage" && (
            <div className="mb_24 col-md-6">
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                htmlFor="email"
              >
                
                {t("voucher.Upperlimit")}
              </label>
              <input
                className="MainInput"
                type="text"
                placeholder={t("voucher.EnterValue")}
                id="upper_limit"
                name="upper_limit"
                value={values.upper_limit}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors.upper_limit && touched.upper_limit ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {errors.upper_limit}
                </p>
              ) : (
                <></>
              )}
            </div>
          )}


          <div className="mb_24 col-md-12">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
                {t("voucher.PrivacyPolicy")}
                
            </label>
            {/* <textarea
              className="MainTextarea"
              placeholder="Privacy Policy"
              id="privacyPolicy"
              name="privacyPolicy"
              value={values.privacyPolicy}
              onBlur={handleBlur}
              onChange={handleChange}
              rows={3}
            ></textarea> */}
            <TextEditor id="privacyPolicy" name="privacyPolicy" fieldName="privacyPolicy" value={privacyPolicy} onChange={setPrivacyPolicy} setFieldValue={setFieldValue} onBlur={handleBlur} />
            {errors.privacyPolicy && touched.privacyPolicy ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.privacyPolicy}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className="mb_24 col-md-12">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
            {t("voucher.TermsAndCondition")}

              
            </label>
            {/* <textarea
              className="MainTextarea"
              placeholder="Terms And Condition"
              id="termsAndCondition"
              name="termsAndCondition"
              value={values.termsAndCondition}
              onBlur={handleBlur}
              onChange={handleChange}
              rows={3}
            ></textarea> */}
            <TextEditor id="termsAndCondition" name="termsAndCondition" fieldName="termsAndCondition" value={termsAndCondition} onChange={setTermsAndCondition} setFieldValue={setFieldValue} onBlur={handleBlur} />
            {errors.termsAndCondition && touched.termsAndCondition ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.termsAndCondition}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className="mb_24 col-md-12">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
            {t("voucher.StepsToRedeem")}

              
            </label>
            {/* <textarea
              className="MainTextarea"
              placeholder="Steps To Redeem"
              id="stepsToRedeem"
              name="stepsToRedeem"
              value={values.stepsToRedeem}
              onBlur={handleBlur}
              onChange={handleChange}
              rows={3}
            ></textarea> */}
            <TextEditor id="stepsToRedeem" name="stepsToRedeem" fieldName="stepsToRedeem" value={stepsToRedeem} onChange={setStepsToRedeem} setFieldValue={setFieldValue} onBlur={handleBlur} />
            {errors.stepsToRedeem && touched.stepsToRedeem ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.stepsToRedeem}
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>

        {/* ========= Submit Button ========= */}
        <button type="submit" className="MainButton cyanSky_clr gap12">
          <img src={CreateIcon} alt="CreateIcon" className="width_20px" />
          <span> {t("common.saveDraft")}
          </span>
        </button>
      </form>
    </div>
  );
};

export default CreateVoucher;
