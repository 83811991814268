import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from 'react-router-dom';
import { Customerlist } from "../../services/customer.service";
import { updateBrandStatus,Merchantlist } from "../../services/merchant.service";
import { useTranslation } from 'react-i18next';  // Import useTranslation


const ViewCustomerDetail = ({ brand = {}, back } ) => {
  const { t, i18n } = useTranslation(); // Translation hook

    const { id } = useParams(); 

    const [customer, setCustomer] = useState();
    console.log('customer::: ', customer);

    const mobileNumber = customer?.[0].mobile||'-';
    const cleanedNumber = mobileNumber.replace(/\D/g, '');

// Format the number as per your requirement
const formattedNumber = `+${cleanedNumber.substring(0, 2)}-${cleanedNumber.substring(2)}`;
   

    const getAllCustomerById = async (id) => {
      
        try {
          const resp = await Customerlist(id);
          
    
          if (resp?.status === 200 && resp?.data?.statusCode === 200) {
    
            setCustomer(resp?.data?.data?.getAllCustomerById);
    
          } else {
            toast.error(resp?.message || resp?.data?.message);
          }
    
        } catch (error) {
          if (error?.message) {
            const msg = error?.message?.split(":")?.[1]?.trim();
    
            console.error(msg)
    
          }
          console.log(error.message);
    
        }
      };
    
    
      useEffect(() => {
        getAllCustomerById(id);
      }, []);


    return (
      <div className="tableResponsive">
            <table className="table_detail">
                <tbody>                  
                    <tr>
                        <th>{t("common.FirstName")}
                        </th>
                        <td>{customer?.[0].first_name||'-'}</td>
                    </tr>
                    <tr>
                        <th>{t("common.LastName")}
                        </th>
                        <td>{customer?.[0].last_name||'-'}</td>
                    </tr>
                    <tr>
                        <th>{t("customer.Mobile")}
                        </th>
                        <td>{formattedNumber ||'-'}</td>
                    </tr>
                    <tr>
                        <th>{t("common.EmailAddress")}
                        </th>
                        <td>{customer?.[0].email ||'-'}</td>
                    </tr>
                    <tr>
                        <th>{t("common.Country")}
                        </th>
                        <td>{(customer?.[0]?.country && customer[0].country !== 'null') ? customer[0].country : '-'}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default ViewCustomerDetail;
