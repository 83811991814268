import Dropdown from "react-bootstrap/Dropdown";
import Adminlogo from "../../assets/images/admin_logo.png";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from "../../store/user/user.selector";
import { setCurrentUser,updateUserLanguageCode} from "../../store/user/user.reducer";
import { useTranslation } from 'react-i18next';
import Modal from "react-bootstrap/Modal";
import Multilanguage from "../MultilanguageSetting/multi-language";
import CloseIcon from "../../assets/images/close_icon.svg";
import { getAllLanguages,updateLanguage } from "../../services/language.service";
import { id } from "date-fns/locale";


const Header = ({ handleToggleClick }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser)?.userDetail;
  // const user = useSelector((state) => state.user.userDetail);

  const { t, i18n } = useTranslation(); // Use translation hook
  const [languages, setLanguage] = useState();
  console.log('languages::: ', languages);
  // const [handleLanguageConfirm, handleLanguageConfirm] = useState(user);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState({});
  console.log('selectCurrentUser::: ', useSelector(selectCurrentUser));

  const [defaultLanguage, setdefaultLanguage] = useState(); // Default language name






  const checkLanguage = () => {
   
  if(user?.lang_code===''||undefined){
      i18n.changeLanguage('en'); 
    }else{
      i18n.changeLanguage(user.lang_code); 
    }
  };





  useEffect(() => {
    checkLanguage(); 
  }, [languages]);

  const handleLogout = () => {
    Cookies.remove('token');
    dispatch(setCurrentUser(''));
    navigate(`/${user?.userType}`);
  };
  const handleLanguageChange = (lang_code, lang_id) => {
    handleEdit({ lang_id, userId: user.user_id });

  };


  const getLanguagelist = async () => {

    try {
     const requestBody={};
      const resp = await getAllLanguages();
      if (resp?.status === 200 && resp?.data?.statusCode === 200) {
        console.log('resp::: ', resp);
        
        setLanguage(resp?.data?.getalllanguages);
        console.log('setLanguage::: ', resp);
          const lang_data=resp?.data?.getalllanguages?.filter((ele)=>ele.lang_code === user?.lang_code? ele.language_name:'');
          console.log('lang_data::: ', lang_data);

        setdefaultLanguage(lang_data.length?lang_data[0]?.language_name:'ENGLISH'
          );
          
      
     
      } else {
        toast.error(resp?.message || resp?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();
        console.error(msg);
      }
      console.log(error.message);
    }
  };



  useEffect(() => {
    getLanguagelist();
  },[]);

  const handleEdit = (data) => {
    setEditData(data);
    setIsEdit(true);
  };
  const closeModal = () => {
    setIsEdit(false);
  };
  const updateLanguageCode = (data) => {
    dispatch(updateUserLanguageCode(data.lang_code));
    setdefaultLanguage(data.language_name);
      // Only update lang_code
  };

  const handleLanguageConfirm = async  (lang_id, userId)  => {
    
    try {
      const requestBody={
        id:lang_id,
        user_id:userId
      };
       const resp = await updateLanguage(requestBody);
       if (resp?.status === 200 && resp?.data?.statusCode === 200) {
        toast.success(resp.data.message);
        console.log('resp::: ', resp);
        i18n.changeLanguage(resp.data?.data[0].lang_code); 
        closeModal();
        updateLanguageCode(resp.data?.data[0]);

       } else {
         toast.error(resp?.message || resp?.data?.message);
       }
     } catch (error) {
       if (error?.message) {
         const msg = error?.message?.split(":")?.[1]?.trim();
         console.error(msg);
       }
       console.log(error.message);
     }









  };
  return (
    <div className="navbarHeader">
      <div>        <button onClick={handleToggleClick} className="mobileToggle" ><span></span><span></span><span></span></button>
</div>
        <Modal
          centered
          show={isEdit}
          onHide={() => setIsEdit(false)}
          backdrop="static"
          className="setLanguage_popup"
        >
          <Modal.Body>
            <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween">
              <div>
                <h2 className="fontSize18 fontWeight600 blackClr_text  ">
                   Are you sure to change the language?
                </h2>
              </div>
              <div>
                <button className="emptyBtn" onClick={() => setIsEdit(false)}>
                  <img src={CloseIcon} alt="CloseIcon" />
                </button>
              </div>
            </div>
              <div className="displayFlex alignItem_center gap12 justifyContent_center">
                <div>             <button className="MainButton cyanSky_clr" onClick={() => handleLanguageConfirm(editData.lang_id, editData.userId)}>
                Yes
              </button>
              </div>
              <div>
 
              <button className="MainButton cyanSky_clr" onClick={() => setIsEdit(false)}>
                No
              </button>
              </div>
            </div>
            {/* <Multilanguage data={editdata} back={closeModal} /> */}
          </Modal.Body>
        </Modal>
      <div className="displayFlex alignItem_center gap20">
          {/* Language Dropdown */}
          <div>
          <Dropdown>
            {/* <Dropdown.Toggle id="language-dropdown" className="languageDropdown">
             
            </Dropdown.Toggle> */}
            {/* <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleLanguageChange('en')}>
                English
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleLanguageChange('ar')}>
                العربية
              </Dropdown.Item>
            </Dropdown.Menu> */}

              <Dropdown.Toggle id="language-dropdown" className="languageDropdown">
         Language: {defaultLanguage}

              </Dropdown.Toggle>

              <Dropdown.Menu>
                {languages?.map((lang) => (
                  <Dropdown.Item key={lang.lang_code} onClick={() => handleLanguageChange(lang.lang_code,lang.id)}>
                    {lang.language_name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
          </Dropdown>
        </div>
        <div>
          <Dropdown className="userProfile">
            <Dropdown.Toggle
              id="dropdown-basic"
              className="displayFlex alignItem_center gap12"
            >
              <div>
                <img src={Adminlogo} alt="Adminlogo" />
              </div>
              <div className="userprofileText">{user?.name}</div>
            </Dropdown.Toggle>
            <Dropdown.Menu>

              <Dropdown.Item onClick={() => navigate(`/${user?.userType}/reset-password`)}>Change Password</Dropdown.Item>
              <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>

              {/* <Dropdown.Item href='#'>Another action</Dropdown.Item>
            <Dropdown.Item href='#'>Something else</Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
       
      </div>
    </div>

    
  );
};

export default Header;
