import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import BackIcon from "../../assets/images/back_icon.svg";
import CreateIcon from "../../assets/images/create_icon.svg";
import { CreateCategory } from "../../services/Category.service";
import CloseIcon from "../../assets/images/close_icon.svg";
import UploadImg from "../../assets/images/upload_img.svg";
import { selectcountry } from "../../store/countrylist/country.selector";
import { useSelector } from "react-redux";
import { PostCustomerCreditLimit } from "../../services/Global.service";
import { selectCurrentUser } from "../../store/user/user.selector";
import { useTranslation } from 'react-i18next';  // Import useTranslation


const EditConfigSetting = ({ data = {}, back }) => {

  const { t, i18n } = useTranslation(); // Translation hook

  const countries = useSelector(selectcountry);
  const user = useSelector(selectCurrentUser)?.userDetail;

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues: {
      minWithdraw: data?.min_withdraw || "",
      maxWithdraw: data?.max_withdraw || "",
      country: data?.country_id || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      country: Yup.string().required("Country is required"),
      minWithdraw: Yup.number()
        .typeError("Minimum  Withdrawal must be a number")
        .required("Minimum  Withdrawal is required")
        .test('is-less-than-max', 'Minimum Withdraw must be less than Maximum Withdrawal', function (value) {
          return value < this.parent.maxWithdraw;
        }),
      maxWithdraw: Yup.number()
        .typeError("Maximum  Withdrawal must be a number")
        .required("Maximum  Withdrawal is required")
        .test('is-greater-than-min', 'Maximum Withdrawal must be greater than Minimum Withdrawal', function (value) {
          return value > this.parent.minWithdraw;
        }),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {

        const payload = {
          cashLimit: +values?.maxWithdraw,
          minLimit: +values?.minWithdraw,
          country: values?.country,
          updated_by: user?.user_id,
          id: data?.id || null
        };

        const res = await PostCustomerCreditLimit(payload);

        if (res?.status === 200 && res?.data?.statusCode === 200) {
          toast.success(res.data.message);
          resetForm();
          setErrors({});
          back();
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          console.error(msg);
        }
        console.log(error.message);
      }
    },
  });

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setFieldValue("country", selectedCountry);
  };





  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          {/* <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Country
            </label>
            <select
              className="selectMainInput"
              id="country"
              placeholder="country"
              name="country"
              value={values.country}
              onBlur={handleBlur}
              onChange={handleCountryChange}
            >
              <option> Select Country </option>
              {countries.map((city, index) => (
                <option key={index}>{city.name}</option>
              ))}
            </select>

            {errors.country && touched.country ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.country}
              </p>
            ) : (
              <></>
            )}
          </div> */}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              {t("globalsetting.MinimumCashWithdrawal")}
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter  "
              id="minWithdraw"
              name="minWithdraw"
              value={values.minWithdraw}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.minWithdraw && touched.minWithdraw ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.minWithdraw}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
             {t("globalsetting.MaximumCashWithdrawal")}
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter  "
              id="maxWithdraw"
              name="maxWithdraw"
              value={values.maxWithdraw}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.maxWithdraw && touched.maxWithdraw ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.maxWithdraw}
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>

        {/* ========= Submit Button ========= */}
        <button type="submit" className="MainButton cyanSky_clr gap12">
          <img src={CreateIcon} alt="CreateIcon" className="width_20px" />
          <span>{t("globalsetting.UpdateConfig")}</span>
        </button>
      </form>
    </>
  );
};

export default EditConfigSetting;
