import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  transaction,
  transactionbyid,
  viewtransactionbyid,
} from "../../services/Bank.service";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import Accordion from "react-bootstrap/Accordion";
// import ViewTransaction from "./ViewTransaction";
import Editproduct from "./EditProduct";
import ViewIcon from "../../assets/images/view_icon.svg";
import {
  getCashbackRules,
  updateCashBackRules,
  updateProductStatus,
  deleteProductById,
} from "../../services/product.services";
import { useTranslation } from 'react-i18next';  

import DeactivateIcon from "../../assets/images/deactivate_icon.svg";
import ActiveIcon from "../../assets/images/active_icon.svg";
import DeleteIcon from "../../assets/images/delete_icon.svg";

import EditIcon from "../../assets/images/edit_icon.svg";
import { formatCurrency } from "../../utils/helpers/helper";
import { selectRole } from "../../store/role/role.selector";

function ViewProduct({ brand, back }) {
  console.log("brand::: ", brand?.[0]?.id);
  const { t, i18n } = useTranslation(); // Translation hook

  const { id } = useParams();
  const [Ediform, setEdiform] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [CategoryTable, setCategoryTable] = useState(true);
  const role = useSelector(selectRole);

  const [Viewsachback, Setviewcashback] = useState();

  const handleShowedit = (data) => {
    // setdata(data);
    setShowEdit(true);
    setCategoryTable(false);
  };

  // const getAllcashbackrules = async (id) => {
  //   try {
  //     const resp = await getCashbackRules(id);

  //     if (resp?.status === 200 && resp?.data?.statusCode === 200) {
  //       Setviewcashback(resp?.data?.data);
  //     } else {
  //       toast.error(resp?.message || resp?.data?.message);
  //     }
  //   } catch (error) {
  //     if (error?.message) {
  //       const msg = error?.message?.split(":")?.[1]?.trim();

  //       console.error(msg);
  //     }
  //     console.log(error.message);
  //   }
  // };

  const ProductStatusUpdate = async () => {
    try {
      const payload = {
        product_id: brand?.[0]?.id,
        status: !brand?.[0]?.active_flag,
      };

      const res = await updateProductStatus(payload);

      if (res?.status === 200 && res?.data?.statusCode === 200) {
        toast.success(res?.data?.message);
        back();
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {
      console.error("Error occurred while updating brand status:", error);
    }
  };

  const DeleteProduct = async (id) => {
    try {
      const payload = {
        id: brand?.[0]?.id,
      };

      const res = await deleteProductById(payload);
      if (res?.status === 200 && res?.data?.statusCode === 200) {
        toast?.success(res?.data?.message);
        back();
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {}
  };

  useEffect(() => {
    // getAllcashbackrules(id);
  }, []);
  return (
    <>
      <div>
        <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween Mob_flexWrapGap">
          <div>
            <h5 className="fontSize20 fontWeight600 blackClr_text">
              {showEdit ? "Edit Product" : "View Product"}
            </h5>
          </div>
          {role == "merchant"
            ? ""
            : !showEdit && (
                <div className="displayFlex justifyContent_end gap12">
                  <div>
                    <button
                      className="MainButton lemonPie_clr gap8"
                      type="button"
                      onClick={() => handleShowedit(brand)}
                    >
                      <img
                        className="width20px"
                        src={EditIcon}
                        alt="EditIcon"
                      />
                      {/* <span>Edit</span> */}
                    </button>
                  </div>
                  <div>
                    <button
                      className={
                        brand?.active_flag
                          ? "actionIcon_btn customTooltip  errorText_clr"
                          : "actionIcon_btn customTooltip  UFOGreen_clr"
                      }
                      onClick={() =>
                        ProductStatusUpdate(brand?.[0]?.product_id)
                      }
                    >
                      {brand?.active_flag ? (
                        <img
                          src={DeactivateIcon}
                          alt="DeactivateIcon"
                          className="width20px"
                        />
                      ) : (
                        <img
                          src={ActiveIcon}
                          alt="ActiveIcon"
                          className="width20px"
                        />
                      )}
                      <span className="custom_tooltiptext">
                        {" "}
                        {brand?.activeFlag === "active" ? "Active" : "Inactive"}
                      </span>
                    </button>
                  </div>
                  <div>
                    <button
                      className="actionIcon_btn customTooltip errorText_clr gap8"
                      onClick={() => DeleteProduct(brand?.[0].id)}
                    >
                      <img
                        src={DeleteIcon}
                        alt="DeleteIcon"
                        className="width20px"
                      />
                      <span className="custom_tooltiptext">{t("common.Delete")}</span>
                    </button>
                  </div>
                </div>
              )}
        </div>

        {CategoryTable && (
          <>
            <div className="row mb_24">
              <div className="col-md-2 mob_mb24">
                <label
                  className="fontSize14 fontWeight500 blackClr_text display_block mb_16"
                  htmlFor="fn"
                >
                 {t("merchant.ProductLogo")} 
                </label>
                <div className="viewBrand_logo">
                  {/* PlaceholderImg */}
                  <img
                    src={brand?.[0]?.logo || "-"}
                    alt="brand_logo"
                    width={100}
                    height={100}
                  />
                </div>
              </div>

              {/* <div className="col-md-2">
                <label
                  className="fontSize14 fontWeight500 blackClr_text display_block mb_16"
                  htmlFor="fn"
                >
                 Cashback Banner
                </label>

                <div className="viewBrand_logo">
                  PlaceholderImg
                  <img
                    src={brand?.banner_image || "-"}
                    alt="brand_logo"
                    width={100}
                    height={100}
                  />
                </div>

              </div> */}
            </div>
            <div className="tableResponsive">
              <table className="table_detail">
                <tbody>
                  <tr>
                    <th>{t("merchant.ProductName")}</th>
                    <td>{brand?.[0]?.product_name || "-"}</td>
                  </tr>
                  <tr>
                    <th>{t("merchant.UPCNumber")} </th>
                    <td>{brand?.[0]?.upc || "-"}</td>
                  </tr>
                  <tr>
                    <th>{t("merchant.SKUNumber")}</th>
                    <td>{brand?.[0]?.sku || "-"}</td>
                  </tr>
                  {/* <tr>
                    <th>Product Desc</th>
                    <td>
                      {(brand?.offer_type === "Percentage"
                        ? `${brand?.offer_value}%`
                        : formatCurrency(brand?.offer_value)) || "-"}
                    </td>
                  </tr> */}
                  {/* <tr>
                    <th>Min Spend </th>
                    <td>
                      {formatCurrency(brand?.min_spend) || "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Upper Limit</th>
                    <td>
                      {formatCurrency(brand?.upper_limit) || "-"}
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
      {showEdit && <Editproduct brand={brand} back={back} />}
    </>
  );
}

export default ViewProduct;
