import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  transaction,
  transactionbyid,
  viewtransactionbyid,
} from "../../services/Bank.service";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import Accordion from "react-bootstrap/Accordion";
// import ViewTransaction from "./ViewTransaction";
import Editcashbackrule from "../branding/Editcashbackrule";
import ViewIcon from "../../assets/images/view_icon.svg";
import {
  getCashbackRules,
  updateCashBackRules,
  removeCashBackRules,
  ActiveCashBackRules,
} from "../../services/cashback.service";
import {
  updateCampaignStatus,
  deleteCampaign,
  getcashbacklist,
} from "../../services/campaign.service";

import DeactivateIcon from "../../assets/images/deactivate_icon.svg";
import ActiveIcon from "../../assets/images/active_icon.svg";
import DeleteIcon from "../../assets/images/delete_icon.svg";

import EditIcon from "../../assets/images/edit_icon.svg";
import { formatCurrency } from "../../utils/helpers/helper";
import { selectRole } from "../../store/role/role.selector";
import PaginatedTable from "../common/PaginatedTable";
import { convertToISOString } from "../../services/common.service";
import { useTranslation } from 'react-i18next';  

function ViewCashback({ brand, back, product }) {
  console.log('brand::: ', brand);
  console.log("product::: ", product);
  const { t, i18n } = useTranslation(); // Translation hook

  const { id } = useParams();
  const [Ediform, setEdiform] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [CategoryTable, setCategoryTable] = useState(true);
  const role = useSelector(selectRole);
  const [campaignlist, setcampianlist] = useState(null);
  console.log("campaignlist::: ", campaignlist);
  //Paginated Table Variables
  let [totalCount, setTotalCount] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  let [itemsPerPage, setItemsPerPage] = useState(10);
  let [searchKey, setSearchKey] = useState("");
  let [isLoading, setIsLoading] = useState(true);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [range, setRange] = useState({
    startDate: undefined,
    endDate: new Date(),
  });

  const [Viewsachback, Setviewcashback] = useState();

  const handleShowedit = (data) => {
    // setdata(data);
    setShowEdit(true);
    setCategoryTable(false);
  };

  // const getAllcashbackrules = async (id) => {
  //   try {
  //     const resp = await getCashbackRules(id);

  //     if (resp?.status === 200 && resp?.data?.statusCode === 200) {
  //       Setviewcashback(resp?.data?.data);
  //     } else {
  //       toast.error(resp?.message || resp?.data?.message);
  //     }
  //   } catch (error) {
  //     if (error?.message) {
  //       const msg = error?.message?.split(":")?.[1]?.trim();

  //       console.error(msg);
  //     }
  //     console.log(error.message);
  //   }
  // };

  const cashbackStatusUpdate = async (brand) => {
    try {
      const payload = {
        rules_id: brand?.id,
        status: !brand?.active_flag,
      };

      const res = await ActiveCashBackRules(payload);

      if (res?.status === 200 && res?.data?.statusCode === 200) {
        toast.success(res?.data?.message);
        back();
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {
      console.error("Error occurred while updating brand status:", error);
    }
  };

  const [column, setColumn] = useState([
    // {
    //   heading: () => "Code",
    //   cell: (row) => (row["code"] ? row["code"] : "-"),
    //   hidden: false,
    //   width: "120",
    //   align: "left",
    //   id: 2,
    // },

    {
      heading: () => t("customer.CustomerName")+"/"+t("customer.CustomerMobile"),
      cell: (row) => (row["user_name"] ? row["user_name"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 3,
    },
    {
      heading: () => t("customer.Email"),
      cell: (row) => (row["email"] ? row["email"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 3,
    },

    {
      heading: () => t("common.UsedBy"),
      cell: (row) => {
        if (row["updated_date"]) {
          // Parse the ISO 8601 date string
          const dateObj = new Date(row["updated_date"]);
          
          // Format the date as DD-MM-YYYY
          const date = dateObj.toLocaleDateString('en-GB').split('/').reverse().join('-');
          
          // Format the time as HH:MI:SS
          const time = dateObj.toTimeString().split(' ')[0];
          
          return `${date} ${time}`;
        } else {
          return "-";
        }
      },

      hidden: false,
      width: "120",
      align: "left",
      id: 6,
    },

    // {
    //   heading: () => "Create Date",
    //   cell: (row) => (row["start_Date"] ? row["start_Date"] : "-"),
    //   hidden: false,
    //   width: "120",
    //   align: "left",
    //   id: 6,
    // },

    // {
    //   heading: () => "Redeemed",
    //   cell: (row) => (
    //     <span style={{ color: row["redeemed"] ? "green" : "red" }}>
    //       {row["redeemed"] ? "Redeemed" : "Not-Redeemed"}
    //     </span>
    //   ),
    //   hidden: false,
    //   width: "120",
    //   align: "left",
    //   id: 8,
    // },
  ]);

  const deleteVoucher = async (id) => {
    try {
      const payload = {
        id: id,
      };
      const res = await removeCashBackRules(payload);
      if (res?.status === 200 && res?.data?.statusCode === 200) {
        toast?.success(res?.data?.message);
        back();
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const getAllcampilist = async () => {
      const brandid = brand?.id;
      const offset = (currentPage - 1) * itemsPerPage;
      const offsetToUse = offset >= 0 ? offset : 0;
      try {
        const requestBody = {
          offset: offsetToUse,
          limit: itemsPerPage,
          search: searchKey,
          startDate: convertToISOString(range.startDate),
          endDate: convertToISOString(range.endDate),
        };
        const resp = await getcashbacklist(brandid, requestBody);

        if (resp?.status === 200 && resp?.data?.statusCode === 200) {
          setcampianlist(resp?.data?.data);
          setTotalCount(resp?.data?.count);
        } else {
          toast.error(resp?.message || resp?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          console.error(msg);
        }
        console.log(error.message);
      }
    };
    getAllcampilist();
  }, [currentPage, itemsPerPage, searchKey, setSearchKey, range]);
  return (
    <>
      <div>
        <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween Mob_flexWrapGap">
          <div>
            <h5 className="fontSize20 fontWeight600 blackClr_text">
              {showEdit ? "Edit Cashback Rule" : "View Cashback Rule"}
            </h5>
          </div>
          {role == "merchant"
            ? ""
            : !showEdit && (
                <div className="displayFlex justifyContent_end gap12">
                  <div>
                    <button
                      className="MainButton lemonPie_clr gap8"
                      type="button"
                      onClick={() => handleShowedit(brand)}
                    >
                      <img
                        className="width20px"
                        src={EditIcon}
                        alt="EditIcon"
                      />
                      {/* <span>Edit</span> */}
                    </button>
                  </div>
                  <div>
                    <button
                      className={
                        brand?.active_flag
                          ? "actionIcon_btn customTooltip  errorText_clr"
                          : "actionIcon_btn customTooltip  UFOGreen_clr"
                      }
                      onClick={() => cashbackStatusUpdate(brand)}
                    >
                      {brand?.active_flag ? (
                        <img
                          src={DeactivateIcon}
                          alt="DeactivateIcon"
                          className="width20px"
                        />
                      ) : (
                        <img
                          src={ActiveIcon}
                          alt="ActiveIcon"
                          className="width20px"
                        />
                      )}
                      <span className="custom_tooltiptext">
                        {" "}
                        {brand?.activeFlag === "active" ? "Active" : "Inactive"}
                      </span>
                    </button>
                  </div>
                  <div>
                    <button
                      className="actionIcon_btn customTooltip errorText_clr gap8"
                      onClick={() => deleteVoucher(brand?.id)}
                    >
                      <img
                        src={DeleteIcon}
                        alt="DeleteIcon"
                        className="width20px"
                      />
                      <span className="custom_tooltiptext">{t("common.Delete")}</span>
                    </button>
                  </div>
                </div>
              )}
        </div>

        {CategoryTable && (
          <>
            <div className="row mb_24">
              <div className="col-md-2 mob_mb24">
                <label
                  className="fontSize14 fontWeight500 blackClr_text display_block mb_16"
                  htmlFor="fn"
                >
                  {t("merchant.CashbackLogo")}
                </label>
                <div className="viewBrand_logo">
                  {/* PlaceholderImg */}
                  <img
                    src={brand?.campaign_logo || "-"}
                    alt="brand_logo"
                    width={100}
                    height={100}
                  />
                </div>
              </div>

              <div className="col-md-2">
                <label
                  className="fontSize14 fontWeight500 blackClr_text display_block mb_16"
                  htmlFor="fn"
                >
                  {t("merchant.CashbackBanner")}
                  
                </label>

                <div className="viewBrand_logo">
                  {/* PlaceholderImg */}
                  <img
                    src={brand?.banner_image || "-"}
                    alt="brand_logo"
                    width={100}
                    height={100}
                  />
                </div>
              </div>
            </div>
            <div className="tableResponsive">
              <table className="table_detail">
                <tbody>
                  <tr>
                    <th>{t("merchant.CashbackRuleName")}</th>
                    <td>{brand?.rules_name || "-"}</td>
                  </tr>
                  <tr>
                    <th>{t("merchant.CashbackOn")}</th>
                    <td>{brand?.cash_back_on || "-"}</td>
                  </tr>
                  <tr>
                    <th>{t("campaign.Offertype")}</th>
                    <td>
                      {brand?.offer_type === "Value"
                        ? "Amount"
                        : brand?.offer_type || "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>{t("merchant.OfferValue")}</th>
                    <td>
                      {(brand?.offer_type === "Percentage"
                        ? `${brand?.offer_value}%`
                        : formatCurrency(brand?.offer_value)) || "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>{t("merchant.MinSpend")} </th>
                    <td>{formatCurrency(brand?.min_spend) || "-"}</td>
                  </tr>
                  <tr>
                    <th>{t("voucher.Upperlimit")}</th>
                    <td>{formatCurrency(brand?.upper_limit) || "-"}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween">
              <div>
                <h5 className="fontSize20 fontWeight600 blackClr_text">
                {t("merchant.CashBackList")}
                </h5>
              </div>
            </div>

            {isLoading ? (
              <PaginatedTable
                row={campaignlist}
                column={column}
                totalCount={totalCount}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                isSearch={true}
                searchKey={searchKey}
                setSearchKey={setSearchKey}
                maxPageNumberLimit={maxPageNumberLimit}
                setMaxPageNumberLimit={setMaxPageNumberLimit}
                minPageNumberLimit={minPageNumberLimit}
                setMinPageNumberLimit={setMinPageNumberLimit}
                setRange={setRange}
                range={range}
                isExport={false}
                isDateRange={true}
              />
            ) : (
              <div style={{ textAlign: "center" }}>Loading...</div>
            )}
          </>
        )}
      </div>
      {showEdit && (
        <Editcashbackrule brand={brand} back={back} product={product} />
      )}
    </>
  );
}

export default ViewCashback;
