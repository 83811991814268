import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getmerchantvariable } from "../../services/merchant.service";
import { useNavigate } from "react-router-dom";
import Createmersetting from "./CreatemerSetting";
import ViewIcon from "../../assets/images/view_icon.svg";

import { formatCurrency } from "../../utils/helpers/helper";

import { useParams } from "react-router-dom";
import EditSetting from "./EditSetting";
import AddIcon from "../../assets/images/add_icon.svg";
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";
import { useTranslation } from 'react-i18next';  

function Settingmanagement({ backFunc }) {
  const { id } = useParams();
  const { t, i18n } = useTranslation(); // Translation hook

  const [createForm, setCreateForm] = useState(false);
  const [brandTable, setBrandTable] = useState(true);
  const [createcashproform, setcashproform] = useState(false);
  const [cashbackview, setcashbackview] = useState(false);

  const [Ediform, setEdiform] = useState(false);
  const [brand, setbrand] = useState();


  const [data, setData] = useState({});

  const navigate = useNavigate();
  const handleShow = () => {
    setCreateForm(true);
    setBrandTable(false);
  };

  const back = (list = true) => {
    list && getMerchantVariable(id);
    setBrandTable(true);
    setEdiform(false);
    setCreateForm(false);
    setcashproform(false);
    setData({});
  };

  const handleShowedit = (brand) => {
    setData(brand);
    // setcashproform(true);
    setEdiform(true);
    setBrandTable(false);
  };

  // const handleView = (brand) => {
  //   setData(brand);
  //   setEdiform(true);
  //   setBrandTable(false);
  // };

  const handleShowView = (brand) => {
    setData(brand);
    setcashbackview(true);
    setBrandTable(false);
  };

  const getMerchantVariable = async (id) => {
    try {
      const resp = await getmerchantvariable(id);

      if (resp?.status === 200 && resp?.data?.statusCode === 200) {
        setbrand(resp?.data?.data);
      } else {
        toast.error(resp?.message || resp?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();

        console.error(msg);
      }
      console.log(error.message);
    }
  };

  useEffect(() => {
    getMerchantVariable(id);
  }, []);

  const handleBack = () => {
    if (!brandTable) {
      setCreateForm(false);
      setEdiform(false);
      setBrandTable(true);
    } else {
      backFunc();
    }
  }

  return (
    <div>
      <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween BorderBottom_LavenderSyrup pb_16 Mob_flexWrapGap">
        <div className="displayFlex alignItem_center gap12">
          <div>
            <button className="emptyBtn" onClick={handleBack}>
              <img src={ArrowleftIcon} alt="ArrowleftIcon" />
            </button>
          </div>
          <div>
            <h5 className="fontSize20 fontWeight600 blackClr_text">
              {"Setting"}
            </h5>
          </div>
        </div>


      </div>
      <div>

        {brandTable && (
          <>
            <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween">
              <div>
                <h5 className="fontSize16 fontWeight600 blackClr_text">
                {t("merchant.MerchantVariableList")}
                </h5>
              </div>
              <div>
                <button
                  className="MainButton cyanSky_clr gap8"
                  type="button"
                  onClick={handleShow}
                // onClick={() => navigate("/add-coach")}
                >
                  <img className="width20px" src={AddIcon} alt="AddIcon" />
                  <span> {t("merchant.CreateSetting")}Create Setting </span>
                </button>
              </div>
            </div>
            <div className="Table_Wrapper tableResponsive">
              <table className="mb_24">
                <thead>
                  <tr>
                    {/* <th>S.No</th> */}
                    <th>{t("common.Action")}</th>
                    <th>{t("merchant.VariableFeesType")}</th>
                    <th>{t("merchant.VariableFees")}</th>
                    <th>{t("merchant.FixedFee")}</th>
                    <th>{t("merchant.PayIn")}</th>

                    {/* <th>Status</th> */}
                  </tr>
                </thead>
                <tbody>
                  {brand?.map((brand, i) => {
                   
                    return (
                      <tr key={brand?.id}>
                        {/* <td> {i + 1}</td> */}
                        <td>
                          <div className="displayFlex alignItem_center gap12">
                            <div>
                              <button
                                className="emptyBtn"
                                onClick={() => handleShowedit(brand)}
                              >
                                <img src={ViewIcon} alt="ViewIcon" />
                              </button>
                            </div>
                          </div>
                        </td>
                        <td> {brand?.fee_type ?? "-"}</td>
                        <td>
                          {" "}
                          {(brand?.fee && formatCurrency(brand?.fee)) || "-"}
                        </td>
                        <td>
                          {" "}
                          {(brand?.fixed_fee &&
                            formatCurrency(brand?.fixed_fee)) ||
                            "-"}
                        </td>
                        <td>
                          {" "}
                          {(brand?.pay_in && formatCurrency(brand?.pay_in)) ||
                            "-"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}

        {createForm && <Createmersetting back={back} />}
        {Ediform && <EditSetting brand={data} back={back} />}
      </div>
    </div>
  );
}

export default Settingmanagement;
