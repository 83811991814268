import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import { CreateNewCampaign } from "../../services/campaign.service";
import { selectCurrentUser } from "../../store/user/user.selector";
import BackIcon from "../../assets/images/back_icon.svg";
import CreateIcon from "../../assets/images/create_icon.svg";
import {
  Postmerchantvariable,
  getmerchantvariable,
} from "../../services/merchant.service";
import {
  addCashbackRules,
  getCashbackRules,
} from "../../services/cashback.service";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';  

const Createmersetting = ({ back }) => {
  const { id } = useParams();
  const user = useSelector(selectCurrentUser)?.userDetail;
  const { t, i18n } = useTranslation(); // Translation hook
 

  const [brand, setbrand] = useState();
  const handleClose = () => {
    resetForm();
    setErrors({});
    // back(false);
  };

  const today = new Date().toISOString().split("T")[0];

  const Cashbackoption = [
    { key: "Product", value: "Product" },
    { key: "Cart", value: "Cart" },
    { key: "Promotions", value: "Promotions" },
  ];

  // form

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues: {
      brand_id: id,
      fee_type: "",
      fixed_fee: "",
      pay_in: "",
      fee: "",
      created_by: user.user_id,
    },

    validationSchema: Yup.object({
      fee_type: Yup.string().required(" Fee Type is required"),

      fee: Yup.string()
        .required("Variable Fees  is required")
        .matches(/^[1-9][0-9]*$/, "Min spend must above 0"),
      fixed_fee: Yup.string()
        .required("Fixed Fee   is required")
        .matches(/^[1-9][0-9]*$/, "Fixed Fee must above 0"),
      pay_in: Yup.string()
        .required("Pay In  is required")
        .matches(/^[1-9][0-9]*$/, "pay in must above 0"),
    }),

    onSubmit: async (values, { resetForm }) => {
      try {
   

        const payload = {
          // ...values,
          // // CouponCount: +values.CouponCount,
          // min_spend: +values.min_spend,
          // upper_limit: +values?.upper_limit,
          // discount_value:
          //   values?.offer_type === "Percentage"
          //     ? +values?.upper_limit
          //     : +values?.offer_value,
          fee_type: values.fee_type,
          fixed_fee: values.fixed_fee,
          brand_id: id,
          pay_in: values.pay_in,
          fee: values?.fee,
          created_by: user.user_id,
        };

        const res = await Postmerchantvariable(payload);

        if (res?.status === 200 && res?.data?.statusCode === 200) {
          toast.success(res.data.message);
          resetForm();
          setErrors({});

          back();
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          console.error(msg);
        }
        console.log(error.message);
      }
    },
  });

  const getMerchantVariable = async (id) => {
    try {
      const resp = await getmerchantvariable(id);

      if (resp?.status === 200 && resp?.data?.statusCode === 200) {
        setbrand(resp?.data?.data);
      } else {
        toast.error(resp?.message || resp?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();

        console.error(msg);
      }
      console.log(error.message);
    }
  };

  useEffect(() => {
    getMerchantVariable(id);
  }, [id]);

  

  const handleTypeChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  return (
    <>
      <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween">
        <div>
          <h5 className="fontSize16 fontWeight600 blackClr_text">
          {t("merchant.CreateMerchantVariable")} {" "}
          </h5>
        </div>
        {/* <div>
          <button
            className="MainButton neverything_clr gap8"
            type="button"
            onClick={handleClose}
          >
            <img src={BackIcon} alt="BackIcon" />
            <span> Back </span>
          </button>
        </div> */}
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="min_spend"
            >
               {t("merchant.VariableFeesType")}
            </label>
            <select
              className="MainInput"
              id="fee_type"
              name="fee_type"
              value={values.fee_type}
              onBlur={handleBlur}
              onChange={handleChange}
            >
              <option value="Value"> {t("common.Amount")}</option>
              <option value="Percentage"> {t("common.Percentage")}</option>
            </select>

            {errors.fee_type && touched.fee_type ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.fee_type}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
               {t("merchant.VariableFees")}
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder={t("merchant.EnterVariableFees")}
              id="fee"
              name="fee"
              value={values.fee}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.fee && touched.fee ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.fee}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className="mb_24  col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              {t("merchant.PayIn")}
            </label>
            <input
              className="MainInput"
              type="pay_in"
              placeholder= {t("merchant.EnterPayIn")}
              id="pay_in"
              name="pay_in"
              value={values.pay_in}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.pay_in && touched.pay_in ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.pay_in}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              {t("merchant.FixedFee")} 
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder=  {t("merchant.EnterFixedFee")} 
              id="fixed_fee"
              name="fixed_fee"
              value={values.fixed_fee}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.fixed_fee && touched.fixed_fee ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.fixed_fee}
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>

        {/* ========= Submit Button ========= */}
        <button type="submit" className="MainButton cyanSky_clr gap12">
          <img src={CreateIcon} alt="CreateIcon" className="width_20px" />
          <span> {t("merchant.CreateSetting")}</span>
        </button>
      </form>
    </>
  );
};

export default Createmersetting;
