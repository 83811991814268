import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { getCustomerTransactionDetails } from "../../services/customer.service";
import { formatCurrency } from "../../utils/helpers/helper";
import PaginatedTable from "../common/PaginatedTable";
import ViewIcon from "../../assets/images/view_icon.svg";
import  Viewalltransaction from "../transaction/Viewalltransaction";
import { useNavigate } from "react-router-dom";
import ViewCustomertransaction from "./ViewCustomertransaction";
import { useTranslation } from 'react-i18next';  // Import useTranslation

const CustomerTransactions = () => {
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  console.log('transactions::: ', transactions);
  const { t, i18n } = useTranslation(); // Translation hook

  const [transactionview, setTransactionView] = useState(false);
  const [transactionstable, setTransactionstable] = useState(true);
  const { id } = useParams();
  //Paginated Table Variables
  let [totalCount, setTotalCount] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  let [itemsPerPage, setItemsPerPage] = useState(10);
  let [searchKey, setSearchKey] = useState("");
  let [isLoading, setIsLoading] = useState(true);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [range, setRange] = useState({
    startDate: undefined,
    endDate: undefined,
  });

  useEffect(() => {
    fetchTransactions(id);
  }, [id, currentPage, itemsPerPage, searchKey,]);

  const fetchTransactions = async () => {
    try {
      const offset = (currentPage - 1) * itemsPerPage;
      const offsetToUse = offset >= 0 ? offset : 0;
      const requestBody = {
        master_id: id,
        offset: offsetToUse,
        limit: itemsPerPage,
        search: searchKey,
      };
      const res = await getCustomerTransactionDetails(requestBody);
      if (res?.status === 200 && res?.data?.statusCode === 200) {
        const formattedTransactions = res?.data?.data.map((transaction) => ({
          ...transaction,
          // paymentDate: formatDate(transaction.paymentDate),
        }));

        console.log("test--------------",formattedTransactions)
        setTransactions(formattedTransactions);
        setTotalCount(res?.data?.count);
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {
      console.error("Error fetching transactions:", error.message);
      toast.error("Failed to fetch transaction data");
    }
  };

  const handleShowView = (tdetails) => {
    fetchTransactions(tdetails.master_transaction_id);
    sessionStorage.setItem(
      "master_transaction_id",
      tdetails.master_transaction_id
    );
    setTransactionstable(false);

    setTransactionView(true);
  };

  // Function to format the date string "DD-MM-YYYY" to a JavaScript Date object
  const formatDate = (dateString) => {
    const parts = dateString.split("-");
    const day = parseInt(parts[0]);
    const month = parseInt(parts[1]) - 1; // Month is zero-based in Date object
    const year = parseInt(parts[2]);
    return new Date(year, month, day).toLocaleDateString("en-GB");
  };

  const [column, setColumn] = useState([
    {
      heading: () => t("common.Action"),
      cell: (row) => (
        <button className="emptyBtn"  onClick={() => handleShowView(row.master_transaction_id)} >
          <img
            src={ViewIcon}
            alt="ViewIcon"
            // onClick={() => handleShowView(row.master_transaction_id,row.customer_name)}
           
          />
        </button>
      ),
      hidden: false,
      width: "120",
      align: "left",
      id: 1,
    },

    {
      heading: () => t("customer.CustomerName"),
      cell: (row) =>
        row["customer_name"] === " " ? "-" : row["customer_name"],
      hidden: false,
      width: "120",
      align: "left",
      id: 2,
    },
    {
      heading: () => t("merchant.MerchantName"),
      cell: (row) =>
        row["name"] === " " ? "-" : row["name"],
      hidden: false,
      width: "120",
      align: "left",
      id: 2,
    },
    {
      heading: () => t("transaction.PurchaseType"),
      cell: (row) =>
        row["purchase_type"] ? row["purchase_type"] : "-",
      hidden: false,
      width: "120",
      align: "left",
      id: 3,
    },
    {
      heading: () => t("transaction.TotalAmount"),
      cell: (row) => {
        const amount = row["total_amount"];
        return amount ? formatCurrency(amount) : "-";
        // return row['total_amount']
      },
      hidden: false,
      width: "100",
      id: 4,
    },
    {
      heading: () => t("transaction.PaymentDateTime"),
      // cell: (row) => (row["paymentDate"] ? row["paymentDate"] : "-"),
      cell: (row) => {
        console.log('row::: ', row);
        if (row["paymentDate"]) {
          // Assuming createdDate is in the format "DD-MM-YYYY HH:MI:SS"
          const [date, time] = row["paymentDate"].split(' ');
          return `${date} ${time}`;
        } else {
          return "-";
        }
      },
      hidden: false,
      width: "120",
      align: "left",
      id: 5,
    },
  ]);

  return (
    <div className="Table_Wrapper tableResponsive">
      <div>
        {transactionstable && (
          <>
            <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween">
              <div>
                <h5 className="fontSize20 fontWeight600 blackClr_text">
                  {" "}
                  {t("transaction.TransactionsList")}
                </h5>
              </div>
            </div>
            {isLoading ? (
              <PaginatedTable
                row={transactions}
                column={column}
                totalCount={totalCount}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                isSearch={false}
                searchKey={searchKey}
                setSearchKey={setSearchKey}
                maxPageNumberLimit={maxPageNumberLimit}
                setMaxPageNumberLimit={setMaxPageNumberLimit}
                minPageNumberLimit={minPageNumberLimit}
                setMinPageNumberLimit={setMinPageNumberLimit}
                setRange={setRange}
                range={range}
                isExport={false}
                isDateRange={false}
              //   handleExport={() => handleExportTransactions()}
              />
            ) : (
              <div style={{ textAlign: "center" }}>Loading...</div>
            )}
          </>

        )}

        {/* )} */}

        {transactionview && <ViewCustomertransaction data={transactions}/>}
      </div>
    </div>
  );
};

export default CustomerTransactions;
