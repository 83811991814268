import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import CreateIcon from "../../assets/images/create_icon.svg";
import {
  updateCashBackRules,
  getCashbackRules,
} from "../../services/cashback.service";
import { useParams } from "react-router-dom";
import CloseIcon from "../../assets/images/close_icon.svg";
import UploadImg from "../../assets/images/upload_img.svg";
import { fileUpload } from "../../services/common.service";
import { GetMerchantCategory } from "../../services/Category.service";
import TextEditor from "../TextEditor/TextEditor";
import { calculatePercentage } from "../../utils/helpers/helper";
import { Parser } from "htmlparser2";
import { DomHandler } from "domhandler";
import {
  latitudeRegex,
  longitudeRegex,
  phoneRegex,
  VatIdRegex,
  Swiftcode,
  IBANcode,
} from "../../utils/Regex";
import { selectcountry } from "../../store/countrylist/country.selector";
import { useDispatch, useSelector } from "react-redux";
import { setcountry } from "../../store/countrylist/country.reducer";
import { Counrtylist } from "../../services/merchant.service";

import {
  addBrandBank,
  getBrandBankList,
  bankMasterList,
  setPrimaryAccount,
  removeBank,
  updatebankaccount,
} from "../../services/Bank.service";
import { useTranslation } from 'react-i18next';  

const fileType = "Cashback";
function Editbank({ brand, editdata = {}, back }) {
  console.log('editdata::: ', editdata);
  console.log('brand::: ', brand);
 
  const { t, i18n } = useTranslation(); // Translation hook

  

  // const user = useSelector(selectCurrentUser)?.userDetail;

  const { id } = useParams();
  const [categoryList, setCategoryList] = useState([]);
  const today = new Date().toISOString().split("T")[0];
  const formattedToday = new Date().toISOString().split("T")[0];
  // const [isMultipleRedemption, setIsMultipleRedemption] = useState(false);

  const initialRedemptionType = brand?.redemption_type === "multirredemption";

  const [isMultipleRedemption, setIsMultipleRedemption] = useState(
    initialRedemptionType
  );

 
  const countries = useSelector(selectcountry);
  const dispatch = useDispatch();
  // const { id } = useParams();
  const [bankMaster, setBankMaster] = useState([]);
  const formikedit = useFormik({
    initialValues: {
      id: editdata?.id,
      name: editdata?.name,
      street_address: editdata?.street_address,
      city: editdata?.city,
      state: editdata?.state,
      account_number: editdata?.account_number,
      iban_number: editdata?.iban_number,
      swift_code: editdata?.swift_code,
      bank_name: editdata?.bank_name,
      branch_name: editdata?.branch_name,
      country: editdata?.country,
      account_id: editdata?.id,
      user_id: editdata?.cus_id_mer_id,
      // Create_type: editdata?.name,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Contact name is required")
        .matches(
          /^[a-zA-Z0-9\s]*$/,
          "Only alphanumeric characters and spaces are allowed"
        ),
      street_address: Yup.string().required(" Street address is required"),
      // .matches(
      //   /^[a-zA-Z0-9\s,/-]+$/,
      //   "Only alphanumeric characters, spaces, commas, hyphens, and slashes are allowed"
      // ),
      city: Yup.string().required(" City is required"),
      // .matches(
      //   /^[a-zA-Z0-9\/,\s-]+$/,
      //   "City Name must contain only letters, numbers, slashes, hyphens, and commas"
      // ),
      state: Yup.string().required(" State is required"),
      // .matches(
      //   /^[a-zA-Z0-9\s]*$/,
      //   "Only alphanumeric characters and spaces are allowed"
      // ),
      account_number: Yup.string()
        .required(" Account number is required")
        .matches(phoneRegex, "Account  number is not valid"),
      iban_number: Yup.string()
        .required(" Iban is required")
        .matches(IBANcode, "Iban number is not valid"),
        swift_code: Yup.string()
        .required(" Swift code is required")
        .matches(Swiftcode, "Swiftcode number is not valid"),
      bank_name: Yup.string().required(" Bank name is required"),
      branch_name: Yup.string().required(" Branch  name is required"),
      // .matches(
      //   /^[a-zA-Z0-9\s]*$/,
      //   "Only alphanumeric characters and spaces are allowed"
      // ),
      country: Yup.string().required(" Country is required"),
    }),
    onSubmit: async (values) => {
      console.log("values::: ", values);
      debugger

      try {
        const payload = {
          ...values,
        };

        const res = await updatebankaccount(payload);
        console.log("res::: ", res);
        if (res?.status === 200 && res?.data?.statusCode === 200) {
          toast.success(res.data.message);
          // resetForm();
          back();
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          console.error(msg);
        }
        console.log(error.message);
      }
    },
  });

  console.log("test", formikedit.errors);

  useEffect(() => {
    const getCountry = async () => {
      try {
        const res = await Counrtylist();
        if (res?.status === 200 && res?.data?.statusCode === 200) {
          dispatch(setcountry(res?.data?.data));
          // setBrand(res?.data?.getallbrand || []);
        } else {
          // toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          // alert(msg);
          console.error(msg);
        }
        console.log(error.message);
      }
    };
    const getbankMaster = async () => {
      try {
        const res = await bankMasterList();
        if (res?.status === 200 && res?.data?.statusCode === 200) {
          setBankMaster(res?.data?.data);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          // alert(msg);
          console.error(msg);
        }
        console.log(error.message);
      }
    };

    getCountry();
    getbankMaster();
  }, []);

  return (
    <>
      <form onSubmit={formikedit.handleSubmit}>
        <div className="mb_24">
          <label
            className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
            htmlFor="email"
          >
           {t("merchant.ContactName")} 
          </label>
          <input
            className="MainInput"
            type="text"
            placeholder={t("merchant.ContactNameEnter")}
            id="name"
            name="name"
            value={formikedit.values.name}
            onChange={formikedit.handleChange}
            onBlur={formikedit.handleBlur}
          />
          {formikedit.errors.name && formikedit.touched.name ? (
            <p className="fontSize12 fontWeight500 errorClr_text mt_8">
              {formikedit.errors.name}
            </p>
          ) : (
            <></>
          )}
        </div>
        <div className="mb_24">
          <label
            className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
            htmlFor="email"
          >
            {t("merchant.StreetAddress")}
          </label>
          <input
            className="MainInput"
            type="text"
            placeholder={t("merchant.EnterStreetAddress")}
            id="street_address"
            name="street_address"
            value={formikedit.values.street_address}
            onChange={formikedit.handleChange}
            onBlur={formikedit.handleBlur}
          />
          {formikedit.errors.street_address &&
          formikedit.touched.street_address ? (
            <p className="fontSize12 fontWeight500 errorClr_text mt_8">
              {formikedit.errors.street_address}
            </p>
          ) : (
            <></>
          )}
        </div>

        <div className="row">
          <div className="col-md-6 mb_24">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
             {t("merchant.Country")} 
            </label>
            <select
              className="selectMainInput"
              id="country"
              placeholder={t("merchant.Country")} 
              // id="city"
              name="country"
              value={formikedit.values.country}
              onChange={formikedit.handleChange}
              onBlur={formikedit.handleBlur}
            >
              <option>---Select Country---</option>
              {countries.map((city, index) => (
                <option key={index}>{city.name}</option>
              ))}
            </select>

            {formikedit.errors.country && formikedit.touched.country ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.country}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="col-md-6 mb_24">
            <div>
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_12"
                htmlFor="fn"
              >
               {t("merchant.StateName")} 
              </label>
              <input
                className="MainInput"
                type="text"
                placeholder={t("merchant.EnterStateName")}
                id="state"
                name="state"
                value={formikedit.values.state}
                onChange={formikedit.handleChange}
                onBlur={formikedit.handleBlur}
              />
              {formikedit.errors.state && formikedit.touched.state ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {formikedit.errors.state}
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb_24">
            <div>
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_12"
                htmlFor="fn"
              >
               {t("merchant.CityName")} 
              </label>
              <input
                className="MainInput"
                type="text"
                placeholder={t("merchant.EnterCityName")}
                id="city"
                name="city"
                value={formikedit.values.city}
                onChange={formikedit.handleChange}
                onBlur={formikedit.handleBlur}
              />
              {formikedit.errors.city && formikedit.touched.city ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {formikedit.errors.city}
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-6 mb_24">
            <div>
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_12"
                htmlFor="fn"
              >
               {t("merchant.AccountNumber")} 
              </label>
              <input
                className="MainInput"
                type="text"
                placeholder={t("merchant.EnterAccountNumber")}
                id="account_number"
                name="account_number"
                value={formikedit.values.account_number}
                onChange={formikedit.handleChange}
                onBlur={formikedit.handleBlur}
              />
              {formikedit.errors.account_number &&
              formikedit.touched.account_number ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {formikedit.errors.account_number}
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb_24">
            <div>
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_12"
                htmlFor="fn"
              >
               {t("merchant.IBAN")} 
              </label>
              <input
                className="MainInput"
                type="text"
                placeholder={t("merchant.EnterIBAN")}
                id="iban_number"
                name="iban_number"
                value={formikedit.values.iban_number}
                onChange={formikedit.handleChange}
                onBlur={formikedit.handleBlur}
              />
              {formikedit.errors.iban_number &&
              formikedit.touched.iban_number ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {formikedit.errors.iban_number}
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-6 mb_24">
            <div>
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_12"
                htmlFor="fn"
              >
               {t("merchant.SwiftCode")} 
              </label>
              <input
                className="MainInput"
                type="text"
                placeholder={t("merchant.EnterSwiftCode")}
                id="swift_code"
                name="swift_code"
                value={formikedit.values.swift_code}
                onChange={formikedit.handleChange}
                onBlur={formikedit.handleBlur}
              />
              {formikedit.errors.swift_code && formikedit.touched.swift_code ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {formikedit.errors.swift_code}
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb_24">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              {t("merchant.BankName")}
            </label>
            <select
              className="selectMainInput"
              id="bank_name"
              placeholder={t("merchant.BankName")}
              name="bank_name"
              value={formikedit.values.bank_name}
              onChange={formikedit.handleChange}
              onBlur={formikedit.handleBlur}
            >
              <option>---Select Bank Name---</option>
              {bankMaster.map((bank, index) => (
                <option key={index}>{bank.bankname}</option>
              ))}
            </select>

            {formikedit.errors.bank_name && formikedit.touched.bank_name ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.bank_name}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className="col-md-6 mb_24">
            <div>
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_12"
                htmlFor="fn"
              >
               {t("merchant.BranchName")} 
              </label>
              <input
                className="MainInput"
                type="text"
                placeholder={t("merchant.EnterBranchName")}
                id="branch_name"
                name="branch_name"
                value={formikedit.values.branch_name}
                onChange={formikedit.handleChange}
                onBlur={formikedit.handleBlur}
              />
              {formikedit.errors.branch_name && formikedit.touched.branch_name ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {formikedit.errors.branch_name}
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
          {/* <div className="col-md-6 mb_24">
                        <label
                            className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                            htmlFor="email"
                        >
                            Country
                        </label>
                        <select
                            className="MainInput"
                            id="country"
                            placeholder="country"
                            // id="city"
                            name="country"
                            value={values.country}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        >
                            <option>---Select City---</option>
                            {countries.map((city, index) => (
                                <option key={index}>{city.name}</option>
                            ))}
                        </select>

                        {errors.country && touched.country ? (
                            <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                                {errors.country}
                            </p>
                        ) : (
                            <></>
                        )}
                    </div> */}
        </div>

        {/* ========= Submit Button ========= */}
        <button type="submit" className="MainButton cyanSky_clr gap12">
          <img src={CreateIcon} alt="CreateIcon" className="width_20px" />
          <span>{t("merchant.SaveBank")}</span>
        </button>
      </form>
    </>
  );
}

export default Editbank;
