import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  updateBrandStatus,
  Merchantlist,
  updatepopularStatus,
} from "../../services/merchant.service";
import BackIcon from "../../assets/images/back_icon.svg";
import ActiveIcon from "../../assets/images/active_icon.svg";
import PlaceholderImg from "../../assets/images/placeholder_img.png";
import { useParams } from "react-router-dom";
import DeactivateIcon from "../../assets/images/deactivate_icon.svg";
import { useTranslation } from 'react-i18next';  

import UnsetPromotedIcon from "../../assets/images/unsetPromoted_icon.svg";
import SetpromotedIcon from "../../assets/images/setpromoted_icon.svg";
import ConfirmPopup from "../common/confirmation component/ConfirmPopup";
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";
import EditIcon from "../../assets/images/edit_icon.svg";
import EditBrandForm from "./EditBrandForm";
import { useSelector } from "react-redux";
import { selectRole } from "../../store/role/role.selector";
import {
  getLocation,
  // updateCashBackRules,
  // removeCashBackRules
} from "../../services/Location.service";

const ViewBrandDetail = ({ back, setMerchant }) => {
  const { id } = useParams();
  const role = useSelector(selectRole);
  const { t, i18n } = useTranslation(); // Translation hook

  const [merchant, setLocalMerchant] = useState(null);
  console.log('merchant::: ', merchant);
  console.log("merchant::: ", merchant);
  const [activePopup, setActivePopup] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [TotallocationCount, setTotallocationCount] = useState(null);
  console.log("TotallocationCount::: ", TotallocationCount);

  //Paginated Table Variables
  let [totalCount, setTotalCount] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  let [itemsPerPage, setItemsPerPage] = useState(10);
  let [searchKey, setSearchKey] = useState("");
  let [isLoading, setIsLoading] = useState(true);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [range, setRange] = useState({
    startDate: undefined,
    endDate: new Date(),
  });

  const confirm = {
    active: "active",
    promoted: "promoted",
  };

  const brandStatusUpdate = async (brand) => {
    try {
      const payload = {
        id: merchant.id,
        status: !merchant?.active_flag,
      };

      const res = await updateBrandStatus(payload);

      if (res?.status === 200 && res?.data?.statusCode === 200) {
        toast.success(res?.data?.message);
        // back();
        getAllMerchant(brand.id);
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {
      console.log("error::: ", error);
    }
  };

  const brandPopularUpdate = async () => {
    try {
      const payload = {
        brand_id: id,
        status: !merchant?.promoted,
      };

      const res = await updatepopularStatus(payload);
      if (res?.data?.statusCode === 200) {
        const data = res?.data?.data;
        setLocalMerchant(data);
        setMerchant(data);
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {
      // toast.error("Error occurred while updating brand status.");
      console.error("Error occurred while updating brand status:", error);
    }
  };

  const getAllMerchant = async () => {
    try {
      const resp = await Merchantlist(id);
      if (resp?.status === 200 && resp?.data?.statusCode === 200) {
        const merchantData = resp?.data?.data?.getAllMerchant?.[0];
        setLocalMerchant(merchantData);
        setMerchant(merchantData); // Update parent component state
      } else {
        toast.error(resp?.message || resp?.data?.message);
      }
    } catch (error) {
      toast.error("Error occurred while fetching merchant details.");
      console.error("Error occurred while fetching merchant details:", error);
    }
  };

  const getLocationdetails = async (id) => {
    try {
      const requestBody = {
        offset: (currentPage - 1) * itemsPerPage,
        limit: itemsPerPage,
        search: searchKey,

        parent_merchant_id: id,
        startDate: range.startDate,
        endDate: range.endDate,
      };
      const resp = await getLocation(requestBody);
      console.log("resp::: ", resp);

      if (resp?.status === 200 && resp?.data?.statusCode === 200) {
        // setbrand(resp?.data?.getallbrand);
        setTotallocationCount(resp?.data?.count);
      } else {
        toast.error(resp?.message || resp?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();

        console.error(msg);
      }
      console.log(error.message);
    }
  };

  const confirmPopFunction = () => {
    if (confirm?.active === activePopup) brandStatusUpdate(merchant);
    if (confirm?.promoted === activePopup) brandPopularUpdate();
    setActivePopup(null);
  };

  const formattedMobile =
    merchant?.dial_code && merchant?.mobile
      ? `${merchant.dial_code}-${merchant.mobile}`
      : merchant?.mobile || "-";

  useEffect(() => {
    const getAllMerchant = async () => {
      try {
        const resp = await Merchantlist(id);
        if (resp?.status === 200 && resp?.data?.statusCode === 200) {
          const merchantData = resp?.data?.data?.getAllMerchant?.[0];
          setLocalMerchant(merchantData);
          setMerchant(merchantData); // Update parent component state
        } else {
          toast.error(resp?.message || resp?.data?.message);
        }
      } catch (error) {
        toast.error("Error occurred while fetching merchant details.");
        console.error("Error occurred while fetching merchant details:", error);
      }
    };
    getAllMerchant(id);
    getLocationdetails(id);
  }, []);

  return (
    <div>
      {!isEdit && (
        <div>
          <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween BorderBottom_LavenderSyrup pb_16 Mob_flexWrapGap">
            <div className="displayFlex alignItem_center gap12">
              <div>
                <button className="emptyBtn" onClick={() => back()}>
                  <img src={ArrowleftIcon} alt="ArrowleftIcon" />
                </button>
              </div>
              <div>
                <h5 className="fontSize20 fontWeight600 blackClr_text">
                {t("merchant.MerchantDetail")}
                </h5>
              </div>
            </div>

            <div className="displayFlex alignItem_center gap12">
              <div>
                <button
                  className="actionIcon_btn lemonPie_clr customTooltip"
                  type="button"
                  onClick={() => setIsEdit(true)}
                >
                  <img className="width20px" src={EditIcon} alt="EditIcon" />
                  <span className="custom_tooltiptext">{t("common.Edit")} </span>
                </button>
              </div>
              {(role == "admin" || role == "superadmin") && (
                <div>
                  <button
                    className={
                      merchant?.active_flag
                        ? "actionIcon_btn  errorText_clr customTooltip"
                        : "actionIcon_btn  UFOGreen_clr customTooltip"
                    }
                    onClick={() => setActivePopup(confirm.active)}
                  >
                    {merchant?.active_flag ? (
                      <img
                        src={DeactivateIcon}
                        alt="DeactivateIcon"
                        className="width20px"
                      />
                    ) : (
                      <img
                        src={ActiveIcon}
                        alt="ActiveIcon"
                        className="width20px"
                      />
                    )}
                    <span className="custom_tooltiptext">
                      {" "}
                      {merchant?.active_flag ? "InActive" : "Active"}
                    </span>
                  </button>
                </div>
              )}
              {(role == "admin" || role == "superadmin") && (
                <div>
                  <button
                    className={
                      merchant?.promoted
                        ? "actionIcon_btn  errorText_clr customTooltip"
                        : "actionIcon_btn  UFOGreen_clr customTooltip"
                    }
                    onClick={() => setActivePopup(confirm.promoted)}
                  >
                    {merchant?.promoted ? (
                      <img
                        src={UnsetPromotedIcon}
                        alt="UnsetPromotedIcon"
                        className="width20px"
                      />
                    ) : (
                      <img src={SetpromotedIcon} alt="SetpromotedIcon" />
                    )}
                    <span className="custom_tooltiptext">
                      {" "}
                      {merchant?.promoted ? "UnsetPromoted" : "SetPromoted"}
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
          <ConfirmPopup
            message="Are you sure you want to proceed with this action?"
            onConfirm={confirmPopFunction}
            onCancel={() => setActivePopup(null)}
            isOpen={confirm?.active === activePopup}
          />
          <ConfirmPopup
            message="Are you sure you want to proceed with this action?"
            onConfirm={confirmPopFunction}
            onCancel={() => setActivePopup(null)}
            isOpen={confirm?.promoted === activePopup}
          />
          <div className="displayFlex justifyContent_end gap12"></div>
          <label
            className="fontSize14 fontWeight500 blackClr_text display_block mb_16"
            htmlFor="fn"
          >
            {t("merchant.MerchantLogo")}
          </label>

          <div className="viewBrand_logo mb_24">
            {/* PlaceholderImg */}
            <img
              src={merchant?.brand_logo || "-"}
              alt="brand_logo"
              width={100}
              height={100}
            />
          </div>

          <div className="tableResponsive">
            <table className="table_detail ">
              <tbody>
                <tr>
                  <th>{t("merchant.MerchantName")}</th>
                  <td className="textWrap_Wrap">{merchant?.name || "-"}</td>
                </tr>
                <tr>
                  <th>{t("merchant.ContactName")}</th>
                  <td className="textWrap_Wrap">
                    {merchant?.contact_name || "-"}
                  </td>
                </tr>
                <tr>
                  <th>{t("merchant.Mobile")}</th>
                  <td>{formattedMobile}</td>
                </tr>
                <tr>
                  <th>{t("merchant.EmailAddress")}</th>
                  <td>{merchant?.email || "-"}</td>
                </tr>
                <tr>
                  <th>{t("merchant.WebsiteURL")}</th>
                  <td>{merchant?.website_url || "-"}</td>
                </tr>
                <tr>
                  <th>{t("merchant.MerchantType")}</th>
                  <td>
                    {merchant?.store_type
                      ? merchant.store_type.charAt(0).toUpperCase() +
                        merchant.store_type.slice(1).toLowerCase()
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <th>{t("merchant.StoreCount")}</th>
                  <td>{merchant?.store_count || "-"}</td>
                </tr>
                <tr>
                  <th>{t("merchant.CashbackReturnPolicyDays")}</th>
                  <td>{merchant?.is_cashback_policy_days || "-"}</td>
                </tr>

                <tr>
                  <th>{t("merchant.Country")}</th>
                  <td>{merchant?.country || "-"}</td>
                </tr>
                <tr>
                  <th>{t("merchant.Location")}</th>
                  <td>{merchant?.location || "-"}</td>
                </tr>
                <tr>
                  <th>{t("merchant.Latitude")}</th>
                  <td>{merchant?.latitude || "-"}</td>
                </tr>
                <tr>
                  <th>{t("merchant.Longitude")}</th>
                  <td>{merchant?.longitude || "-"}</td>
                </tr>
                <tr>
                  <th>{t("merchant.TimeZone")}</th>
                  <td>{merchant?.time_zone || "-"}</td>
                </tr>
                <tr>
                  <th>{t("merchant.AndroidPackageName")}</th>
                  <td>{merchant?.android_package_name || "-"}</td>
                </tr>
                <tr>
                  <th>{t("merchant.AndroidPackageUrl")}</th>
                  <td>{merchant?.android_package_url || "-"}</td>
                </tr>
                <tr>
                  <th>{t("merchant.IOSPackageName")}</th>
                  <td>{merchant?.ios_package_name || "-"}</td>
                </tr>
                <tr>
                  <th>{t("merchant.IOSPackageUrl")}</th>
                  <td>{merchant?.ios_package_url || "-"}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}

      {isEdit && (
        <EditBrandForm back={back} TotallocationCount={TotallocationCount} />
      )}
    </div>
  );
};

export default ViewBrandDetail;
