import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import CreateIcon from '../../assets/images/create_icon.svg';
import { getCustomerBankDetails } from '../../services/customer.service';
import { useTranslation } from 'react-i18next';  // Import useTranslation

const CustomerBankDetails = () => {
    const [bankList, setBankList] = useState([]);
    const { id } = useParams();
    const { t, i18n } = useTranslation(); // Translation hook

    useEffect(() => {
        getBankList(id);
    }, []);

    const getBankList = async (id) => {
        try {
            const res = await getCustomerBankDetails(id);
            if (res?.status === 200 && res?.data?.statusCode === 200) {
                setBankList(res?.data?.data);
             
            } else {
                toast.error(res?.message || res?.data?.message);
            }
        } catch (error) {
            if (error?.message) {
                const msg = error?.message?.split(':')?.[1]?.trim();
                console.error(msg);
            }
            console.log(error.message);
        }
    };

    return (
        <div>
            <div className="Table_Wrapper tableResponsive">
                <table className="mb_24">
                    <thead>
                        <tr>
                            <th>{t("common.Sno")}</th>
                            <th>{t("common.Name")}</th>
                            <th>{t("customer.BankName")}</th>
                            <th>{t("customer.AccountNumber")}</th>
                            {/* <th>Street Address</th>
                            <th>City</th>
                            <th>State</th>
                            <th>Country</th> */}
                            <th>{t("customer.IBAN")}</th>
                            <th>{t("customer.SwiftCode")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bankList?.map((bank, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{bank?.name}</td>
                                <td>{bank?.bank_name}</td>
                                <td>{bank?.account_number}</td>
                                {/* <td>{bank?.street_address}</td>
                                <td>{bank?.city}</td>
                                <td>{bank?.state}</td>
                                <td>{bank?.country}</td> */}
                                <td>{bank?.iban_number}</td>
                                <td>{bank?.swift_code}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CustomerBankDetails;