import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getBrandList } from "../../services/super-admin.service";
import { getoffer } from "../../services/campaign.service";
import { selectCurrentUser } from "../../store/user/user.selector";
import toast from "react-hot-toast";
import AddIcon from "../../assets/images/add_icon.svg";
import ViewIcon from "../../assets/images/view_icon.svg";
import EditIcon from "../../assets/images/edit_icon.svg";
import { getLanguagelist } from "../../services/language.service";
import CreateLanguagesetting from "./Create-multi-language";
import EditLanguagesetting from "./Edit-multi-language";
import ViewCategorysetting from "./View-multi language-setting";
import PaginatedTable from "../common/PaginatedTable";
// import EditCategorysetting from "./Edit-Category-setting";
// import ViewCategorysetting from "./View-Category-setting";
import { useTranslation } from 'react-i18next';  // Import useTranslation


const fileType = "Voucher";
function Multilanguage() {
  const { t, i18n } = useTranslation(); // Translation hook
  const [showCreate, setShowCreate] = useState(false);
  const [showView, setShowView] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [LanguageTable, setLanguageTable] = useState(true);
  const [data, setdata] = useState();
  const [Language, setLanguage] = useState(null);
  console.log('Language::: ', Language);
 
  const [brand, setBrand] = useState([]);
  const user = useSelector(selectCurrentUser)?.userDetail;
  const id = user?.userType === "brand" ? user?.brand_id : "";
  const [status, setStatus] = useState("");

  //Paginated Table Variables
  let [totalCount, setTotalCount] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  let [itemsPerPage, setItemsPerPage] = useState(10);
  let [searchKey, setSearchKey] = useState("");
  let [isLoading, setIsLoading] = useState(true);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);

  const handleShowCreate = () => {
    setShowCreate(true);
    setLanguageTable(false);
  };

  const handleShowedit = (data) => {
    setdata(data);
    setShowEdit(true);
    setLanguageTable(false);
  };

  const getAlllanguage = async () => {
    const offset = (currentPage - 1) * itemsPerPage;
    const offsetToUse = offset >= 0 ? offset : 0;
    try {
      const requestBody = {
        offset: offsetToUse,
        limit: itemsPerPage,
        search: searchKey,
        statusfilter: status || "all"
      };
      const resp = await getLanguagelist(requestBody);
      if (resp?.status === 200 && resp?.data?.statusCode === 200) {
        
        setLanguage(resp?.data?.getalllanguages);
        console.log('resp::: ', resp);
        setTotalCount(resp?.data?.count);
      } else {
        toast.error(resp?.message || resp?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();
        console.error(msg);
      }
      console.log(error.message);
    }
  };

  const [column, setColumn] = useState([
    // {
    //   heading: () => "Action",
    //   cell: (row) => (
    //     <>
    //       <div>
    //         <button className="actionIcon_btn lemonPie_clr customTooltip" onClick={() => handleShowView(row)}>
    //           {/* <img src={ViewIcon} alt="ViewIcon" /> */}
    //           <img className="width20px" src={EditIcon} alt="EditIcon" />
    //           <span className="custom_tooltiptext">Edit</span>
    //         </button>
    //       </div>
    //     </>
    //   ),
    //   hidden: false,
    //   // width: "50",
    //   // align: "center",
    //   id: 1,
    // },
    {
      heading: () => t("language.LanguageName"),
      cell: (row) => (row["language_name"] ? row["language_name"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 2,
    },
    {
      heading: () => t("language.LanguageCode"),
      cell: (row) => (row["lang_code"] ? row["lang_code"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 3,
    },
    // {
    //   heading: () => "Logo",
    //   cell: (row) => (
        
    //     <div>
    //       {row.image ? (
    //         <img src={row.image} alt="Currency Logo" width={60} />
    //       ) : (
    //         "-"
    //       )}
    //     </div>
    //   ),
    //   hidden: false,
    //   width: "120",
    //   align: "left",
    //   id: 3,
    // },
    {
      heading: () => t("common.Status"),
      cell: (row) => {
        console.log('row::: ', row);
        const status = row["active_flag"];
        console.log('status::: ', status);
        let displayText = "";
        let style = {};
        let className = "";

        if (status === true) {
          displayText = "Active";
          
        } else if (status === false) {
          displayText = "InActive";
          className = "fontWeight600 errorClr_text"; // Use class names directly
        } else if (status === "all") {
          displayText = "ALL Status";
          style = { color: "#28a745", fontWeight: 600 };
        } else {
          displayText = "-";
          style = { fontWeight: 600 };
        }

        return <span className={row?.active_flag ? "fontWeight600 UFOGreen_text" : "fontWeight600 errorClr_text"} style={style}>{displayText}</span>;
      },
      hidden: false,
      width: "120",
      align: "left",
      id: 8,
    },
  ]);

  useEffect(() => {
    getAlllanguage();
  }, [currentPage, itemsPerPage, searchKey,setSearchKey,status]);

  const handleShowView = (category) => {
    setLanguageTable(false);
    setdata(category);
    setShowEdit(true);
  };

  const back = (cond = true) => {
    cond && getAlllanguage();
    setShowCreate(false);
    setShowEdit(false);
    setShowView(false);
    setLanguageTable(true);
  };

  return (
    <div>
      {LanguageTable && (
        <>
          <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween">
            <div>
              <h5 className="fontSize20 fontWeight600 blackClr_text">
              {t("language.MultiLanguageList")}
              </h5>
            </div>
            {/* <div>
              <button
                className="MainButton cyanSky_clr gap8"
                type="button"
                onClick={handleShowCreate}
              >
                <img src={AddIcon} alt="AddIcon" className="width20px" />
                <span> Create Language </span>
              </button>
            </div> */}
          </div>

          {isLoading ? (
            <PaginatedTable
              row={Language}
              column={column}
              totalCount={totalCount}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isSearch={true}
              searchKey={searchKey}
              setSearchKey={setSearchKey}
              maxPageNumberLimit={maxPageNumberLimit}
              setMaxPageNumberLimit={setMaxPageNumberLimit}
              minPageNumberLimit={minPageNumberLimit}
              setMinPageNumberLimit={setMinPageNumberLimit}
              //  setRange={setRange}
              //  range={range}
              isExport={false}
              isDateRange={false}
              status={status}
              setStatus={setStatus}
              isstatus={true}
            />
          ) : (
            <div style={{ textAlign: "center" }}>Loading...</div>
          )}
        </>
      )}

      {showCreate && <CreateLanguagesetting brand={data} back={back} />}

      {/* {showView && (
        <ViewCategorysetting
          data={data}
          back={back}
          handleShowedit={handleShowedit}
        />
      )} */} 

{showEdit && <EditLanguagesetting data={data} back={back} />}
    </div>
  );
}

export default Multilanguage;
