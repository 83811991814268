import React, { useState } from "react";

import DashboardIcon from "../../assets/images/dashboard_icon.svg";
import CampaignManagerIcon from "../../assets/images/campaign_manager_icon.svg";
import UsermanagementIcon from "../../assets/images/usermanagement_icon.svg";
import AdminIcon from "../../assets/images/admin_icon.svg";
import UserIcon from "../../assets/images/user_icon.svg";
import brandmanager from "../../assets/images/brand_manager_icon.svg";
import DownarrowIcon from "../../assets/images/down_arrow.svg";
import CustomerIcon from "../../assets/images/customer_icon.svg";
import VoucherIcon from "../../assets/images/voucher_icon.svg";
import CardtemplateIcon from "../../assets/images/card_template_icon.svg";
import FinancialStatementIcon from "../../assets/images/financialStatement_icon.svg";
import MembershipIcon from "../../assets/images/membership_icon.svg";
import ReportIcon from "../../assets/images/report_icon.svg";
import TransactionsIcon from "../../assets/images/transactions_icon.svg";
import smallkrtipayLogo from "../../assets/images/smallkrtipay_logo.svg";
import MerchantCategoryIcon from "../../assets/images/merchantCategory_icon.svg";
import GlobalSettingIcon from "../../assets/images/globalSetting_icon.svg";
import CountryIcon from "../../assets/images/country_icon.svg";
import DocumentIcon from "../../assets/images/documentTypeIconfnl.svg";
import SupportIcon from "../../assets/images/supportQuery_icon.svg";
import ActivityIcon from "../../assets/images/activitylogs_icon.svg";
import { useTranslation } from 'react-i18next';  // Import useTranslation


import { selectCurrentUser } from "../../store/user/user.selector";
import { selectRole } from "../../store/role/role.selector";
import { useSelector } from "react-redux";

import logo from "../../assets/images/krti_logo_new.svg";

import { useNavigate, useLocation } from "react-router-dom";

const Sidebar = ({ toggle }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(); // Translation hook
  const user = useSelector(selectCurrentUser);
  const location = useLocation();
  const { pathname } = location;
  const role = useSelector(selectRole)

  const userType = user?.userDetail?.userType;

  console.log("user", user)

  const navigateToBrand = () => {
    if (role == "merchant") {
      navigate(`brand-detail/${user['userDetail']['brand_id']}`)
    } else {
      navigate(`/${userType}/brand`)
    }
  }

  const [userManagement, setUserManagement] = useState(false);
  const [setting, setsetting] = useState(false);

  return (
    <div className="sidebar_wrapper ">
      <div className="adminLogo">
        {/* <img src={logo} alt="logo" /> */}
        {toggle ? (
          <img src={smallkrtipayLogo} alt="smallkrtipayLogo" />
        ) : (
          <img src={logo} alt="logo" />
        )}

        {/* <h1 className="fontSize24 fontWeight600 whiteClr_text  mb_24">Krti</h1> */}
      </div>
      <ul>
        <li>
          <button
            className={`${pathname?.includes("dashboard") && "active"}`}
            onClick={() => navigate(`/${userType}/dashboard`)}
          >
            <img src={DashboardIcon} alt="DashboardIcon" />
            <span>{t("Sidebar.dashboard")}</span> {/* Using translation key for "Dashboard" */}
          </button>
        </li>
        {(role === "admin" || role == "merchant" || role == "superadmin") && (
          <li>
            <button
              className={`${role == "merchant" ? pathname?.includes("brand-detail") && "active" : pathname?.includes("brand") && "active"}`}
              onClick={() => navigateToBrand()}
            >
              <img src={brandmanager} alt="brandmanager" />
              <span> {role === "admin" || role === "superadmin" ? t("Sidebar.MerchantManagement") : "Merchant Details"}</span>
            </button>
          </li>
        )}

        <li>
          <button
            className={`${pathname?.includes("campaign") && "active"}`}
            onClick={() => navigate(`/${userType}/campaign`)}
          >
            <img src={CampaignManagerIcon} alt="CampaignManager" />
            <span>{t("Sidebar.CampaignsManagement")}</span> {/* Using translation key for "Dashboard" */}
          </button>
        </li>

        <li>
          <button
            className={`${pathname?.includes("voucher") && "active"}`}
            onClick={() => navigate(`/${userType}/voucher`)}
          >
            <img src={VoucherIcon} alt="VoucherIcon" />
            <span>{t("Sidebar.VoucherManagement")}</span> {/* Using translation key for "Dashboard" */}
          </button>
        </li>

        {userType === "admin" && (
          <li>
            <button
              className={pathname?.includes("customer") ? "active" : null}
              onClick={() => navigate("/admin/customer")}
            >
              <img src={CustomerIcon} alt="CustomerIcon" />
              <span>{t("Sidebar.CustomerManagement")}</span>
            </button>
          </li>
        )}
        <li>
          <button
            className={`${pathname?.includes("transaction") && "active"}`}
            onClick={() => navigate(`/${userType}/transaction`)}
          >
            <img src={TransactionsIcon} alt="TransactionsIcon" />
            <span>{t("Sidebar.Transactions")}</span>
          </button>
        </li>

        {userType === "admin" && (
          <li>
            <button
              className={pathname?.includes("user-mangement") ? "active" : null}
              onClick={() => setUserManagement(!userManagement)}
            >
              <img src={UsermanagementIcon} alt="UsermanagementIcon" />
              <span>{t("Sidebar.UserManagement")}</span>

              <img
                src={DownarrowIcon}
                alt="DownarrowIcon"
                className="ml_auto"
              />
            </button>

            {userManagement && (
              <ul className="submenu">
                <li>
                  <button
                    className={`${
                      pathname?.includes("user-mangement/admin") && "active"
                    }`}
                    onClick={() => navigate("/admin/user-mangement/admin")}
                  >
                    <img src={AdminIcon} alt="AdminIcon" />
                    <span>{t("Sidebar.AdminList")}</span>

                  </button>
                </li>
                {/* <li>
                  <button
                    className={`${
                      pathname?.includes("user-mangement/customer") && "active"
                    }`}
                    onClick={() => navigate("/admin/user-mangement/customer")}
                  >
                    <img src={UserIcon} alt="UserIcon" />
                    <span>User </span>
                  </button>
                </li> */}
              </ul>
            )}
          </li>
        )}

        {userType === "admin" && (
          <li>
            <button
              className={pathname?.includes("setting") ? "active" : null}
              onClick={() => setsetting(!setting)}
            >
              <img src={UsermanagementIcon} alt="UsermanagementIcon" />
              <span>{t("Sidebar.Settings")}</span>

              <img
                src={DownarrowIcon}
                alt="DownarrowIcon"
                className="ml_auto"
              />
            </button>

            {setting && (
              <ul className="submenu">
                <li>
                  <button
                    className={`${
                      pathname?.includes("setting/category") && "active"
                    }`}
                    onClick={() => navigate("/admin/setting/category")}
                  >
                    <img
                      src={MerchantCategoryIcon}
                      alt="MerchantCategoryIcon"
                    />
                                <span>{t("Sidebar.Categories")}</span>

                  </button>
                </li>
                <li>
                  <button
                    className={`${
                      pathname?.includes("setting/globalSetting") && "active"
                    }`}
                    onClick={() => navigate("setting/globalSetting")}
                  >
                    <img src={GlobalSettingIcon} alt="GlobalSettingIcon" />
                    <span>{t("Sidebar.GlobalSetting")}</span>

                  </button>
                </li>
                <li>
                  <button
                    className={`${
                      pathname?.includes("setting/country") && "active"
                    }`}
                    onClick={() => navigate("/admin/setting/country")}
                  >
                    <img src={CountryIcon} alt="CountryIcon" />
                    <span>{t("Sidebar.Country")}</span>
                    
                  </button>
                </li>
                <li>
                  <button
                    className={`${
                      pathname?.includes("setting/document") && "active"
                    }`}
                    onClick={() => navigate("/admin/setting/document")}
                  >
                    <img src={DocumentIcon} alt="DocumentIcon" />
                    <span>{t("Sidebar.Document")}</span>
                  </button>
                </li>
                <li>
                  <button
                    className={`${
                      pathname?.includes("setting/multilang") && "active"
                    }`}
                    onClick={() => navigate("/admin/setting/multilang")}
                  >
                    <img src={DocumentIcon} alt="DocumentIcon" />
                    <span>{t("Sidebar.Mutlilanaguage")}</span>
                  </button>
                </li>
              </ul>
            )}
          </li>
        )}

        {(role == "admin" || role === "superadmin") && <li>
          <button
            className={`${pathname?.includes("supportquery") && "active"}`}
            onClick={() => navigate(`/${userType}/supportquery`)}
          >
            <img src={SupportIcon} alt="SupportIcon" />
            <span>{t("Sidebar.Support")}</span>
          </button>
        </li>}

        {(role == "admin" || role === "superadmin") && <li>
          <button
            className={`${pathname?.includes("activitylogs") && "active"}`}
            onClick={() => navigate(`/${userType}/activitylogs`)}
          >
            <img src={ActivityIcon} alt="ActivityIcon" />
             <span>{t("Sidebar.Activity")}</span>
          </button>
        </li>}


        {/* {
          userType === 'admin' && <li className="">
            <button  >
              <img src={TransactionsIcon} alt="TransactionsIcon" />
              <span>Transactions</span>
            </button>
          </li>
        } */}

        {userType === "admin" && (
          <li className="opacity_ZeroFour">
            <button disabled>
              <img src={CardtemplateIcon} alt="CardtemplateIcon" />
              <span>{t("Card.CardTemplates")}</span>
            </button>
          </li>
        )}
        {userType === "admin" && (
          <li className="opacity_ZeroFour">
            <button disabled>
              <img src={FinancialStatementIcon} alt="FinancialStatementIcon" />
              <span>{t("statement.FinancialStatement")}</span>
            </button>
          </li>
        )}
        {userType === "admin" && (
          <li className="opacity_ZeroFour">
            <button disabled>
              <img src={MembershipIcon} alt="MembershipIcon" />
              <span>{t("membership.MemberShip")}</span>
            </button>
          </li>
        )}
        {userType === "admin" && (
          <li className="opacity_ZeroFour">
            <button disabled>
              <img src={ReportIcon} alt="ReportIcon" />
              <span>{t("reports.Reports")}</span>
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Sidebar;
