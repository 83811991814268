import React, { useEffect, useState } from 'react'
import { fileUpload } from "../../services/common.service";

import UploadDocumentsIcon from '../../assets/images/uploadDocuments_icon.svg'
import UploadIcon from '../../assets/images/upload_icon.svg'

import { DocumentSubmit, GetDocuments, GetFileTypes } from '../../services/merchant.service';
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";
import { useTranslation } from 'react-i18next';  


const DocumentCenter = ({ back }) => {
    const { t, i18n } = useTranslation(); // Translation hook

    const [filename, setFileName] = useState('No file uploaded')
    const [fileTypes, setFileType] = useState([]);
    const [file, setFile] = useState("");
    const [isSubmit, setISsubmited] = useState(false);
    const [documentList, setDocumentList] = useState([]);
    const [selectedFileType, setSelectedFileType] = useState('');
    const { id } = useParams();

    const formReset = () => {
        setFileName("");
        setFile("");
        setSelectedFileType("")
    }


    const handleFileTypeChange = (event) => {
        setSelectedFileType(event.target.value);
    };

    const handleFileChange = async (event) => {
        try {
            const selectedFile = event.target.files[0];
            if (selectedFile) {
                const formData = new FormData();
                formData.append("file", selectedFile, selectedFile.name);
                let res = await fileUpload(formData, "Merchant");
                if (res?.data?.statusCode === 200) {
                    setFileName(selectedFile.name)
                    setFile(res?.data?.data);
                }
            }
        } catch (error) {
            if (error?.message) {
                const msg = error?.message?.split(":")?.[1]?.trim();
                console.error(msg);
            }
            console.log(error.message);
        }
    };

    const getDocumentList = async () => {
        try {
            let res = await GetDocuments(id);
            if (res?.data?.statusCode === 200) {
                if (res?.data?.data?.length)
                    setDocumentList(res?.data?.data)
            }

        } catch (error) { }
    };

    useEffect(() => {
        const getFileType = async () => {
            try {
                let res = await GetFileTypes("Merchant");
                if (res?.data?.statusCode === 200) {
                    if (res?.data?.data?.length)
                        setFileType(res?.data?.data)
                }

            } catch (error) { }
        };
        getFileType();
        getDocumentList();
    }, []);

    const onSubmit = async () => {
        try {
            if (!file || !selectedFileType) {
                return setISsubmited(true)
            } else {
                setISsubmited(false)
            }
            const payload = {
                brand_id: id,
                link: file,
                document_type: selectedFileType
            }

            let res = await DocumentSubmit(payload);
            if (res?.data?.statusCode === 200) {
                formReset();
                getDocumentList();
                toast.success(res?.data?.message || "Success");
            } else {
                toast.error(res?.data?.message || res?.message || "Something went wrong")
            }

        } catch (error) {
            if (error?.message) {
                const msg = error?.message?.split(":")?.[1]?.trim();
                console.error(msg);
            }
            console.log(error.message);
        }
    };





    return (
        <div>
            <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween BorderBottom_LavenderSyrup pb_16 Mob_flexWrapGap">
                <div className="displayFlex alignItem_center gap12">
                    <div>

                        <button className="emptyBtn" >
                            <img src={ArrowleftIcon} alt="ArrowleftIcon" onClick={back} />
                        </button>
                    </div>
                    <div>
                        <h5 className="fontSize20 fontWeight600 blackClr_text">
                        {t("merchant.DocumentCenter")}
                        </h5>
                    </div>
                </div>
            </div>
            <div className='row mob_mb24'>
                <div className='col-md-7'>
                    <label htmlFor="selectedLogo" className="UploadDocumentFrame gap12 mb_24">
                        <img src={UploadDocumentsIcon} alt="UploadDocumentsIcon" />

                        <h2>{t("merchant.UploadDocument")}</h2>
                        <input
                            id="selectedLogo"
                            type="file"
                            name="logo"
                            // value={values.logo}
                            accept="image/*" // Accept all image file types
                            onChange={handleFileChange}
                            hidden
                        />

                    </label>
                    {isSubmit && !file ? (
                        <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                            File is required
                        </p>
                    ) : (
                        <></>
                    )}
                </div>
                <div className='col-md-5'>
                    <h2 className='fontSize18 SteelWoolClr_text fontWeight500 mb_16'>File Name : <span className='cyanSkyClr_text'>{filename}</span></h2>

                    <div className='mb_24'>
                        <label className='fontSize14 fontWeight500 blackClr_text display_block mb_12'>Document Type</label>
                        <select
                            className='selectMainInput'
                            onChange={handleFileTypeChange}
                            value={selectedFileType}
                        >
                            <option value="" disabled> Select Document type</option>
                            {
                                fileTypes?.map((fileType, index) => {
                                    return (
                                        <option key={index} value={fileType?.type} >{fileType?.type}</option>
                                    )
                                })
                            }

                        </select>
                        {isSubmit && !selectedFileType ? (
                            <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                                File type is required
                            </p>
                        ) : (
                            <></>
                        )}
                    </div>


                    <div className='displayFlex alignItem_center'>
                        <div>
                            <button className='MainButton lemonPie_clr gap12' onClick={onSubmit}>
                                <img src={UploadIcon} alt='UploadIcon' className='width20px' />
                                <span>{t("common.Upload")}</span>
                            </button>
                        </div>
                    </div>
                </div>


            </div>
            <div className="Table_Wrapper tableResponsive">

                <table className="mb_24">
                    <thead>
                        <tr>
                            <th>{t("common.Sno")}</th>
                            {/* <th>Documents</th> */}
                            <th>{t("merchant.DocumentType")}</th>
                            <th>{t("common.View")}</th>



                        </tr>
                    </thead>
                    <tbody>
                        {documentList?.map((document, i) => {
                            return (
                                <tr >
                                    <td> {i + 1}</td>
                                    <td> {document?.document_type}</td>
                                    <td> <a href={document?.link}>File</a> </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default DocumentCenter