import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import { selectCurrentUser } from "../../store/user/user.selector";
import CloseIcon from "../../assets/images/close_icon.svg";
import CreateIcon from "../../assets/images/create_icon.svg";
import {
  addCashbackRules,
  getCashbackRules,
} from "../../services/cashback.service";
import { CreateNewProduct } from "../../services/product.services";
import { GetMerchantCategory } from "../../services/Category.service";
import { useParams } from "react-router-dom";
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";
import { fileUpload } from "../../services/common.service";
import UploadImg from "../../assets/images/upload_img.svg";
import { Parser } from "htmlparser2";
import { DomHandler } from "domhandler";
import TextEditor from "../TextEditor/TextEditor";
import { calculatePercentage } from "../../utils/helpers/helper";
// import { selectCurrentUser } from "../../store/user/user.selector";
import { useTranslation } from 'react-i18next';  

const fileType = "Cashback";

export default function CreateProduct({ back, data }) {
  const { id } = useParams();
  const { t, i18n } = useTranslation(); // Translation hook

  const [categoryList, setCategoryList] = useState([]);
  const [brand, setbrand] = useState();

  const today = new Date().toISOString().split("T")[0];

  const deserialize = (html) => {
    const parseNode = (node) => {
      if (node.type === "text") {
        let attributes = {};
        if (
          node.parent &&
          (node.parent.name === "strong" ||
            node.parent.name === "em" ||
            node.parent.name === "u")
        ) {
          if (node.parent.name === "strong") attributes.bold = true;
          if (node.parent.name === "em") attributes.italic = true;
          if (node.parent.name === "u") attributes.underline = true;
        }
        return { ...attributes, text: node.data };
      }

      switch (node.name) {
        case "p":
          return {
            type: "paragraph",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "a":
          return {
            type: "link",
            url: node.attribs.href,
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "blockquote":
          // Assuming all block quotes are wrapped in a paragraph tag
          return {
            type: "block-quote",
            children: [
              {
                type: "paragraph",
                children: node.children ? node.children.map(parseNode) : [],
              },
            ],
          };
        case "ul":
          return {
            type: "bulleted-list",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "ol":
          return {
            type: "numbered-list",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "li":
          return {
            type: "list-item",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "h1":
          return {
            type: "heading-one",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "h2":
          return {
            type: "heading-two",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "div":
          // Assuming divs are used for text alignment
          const align = node.attribs.style.split(":")[1].trim();
          return {
            align,
            children: node.children ? node.children.map(parseNode) : [],
          };
        default:
          // Check if the node has only one child
          if (node.children && node.children.length === 1) {
            return parseNode(node.children[0]);
          } else {
            return node.children ? node.children.map(parseNode) : [];
          }
      }
    };

    try {
      const handler = new DomHandler();
      const parser = new Parser(handler);

      parser.write(html || "");
      parser.end();

      const document = handler.dom.map(parseNode);
      return document.length > 0
        ? document
        : [{ type: "paragraph", children: [{ text: "" }] }];
    } catch (error) {
      console.error("Error occurred during deserialization:", error);
      return [
        {
          type: "paragraph",
          children: [{ text: "Error occurred during deserialization" }],
        },
      ];
    }
  };

  const user_id = useSelector(selectCurrentUser)?.userDetail?.user_id;
  console.log("user::: ", user_id);

  const [termsAndCondition, setTermsAndCondition] = useState("");
  const [privacyPolicy, setPrivacyPolicy] = useState("");
  const [stepsToRedeem, setStepsToRedeem] = useState("");
  let [totalCount, setTotalCount] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  let [itemsPerPage, setItemsPerPage] = useState(10);
  let [searchKey, setSearchKey] = useState("");
  let [isLoading, setIsLoading] = useState(true);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [range, setRange] = useState({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(),
  });

  const [isMultipleRedemption, setIsMultipleRedemption] = useState(false);

  const Cashbackoption = [
    { key: "Product", value: "Product" },
    { key: "Cart", value: "Cart" },
    { key: "Promotions", value: "Promotions" },
  ];
  const handleSwitchChange = (e) => {
    const isChecked = e.target.checked;
    setFieldValue(
      "redemption_type",
      isChecked ? "multirredemption" : "perredemption"
    );
    setIsMultipleRedemption(isChecked);
    if (!isChecked) setFieldValue("redemption_count", 1);
  };

  // form

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues: {
      brand_id: id,
      logo: null,
      product_name: null,
      sku: null,
      upc: null,
      user_id: user_id,
      product_description:null,
    },
    // enableReinitialize: true,
    validationSchema: Yup.object({
      product_name: Yup.string()
        .matches(
          /^[a-zA-Z0-9\s]*$/,
          "Only alphanumeric characters and spaces are allowed"
        )
        .max(50, "Product  name must be at most 50 characters")
        .required("Product name is required"),
      logo: Yup.string().required("Product logo is required"),
      sku: Yup.string().required("Sku  is required")   .min(5, "SKU must be at least 5 characters long")  // Adjust the minimum length as needed
      .max(20, "SKU cannot be more than 20 characters long"),
      upc: Yup.string().required("Upc is required").matches(/^\d{8}$|^\d{12}$/, 'UPC must contain between 8 and 12 digits'),
      product_description: Yup.string().required("Product Description is required"),

    }),

    onSubmit: async (values, { resetForm }) => {
      try {
        const payload = {
          ...values,
        };

        const res = await CreateNewProduct(payload);

        if (res?.status === 200 && res?.data?.statusCode === 200) {
          toast.success(res.data.message);
          resetForm();
          setErrors({});

          back();
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          console.error(msg);
        }
        console.log(error.message);
      }
    },
  });

  const getAllcashbackrules = async (id) => {
    try {
      const requestBody = {
        offset: (currentPage - 1) * itemsPerPage,
        limit: itemsPerPage,
        search: searchKey,

        brand_id: id,
        startDate: range.startDate,
        endDate: range.endDate,

        // search:undefined
      };
      const resp = await getCashbackRules(requestBody);

      if (resp?.status === 200 && resp?.data?.statusCode === 200) {
        setbrand(resp?.data?.data);
      } else {
        toast.error(resp?.message || resp?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();

        console.error(msg);
      }
      console.log(error.message);
    }
  };
  const handleFileChange = async (event, type = "logo") => {
    try {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile, selectedFile.name);
        let res = await fileUpload(formData, fileType);
        if (res?.data?.statusCode === 200) {
          if (type === "banner") {
            setFieldValue("banner", res?.data?.data);
          } else {
            setFieldValue("logo", res?.data?.data);
            setFieldValue("selectedLogo", URL.createObjectURL(selectedFile));
          }
        }
      }
    } catch (error) {}
  };

  const removeLogo = (type = "logo") => {
    if (type === "banner") {
      setFieldValue("banner", "");
    } else {
      setFieldValue("logo", "");
      setFieldValue("selectedLogo", "");
    }
  };

  const handleTypeChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setFieldValue("offer_value", "");
  };

  useEffect(() => {
    // const fetchmerchantCategory = async () => {
    //   try {
    //     const resp = await GetMerchantCategory(id);
    //     if (resp?.status === 200 && resp?.data?.statusCode === 200) {
    //       setCategoryList(resp?.data?.data);
    //     } else {
    //       toast.error(resp?.message || resp?.data?.message);
    //     }
    //   } catch (error) {
    //     if (error?.message) {
    //       const msg = error?.message?.split(":")?.[1]?.trim();
    //       console.error(msg);
    //     }
    //     console.log(error.message);
    //   }
    // };
    // fetchmerchantCategory();
  }, []);

  return (
    <>
      <div className="mb_24 displayFlex alignItem_center gap12">
        <div>
          <h5 className="fontSize16 fontWeight600 blackClr_text">
            {t("merchant.CreateProduct")}{" "}
          </h5>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="mb_24 col-md-6">
            <label className="fontSize14 fontWeight500 blackClr_text display_block mb_8">
            {t("merchant.ProductLogo")}
            </label>
            {!values?.selectedLogo && (
              <label htmlFor="selectedLogo" className="uploadImg_btn gap12">
                <img src={UploadImg} alt="UploadImg" />
                <span>{t("merchant.UploadProductLogo")}</span>
                <input
                  id="selectedLogo"
                  type="file"
                  name="logo"
                  value={values.logo}
                  accept="image/*" // Accept all image file types
                  onChange={handleFileChange}
                  hidden
                />
              </label>
            )}

            {values?.selectedLogo && (
              <div className="ImgFrame position_relative">
                <img
                  className="brandImgSelectlogo"
                  src={values?.selectedLogo}
                  width={100}
                  height={100}
                  alt="selected logo"
                />
                <div className="imgcloseBtn ">
                  <img src={CloseIcon} alt="CloseIcon" onClick={removeLogo} />
                </div>
              </div>
            )}

            {!values.logo && touched.logo ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.logo}
              </p>
            ) : (
              <></>
            )}
          </div>

          {/* <div className="mb_24 col-md-6">
            <label className="fontSize14 fontWeight500 blackClr_text display_block mb_8">
              Cashback Banner
            </label>
            {!values?.banner && (
              <label htmlFor="banner" className="uploadImg_btn gap12">
                <img src={UploadImg} alt="UploadImg" />
                <span>Upload Cashback banner</span>
                <input
                  id="banner"
                  type="file"
                  name="banner"
                  value={values.banner}
                  accept="image/*" // Accept all image file types
                  onChange={(e) => handleFileChange(e, "banner")}
                  hidden
                />
              </label>
            )}

            {values?.banner && (
              <div className="bannerImgFrame position_relative">
                <img
                  className="UcbImg"
                  src={values?.banner}
                  alt="selected Banner"
                />
                <div className="imgcloseBtn ">
                  <img
                    src={CloseIcon}
                    alt="CloseIcon"
                    onClick={() => removeLogo("banner")}
                  />
                </div>
              </div>
            )}

            {!values.banner && touched.banner ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.banner}
              </p>
            ) : (
              <></>
            )}
          </div> */}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
             {t("merchant.ProductName")}
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder={t("merchant.EnterProductName")}
              id="product_name"
              name="product_name"
              value={values.product_name}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.product_name && touched.product_name ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.product_name}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              {t("merchant.SKUNumber")}
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder={t("merchant.EnterSKUNumber")}
              id="sku"
              name="sku"
              value={values.sku}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.sku && touched.sku ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.sku}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
             {t("merchant.UPCNumber")} 
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder={t("merchant.EnterUPCNumber")} 
              id="upc"
              name="upc"
              value={values.upc}
              onBlur={handleBlur}
              onChange={handleChange}
              minLength={8}
              maxLength={12}
            />
            {errors.upc && touched.upc ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.upc}
              </p>
            ) : (
              <></>
            )}
          </div>

          {/* <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="bulk_upload"
            >
              Bulk Upload File
            </label>
            <input
              className="MainInput"
              type="file"
              id="bulk_upload"
              name="bulk_upload"
              onBlur={handleBlur}
              // onChange={handleFileUpload}
            />
            {errors.bulk_upload && touched.bulk_upload ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.bulk_upload}
              </p>
            ) : null}
          </div> */}

          <div className="mb_24 col-md-12">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              {t("merchant.ProductDescription")}
            </label>
            <textarea
            className="MainTextarea"
            placeholder="Product Description"
            id="product_description"
            name="product_description"
            value={values.product_description}
            onBlur={handleBlur}
            onChange={handleChange}
            rows={3}
          ></textarea>
            {/* <TextEditor
              id="privacyPolicy"
              name="privacyPolicy"
              fieldName="privacyPolicy"
              value={privacyPolicy}
              onChange={setPrivacyPolicy}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
            /> */}
            {errors.product_description && touched.product_description ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.product_description}
              </p>
            ) : (
              <></>
            )}
          </div>
          {/* <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
             Available Quantity
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Rules Name"
              id="rules_name"
              name="rules_name"
              value={values.rules_name}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.rules_name && touched.rules_name ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.rules_name}
              </p>
            ) : (
              <></>
            )}
          </div> */}
          {/* <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Start Date
            </label>
            <input
              className="MainInput"
              type="date"
              id="start_date"
              name="start_date"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.start_date}
              min={today}
              max="9999-12-31"
            />
            {errors.start_date && touched.start_date ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.start_date}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              End Date
            </label>
            <input
              className="MainInput"
              type="date"
              id="end_date"
              name="end_date"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.end_date}
              min={values.start_date} // Set minimum date to the selected start date
               max="9999-12-31"
            // max={values.end_date} // Set maximum date to the end of the current month
            />
            {errors.end_date && touched.end_date ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.end_date}
              </p>
            ) : (
              <></>
            )}
          </div> */}
          {/* <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="brand_id"
            >
              Category
            </label>
            <select
              className="selectMainInput"
              id="category"
              name="category"
              value={values.category}
              onBlur={handleBlur}
              onChange={handleChange}
            >
              <option value="">Select Category</option>

              {categoryList.map((category, i) => (
                <option key={i} value={category.category_id}>
                  {category.category_name}
                </option>
              ))}
            </select>
            {errors.category && touched.category ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.category}
              </p>
            ) : (
              <></>
            )}
          </div> */}
          {/* {!id && ( */}
          {/* <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="brand_id"
            >
              Cashback On
            </label>
            <select
              className="selectMainInput"
              id="cash_back_on"
              name="cash_back_on"
              value={values.cash_back_on}
              onBlur={handleBlur}
              onChange={handleChange}
            >
              <option value="">Select Cashback On</option>

              {Cashbackoption.map((count) => (
                <option key={count.id} value={count.id}>
                  {count.value}
                </option>
              ))}
            </select>
            {errors.cash_back_on && touched.cash_back_on ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.cash_back_on}
              </p>
            ) : (
              <></>
            )}
          </div> */}
          {/* )} */}

          {/* <div className="mb_24  col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="toggleValueInput"
            >
              Cashback Option type
            </label>
            <div className="displayFlex alignItem_center gap12">
              <div className="prefer_method">
                <input
                  type="radio"
                  id="offer_value"
                  name="offer_type"
                  value="Value"
                  checked={values.offer_type === "Value"}
                  onChange={(e) => handleTypeChange(e)}
                />
                <label htmlFor="offer_value">Amount</label>
              </div>
              <div className="prefer_method">
                <input
                  type="radio"
                  id="offer_percentage"
                  name="offer_type"
                  value="Percentage"
                  checked={values.offer_type === "Percentage"}
                  onChange={(e) => handleTypeChange(e)}
                />
                <label htmlFor="offer_percentage">Percentage</label>
              </div>
            </div>
            {errors.offer_type && touched.offer_type ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.offer_type}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24  col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              {values?.offer_type === "Value" ? "Amount" : "Percentage"}
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Amount"
              id="offer_value"
              name="offer_value"
              value={values.offer_value}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.offer_value && touched.offer_value ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.offer_value}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24  col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Minimum spend
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Value"
              id="min_spend"
              name="min_spend"
              value={values.min_spend}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.min_spend && touched.min_spend ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.min_spend}
              </p>
            ) : (
              <></>
            )}
          </div>

          {values.offer_type === "Percentage" && (
            <div className="mb_24  col-md-6">
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                htmlFor="email"
              >
                Upper limit
              </label>
              <input
                className="MainInput"
                type="text"
                placeholder="Enter Value"
                id="upper_limit"
                name="upper_limit"
                value={values.upper_limit}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors.upper_limit && touched.upper_limit ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {errors.upper_limit}
                </p>
              ) : (
                <></>
              )}
            </div>
          )} */}
        </div>

        {/* ========= Submit Button ========= */}
        <button type="submit" className="MainButton cyanSky_clr gap12">
          <img src={CreateIcon} alt="CreateIcon" className="width_20px" />
          <span>{t("merchant.CreateProduct")}</span>
        </button>
      </form>
    </>
  );
}
