import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import CreateIcon from "../../assets/images/create_icon.svg";
import { selectcountry } from "../../store/countrylist/country.selector";
import { useDispatch, useSelector } from "react-redux";
import { setcountry } from "../../store/countrylist/country.reducer";
import { Counrtylist } from "../../services/merchant.service";
import {
  addBrandBank,
  getBrandBankList,
  bankMasterList,
  setPrimaryAccount,
  removeBank,
} from "../../services/Bank.service";
import {
  latitudeRegex,
  longitudeRegex,
  phoneRegex,
  VatIdRegex,
  Swiftcode,
  IBANcode,
} from "../../utils/Regex";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import AddBankIcon from "../../assets/images/addBank_icon.svg";
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";
import DeleteIcon from "../../assets/images/delete_icon.svg";
import PaginatedTable from "../common/PaginatedTable";
import { useNavigate } from "react-router-dom";
import ViewIcon from "../../assets/images/view_icon.svg";
import { useTranslation } from 'react-i18next';  // Import useTranslation

const CreateBank = ({ getBankList, back }) => {
  const [bankList, setBankList] = useState([]);
  const navigate = useNavigate();
  console.log("bankList::: ", bankList?.[0]?.create_type);
  const { id } = useParams();

  const [addBank, setAddBank] = useState(false);
  const { t, i18n } = useTranslation(); // Translation hook

  const countries = useSelector(selectcountry);
  const dispatch = useDispatch();
  // const { id } = useParams();
  const [bankMaster, setBankMaster] = useState([]);

  useEffect(() => {
    const getCountry = async () => {
      try {
        const res = await Counrtylist();
        if (res?.status === 200 && res?.data?.statusCode === 200) {
          dispatch(setcountry(res?.data?.data));
          // setBrand(res?.data?.getallbrand || []);
        } else {
          // toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          // alert(msg);
          console.error(msg);
        }
        console.log(error.message);
      }
    };
    const getbankMaster = async () => {
      try {
        const res = await bankMasterList();
        if (res?.status === 200 && res?.data?.statusCode === 200) {
          setBankMaster(res?.data?.data);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          // alert(msg);
          console.error(msg);
        }
        console.log(error.message);
      }
    };

    getCountry();
    getbankMaster();
  }, []);
  const {
    handleBlur,
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    setErrors,
  } = useFormik({
    initialValues: {
      name: null,
      streetAddress: null,
      city: null,
      state: null,
      accountNumber: null,
      iban: null,
      swiftCode: null,
      bankName: null,
      branchName: null,
      country: null,
      Create_type: null,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Contact  name is required")
        .matches(
          /^[a-zA-Z0-9\s]*$/,
          "Only alphanumeric characters and spaces are allowed"
        ),
      streetAddress: Yup.string()
        .required(" Street address is required"),
        // .matches(
        //   /^[a-zA-Z0-9\s,/-]+$/,
        //   "Only alphanumeric characters, spaces, commas, hyphens, and slashes are allowed"
        // ),
      city: Yup.string()
        .required(" City is required"),
        // .matches(
        //   /^[a-zA-Z0-9\/,\s-]+$/,
        //   "City Name must contain only letters, numbers, slashes, hyphens, and commas"
        // ),
      state: Yup.string()
        .required(" State is required"),
        // .matches(
        //   /^[a-zA-Z0-9\s]*$/,
        //   "Only alphanumeric characters and spaces are allowed"
        // ),
      accountNumber: Yup.string()
        .required(" Account number is required")
        .matches(phoneRegex, "Account  number is not valid"),
      iban: Yup.string()
        .required(" Iban is required")
        .matches(IBANcode, "Iban number is not valid"),
      swiftCode: Yup.string()
        .required(" Swift code is required")
        .matches(Swiftcode, "Swiftcode number is not valid"),
      bankName: Yup.string().required(" Bank name is required"),
      branchName: Yup.string()
        .required(" Branch  name is required"),
        // .matches(
        //   /^[a-zA-Z0-9\s]*$/,
        //   "Only alphanumeric characters and spaces are allowed"
        // ),
      country: Yup.string().required(" Country is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const payLoad = {
          name: values.name,
          street_address: values.streetAddress,
          city: values.city,
          state: values.state,
          account_number: values.accountNumber,
          iban_number: values.iban,
          swift_code: values.swiftCode,
          bank_name: values.bankName,
          branch_name: values.branchName,
          branch_address: values.branchName,
          country: values.country,
          create_type: "Merchant",
        };

        const res = await addBrandBank(payLoad, id);

        if (res?.status === 200 && res?.data?.statusCode === 200) {
          resetForm();
          setErrors({});
          getBankList(id);
          back();
          toast.success(res.data.message);
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          // alert(msg);
          console.log(msg);
        }
        console.log(error.message);
        console.log("error::: ", error);
      }
    },
  });

  return (
    <div>
      {/* <h5 className='fontSize16 fontWeight600 blackClr_text mb_24'>Add Bank details</h5> */}
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6 mb_24">
            <div>
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_12"
                htmlFor="fn"
              >
               {t("merchant.ContactName")}
              </label>
              <input
                className="MainInput"
                type="text"
                placeholder={t("merchant.ContactNameEnter")}
                id="name"
                name="name"
                value={values.name}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors.name && touched.name ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {errors.name}
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-6 mb_24">
            <div>
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_12"
                htmlFor="fn"
              >
               {t("merchant.StreetAddress")} 
              </label>
              <input
                className="MainInput"
                type="text"
                placeholder={t("merchant.EnterStreetAddress")}
                id="streetAddress"
                name="streetAddress"
                value={values.streetAddress}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors.streetAddress && touched.streetAddress ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {errors.streetAddress}
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb_24">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              {t("merchant.Country")}
            </label>
            <select
              className="selectMainInput"
              id="country"
              placeholder="country"
              // id="city"
              name="country"
              value={values.country}
              onBlur={handleBlur}
              onChange={handleChange}
            >
              <option>---Select Country---</option>
              {countries.map((city, index) => (
                <option key={index}>{city.name}</option>
              ))}
            </select>

            {errors.country && touched.country ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.country}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="col-md-6 mb_24">
            <div>
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_12"
                htmlFor="fn"
              >
                {t("merchant.StateName")}
              </label>
              <input
                className="MainInput"
                type="text"
                placeholder={t("merchant.EnterStateName")}
                id="state"
                name="state"
                value={values.state}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors.state && touched.state ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {errors.state}
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb_24">
            <div>
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_12"
                htmlFor="fn"
              >
               {t("merchant.CityName")}
              </label>
              <input
                className="MainInput"
                type="text"
                placeholder={t("merchant.EnterCityName")}
                id="city"
                name="city"
                value={values.city}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors.city && touched.city ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {errors.city}
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-6 mb_24">
            <div>
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_12"
                htmlFor="fn"
              >
               {t("merchant.AccountNumber")}
              </label>
              <input
                className="MainInput"
                type="text"
                placeholder={t("merchant.EnterAccountNumber")}
                id="accountNumber"
                name="accountNumber"
                value={values.accountNumber}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors.accountNumber && touched.accountNumber ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {errors.accountNumber}
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb_24">
            <div>
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_12"
                htmlFor="fn"
              >
                {t("merchant.IBAN")}
              </label>
              <input
                className="MainInput"
                type="text"
                placeholder={t("merchant.EnterIBAN")}
                id="iban"
                name="iban"
                value={values.iban}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors.iban && touched.iban ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {errors.iban}
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-6 mb_24">
            <div>
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_12"
                htmlFor="fn"
              >
               {t("merchant.SwiftCode")} 
              </label>
              <input
                className="MainInput"
                type="text"
                placeholder={t("merchant.EnterSwiftCode")}
                id="swiftCode"
                name="swiftCode"
                value={values.swiftCode}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors.swiftCode && touched.swiftCode ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {errors.swiftCode}
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb_24">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
             {t("merchant.BankName")} 
            </label>
            <select
              className="selectMainInput"
              id="bankName"
              placeholder="bankName"
              // id="city"
              name="bankName"
              value={values.bankName}
              onBlur={handleBlur}
              onChange={handleChange}
            >
              <option>---Select Bank Name---</option>
              {bankMaster.map((bank, index) => (
                <option key={index}>{bank.bankname}</option>
              ))}
            </select>

            {errors.bankName && touched.bankName ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.bankName}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className="col-md-6 mb_24">
            <div>
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_12"
                htmlFor="fn"
              >
              {t("merchant.BranchName")}  
              </label>
              <input
                className="MainInput"
                type="text"
                placeholder={t("merchant.EnterBranchName")}
                id="branchName"
                name="branchName"
                value={values.branchName}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors.branchName && touched.branchName ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {errors.branchName}
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
          {/* <div className="col-md-6 mb_24">
                        <label
                            className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                            htmlFor="email"
                        >
                            Country
                        </label>
                        <select
                            className="MainInput"
                            id="country"
                            placeholder="country"
                            // id="city"
                            name="country"
                            value={values.country}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        >
                            <option>---Select City---</option>
                            {countries.map((city, index) => (
                                <option key={index}>{city.name}</option>
                            ))}
                        </select>

                        {errors.country && touched.country ? (
                            <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                                {errors.country}
                            </p>
                        ) : (
                            <></>
                        )}
                    </div> */}
        </div>

        <button type="submit" className="MainButton cyanSky_clr gap8">
          <img className="width20px" src={CreateIcon} alt="CreateIcon" />
          <span> {t("merchant.AddBankDetails")}</span>
        </button>
      </form>
    </div>
  );
};

export default CreateBank;
